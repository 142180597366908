
const GalleryTileInfo:
	{
		name: string,
		type: string,
		url: string,
		tags: string[];
	} =
{
	name: "",
	type: "",
	url: "",
	tags: [""]
};

export default GalleryTileInfo;
