import { Checkbox, fabClasses, FormControlLabel } from "@mui/material";
import React, { useEffect, useState } from "react";



const FilterCheckboxComponent = (prop: {
	CheckboxName: string,
	FilterName: string,
	reset: boolean,
	FilterStateDispatch: React.Dispatch<FiltersAction>,
	DefaultState?: boolean,
}) =>
{
	const [CheckedState, setCheckedState] = useState<boolean>(false);
	useEffect(() =>
	{
		if (prop.reset)
		{
			let newState = false;
			if (prop.DefaultState != undefined)
			{
				newState = prop.DefaultState;
			}
			setCheckedState(newState);
		}
	}, [prop.reset])

	const FilterClicked = (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) =>
	{
		prop.FilterStateDispatch(
			{
				type: 'boolUpdate',
				payload: {
					FilterName: prop.FilterName,
					State: checked,
					DisableStates: [],
					EnableStates: [],
				}
			}
		)
	}

	let FilterCheckbox = <FormControlLabel
		control={<Checkbox
			checked={CheckedState}
			onChange={(event, checked) =>
			{
				FilterClicked(event, checked)
				setCheckedState(checked);
			}}
			sx={{
				color: "#00263A",
				"&.Mui-checked": {
					color: "#00263A",
				},
				".MuiSvgIcon-root": {
					width: "34px",
					height: "34px",
				},
			}} />}
		label={prop.CheckboxName}
		sx={{
			".MuiTypography-root": {
				fontSize: 22,
				fontWeight: 100,
			},
		}} />;
	return FilterCheckbox;
};
export const MemoFilterCheckboxComponent = React.memo(FilterCheckboxComponent);