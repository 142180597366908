import * as React from "react";
import { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import { ExteriorContext } from "../ExteriorContext";
import { FieldInfoType } from "../Panel/FilterBar";
import styled from "@emotion/styled";
import { SliderProps } from "@mui/material";
import Slider from "@mui/material/Slider";

const CustomSlider = styled(Slider)<SliderProps>(() => ({
	color: "#00263A",
	height: 2,
	"& .MuiSlider-track": {
		border: "none",
	},
	"& .MuiSlider-thumb": {
		height: 24,
		width: 24,
		backgroundColor: "#fff",
		border: "2px solid #00263A",
		"&:focus, &:hover, &.Mui-active, &.Mui-focusVisible": {
			boxShadow: "inherit",
		},
		"&:before": {
			display: "none",
		},
	},
	"& .MuiSlider-valueLabel": {
		visibility: "hidden",
		lineHeight: 1.2,
		fontSize: 12,
		background: "unset",
		padding: 0,
		width: 32,
		height: 32,
		borderRadius: "50% 50% 50% 0",
		backgroundColor: "#00263A",
		transformOrigin: "bottom left",
		transform: "translate(50%, -100%) rotate(-45deg) scale(0)",
		"&:before": { display: "none" },
		"&.MuiSlider-valueLabelOpen": {
			transform: "translate(50%, -100%) rotate(-45deg) scale(1)",
		},
		"& > *": {
			transform: "rotate(45deg)",
		},
	},
}));

export function MinimumDistanceSlider(prop: { Name: string; MinRange: number; MaxRange: number; FieldInfos: Map<string, typeof FieldInfoType>; })
{
	const { CurrentFilters } = React.useContext(ExteriorContext);
	const { SetCurrentFilters } = React.useContext(ExteriorContext);

	let minDistance = (prop.MaxRange - prop.MinRange) * 0.1;
	if (prop.Name === "level")
		minDistance = 1;
	const [SliderValue, setSliderValue] = useState<number[]>([prop.MinRange, prop.MaxRange]);

	useEffect(() =>
	{
		let Range = prop.FieldInfos.get(prop.Name);
		if (Range)
		{
			setSliderValue([Range?.min, Range?.max]);
			// console.log("FieldInfos changed for " + prop.Name + " to " + Range?.min + " - " + Range?.max);
		}
	}, [prop.FieldInfos]);

	const handleChange = (event: Event, value: number | number[], activeThumb: number) =>
	{
		if (!Array.isArray(value))
		{
			//console.log("NOT ARRAY");
			return;
		}

		// if (prop.Name == "PRICE")
		// 	CurrentNumber /= 1000;
		let NewNumberValues: number[] = [value[0], value[1]];

		if (value[1] - value[0] < minDistance)
		{
			if (activeThumb === 0)
			{
				const clamped = Math.min(value[0], prop.MaxRange - minDistance);
				NewNumberValues[0] = clamped;
				NewNumberValues[1] = clamped + minDistance;
			}
			else
			{
				let NewMinValue = minDistance;
				if (value[0] <= prop.MinRange)
					NewMinValue += value[0];
				const clamped = Math.max(value[1], NewMinValue);

				NewNumberValues[0] = clamped - minDistance;
				NewNumberValues[1] = clamped;
			}
		}
		else
		{
			NewNumberValues = value;
		}
		NewNumberValues[0] = Math.round(NewNumberValues[0]);
		NewNumberValues[1] = Math.round(NewNumberValues[1]);

		const min = NewNumberValues[0];
		const max = NewNumberValues[1];
		console.log(prop.Name);
		let ChangedFilters = new Map(CurrentFilters);
		ChangedFilters.set(prop.Name, [min, max] as [number, number]);
		SetCurrentFilters(ChangedFilters);

		setSliderValue(NewNumberValues);
	};

	return (
		<Box component="div" sx={{ width: "100%" }}>
			<CustomSlider name={prop.Name} min={prop.MinRange} max={prop.MaxRange} getAriaLabel={() => "Minimum distance shift"} value={SliderValue} defaultValue={SliderValue} onChange={handleChange} valueLabelDisplay="auto" disableSwap />
		</Box>
	);
}
