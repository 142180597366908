import "./App.css";
import { useContext, useEffect, useRef, useState } from "react";
import { atom, RecoilRoot, SetterOrUpdater, useRecoilValue, useSetRecoilState } from "recoil";
import { GlobalContext } from "./context/GlobalContext";
import CoreContainer from "./Core/CoreContainer";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./Pages/HomePage";
import LocationPage from "./Pages/LocationPage";
import ExteriorPage from "./Pages/ExteriorPage";
import GalleryPage from "./Pages/GalleryPage";
import CollaboratorPage from "Pages/CollaboratorsPage";
import UnitPage from "Pages/UnitPage";
import { Box, ListItem } from "@mui/material";
import Page from "components/Page";
import Image from "components/Image";
import 'aos/dist/aos.css';
import { rCMSDataLoaded, rOverlayInput, rUseDisplayOverride, rMediaData, rTypeData, rUnitData, rPoIData, rBuildingData, rLevelData, rMiscData } from "recoil/atoms";
import { AuthPage } from "Pages/Auth/AuthPage";
import 'firebase/compat/auth';
import { Public, Login } from "@mui/icons-material";
import ChangePasswordPage from "Pages/Settings/ChangePasswordPage";
import AuthLayout from "Pages/Auth/AuthLayout";
import Button from './components/UI/Button';
import BrandedBackground, { Wrapper } from "BrandedBackground";
import ChangeEmailPage from "Pages/Settings/ChangeEmailPage";
import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import Auth0LogIn from "Pages/Auth/Auth0/Auth0LogIn";
import LogoutButton from "Pages/Auth/Auth0/Auth0LogOut";
import { getApp } from "firebase/app";
import { collection, CollectionReference, DocumentData, Firestore, getDocs, getFirestore } from "firebase/firestore/lite";

import UnitsTilesPage from "Pages/UnitTilesPage";

import { useFirebaseCollection } from "useFirebaseCollection";

export default () =>
{
	const [imag, setImag] = useState("https://firebasestorage.googleapis.com/v0/b/iris-90830.appspot.com/o/Media%2F0vOUhuE5NiK2Gcm5982x%2Fimagefile%2FHighresScreenshot00010.png?alt=media&token=3d14d355-f913-4876-afb0-290e24b3ca05");
	const { LoadedExternalSettings } = useContext(GlobalContext);
	const { HasFinishedAsyncLoad } = useContext(GlobalContext);
	const CMSDataLoaded = useRecoilValue(rCMSDataLoaded);
	const [SharedObject] = useState({ LoadedExternalSettings, HasFinishedAsyncLoad, CMSDataLoaded });
	const SetOverride = useSetRecoilState(rUseDisplayOverride);
	const setOverlayInfo = useSetRecoilState(rOverlayInput);
	SharedObject.LoadedExternalSettings = LoadedExternalSettings;
	SharedObject.HasFinishedAsyncLoad = HasFinishedAsyncLoad;
	SharedObject.CMSDataLoaded = CMSDataLoaded;



	const [HasRequestedDatabase, setHasRequestedDatabase] = useState<boolean>(false);

	// const Types = useFirebaseCollection("Types");
	// const Units = useFirebaseCollection("Units");
	// const PoIs = useFirebaseCollection("Point Of Interests");
	// const Buildings = useFirebaseCollection("Buildings");
	// const Levels = useFirebaseCollection("Levels");
	// const Misc = useFirebaseCollection("Misc");

	const {
		isLoading,
		isAuthenticated,
		error,
		user,
		loginWithRedirect,
		logout,
		getIdTokenClaims,
		getAccessTokenSilently,

	} = useAuth0();

	const SetMediaData = useSetRecoilState(rMediaData);
	const SetTypeData = useSetRecoilState(rTypeData);
	const SetUnitData = useSetRecoilState(rUnitData);
	const SetPoIDAta = useSetRecoilState(rPoIData);
	const SetBuildingData = useSetRecoilState(rBuildingData);
	const SetLevelData = useSetRecoilState(rLevelData);
	const SetMiscData = useSetRecoilState(rMiscData);
	//This does not work it's effectively just 2 ticks due to non async image loading completely blocking all processing outside of babylon
	let DelaySeconds = 2;
	let InitialValue = 0;
	const CurrentTime = useRef(InitialValue);
	useEffect(() =>
	{
		const interval = setInterval(() =>
		{
			CurrentTime.current = CurrentTime.current + 1;
			//console.log("Seconds = "+ CurrentTime.current);
			if (CurrentTime.current > DelaySeconds && SharedObject.LoadedExternalSettings && SharedObject.HasFinishedAsyncLoad && SharedObject.CMSDataLoaded)
			{
				let Splash = document.getElementById("Splash");
				let Logo = document.getElementById("Logo");
				if (Splash && Logo)
				{
					Splash.classList.add("fadeTransition");
					Logo.classList.add("fadeTransition");
					clearInterval(interval);

					Splash.addEventListener("transitionend", () =>
					{
						console.log("Splash Transition Ended");
						if (Splash)
						{
							Splash.style.visibility = "hidden";
						}
					});
					Logo.addEventListener("transitionend", () =>
					{
						console.log("Logo Transition Ended");
						if (Logo)
						{
							Logo.style.visibility = "hidden";
						}
					});
				}
			}
		}, 1000);
		return () => clearInterval(interval);
	}, []);

	useEffect(() =>
	{
		window.addEventListener("SwitchToDisplay", function SwitchToDisplay(event)
		{
			SetOverride(true);
			console.log("SetOverride True");
		});
		window.addEventListener("ExteriorCloseIframe", function ExteriorCloseIframe(event)
		{
			setOverlayInfo(["", ""]);
		});
		console.log("Rerender App.tsx");
	});

	const Media = useFirebaseCollection("Media");
	const Types = useFirebaseCollection("Types");

	const Buildings = useFirebaseCollection("Buildings");
	const Units = useFirebaseCollection("Units");
	const PoIs = useFirebaseCollection("Point Of Interests");

	const Levels = useFirebaseCollection("Levels");
	const Misc = useFirebaseCollection("Misc");


	useEffect(() =>
	{
		async function SetRecoilData(Promise: Promise<CollectionReference<DocumentData>>, Setter: SetterOrUpdater<Object[]>)
		{
			const Data = await Promise;
			Setter((Data as unknown as Object[]));
		}
		if (Types)
		{
			SetRecoilData(Types, SetTypeData);
		}
		if (Media)
		{
			SetRecoilData(Media, SetMediaData);
		}
		if (Buildings)
		{
			SetRecoilData(Buildings, SetBuildingData);
		}
		if (Units)
		{
			SetRecoilData(Units, SetUnitData);
		}
		if (PoIs)
		{
			SetRecoilData(PoIs, SetPoIDAta);
		}
		if (Levels)
		{
			SetRecoilData(Levels, SetLevelData);
		}
		if (Misc)
		{
			SetRecoilData(Misc, SetMiscData);
		}
	}, [Types, Media, Buildings, Units, PoIs, Levels, Misc])

	if (isLoading)
	{
		return <div>Loading...</div>;
	}
	if (error)
	{
		return <div>Oops... {error.message}</div>;
	}
	////

	//var source = "https://firebasestorage.googleapis.com/v0/b/iris-90830.appspot.com/o/Media%2F0vOUhuE5NiK2Gcm5982x%2Fimagefile%2FHighresScreenshot00010.png?alt=media&token=3d14d355-f913-4876-afb0-290e24b3ca05";
	async function OnClickHandler()
	{
		//const token = await getIdTokenClaims();

		const collection = await Media;
		const image = collection[Math.floor(Math.random() * 36)].imagefile[0].downloadURL;
		console.log(image);
		setImag(image);

		return;
	}
	/////
	if (isAuthenticated)
	{
		// return (
		// 	<>
		// 		<LogoutButton />
		// 	</>
		// );
		return (
			<>
				<Page>
					<Routes>
						<Route path="/" element={<CoreContainer />}>

							<Route index element={<HomePage />} />
							<Route path="Neighbourhood" element={<UnitPage />} />
							<Route path="Collaborators" element={<CollaboratorPage />} />
							<Route path="Location" element={<LocationPage />} />
							<Route path="Gallery" element={<GalleryPage />} />
							<Route path="ChangeEmail" element={<ChangeEmailPage />} />
							<Route path="ChangePassword" element={<ChangePasswordPage />} />
							<Route path="*" element={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "80vh" }}><h1>404</h1></div>} />

						</Route>
						<Route path="/explore" element={<ExteriorPage />} />
						<Route path="/minimalexplore" element={<UnitsTilesPage />} />
					</Routes>
				</Page>
			</>
		);
	} else
	{
		return (
			<Auth0LogIn />
		);

	}

};
// return (
// 	<Page>
// 		<Routes>
// 			<Route path="/" element={<CoreContainer />}>

// 				<Route index element={<HomePage />} />
// 				<Route path="Neighbourhood" element={<UnitPage />} />
// 				<Route path="Collaborators" element={<CollaboratorPage />} />
// 				<Route path="Location" element={<LocationPage />} />
// 				<Route path="Gallery" element={<GalleryPage />} />
// 				<Route path="ChangeEmail" element={<ChangeEmailPage />} />
// 				<Route path="ChangePassword" element={<ChangePasswordPage />} />
// 				<Route path="*" element={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "80vh" }}><h1>404</h1></div>} />

// 			</Route>
// 			<Route path="/explore" element={<ExteriorPage />} />
// 		</Routes>
// 	</Page>
// );
//<FirebaseAuthModule IsSignedIn={IsSignedIn} />
const FirebaseAuthModule = (props: { IsSignedIn: boolean; }) =>
{
	return props.IsSignedIn
		?
		<Page>
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<CoreContainer />}>
						<Route index element={<HomePage />} />
						<Route path="authorize" element={<UnitPage />} />
						<Route path="Neighbourhood" element={<UnitPage />} />
						<Route path="Collaborators" element={<CollaboratorPage />} />
						<Route path="Location" element={<LocationPage />} />
						<Route path="Gallery" element={<GalleryPage />} />
						<Route path="ChangeEmail" element={<ChangeEmailPage />} />
						<Route path="ChangePassword" element={<ChangePasswordPage />} />
						<Route path="*" element={<div style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100vw", height: "80vh" }}><h1>404</h1></div>} />

					</Route>
					<Route path="/explore" element={<ExteriorPage />} />
				</Routes>

			</BrowserRouter>
		</Page>
		:
		<AuthPage />;
};

//<FirebaseAuthModule IsSignedIn={IsSignedIn} />

//<button onClick={loginWithRedirect}>Log in</button>;
