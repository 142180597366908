import { Table, TableBody, TableRow, TableCell, TableHead, IconButton, Box, Button, createTheme, Paper, styled, SwipeableDrawer, ThemeProvider } from "@mui/material";
import React from "react";
import { useRef, useState, useEffect } from "react";
import { useRecoilValue, useSetRecoilState } from "recoil";
import cross from "Assets/icon-cross-exit.svg";
import { Vector2 } from "@babylonjs/core";
import { ExteriorContext } from "Exterior/ExteriorContext";
import { ApartmentLabelPosition, rApartmentInfo, rApartmentLabelSlot, rCurrentUnit, rOverlayButtonEnabled, rOverlayInput } from "recoil/atoms";
const grey = {
	20: "#FFFFFF",
	50: "#F3F6F9",
	100: "#E7EBF0",
	200: "#E0E3E7",
	300: "#CDD2D7",
	400: "#B2BAC2",
	500: "#A0AAB4",
	600: "#6F7E8C",
	700: "#3E5060",
	800: "#2D3843",
	900: "#1A2027",
	999: "#000000",
};
// /border: 0px solid ${theme.palette.mode === "dark" ? grey[20] : grey[20]};
const Root = styled("div")(
	({ theme }) => `
  table {
    font-size: 16px;
  }

  td,
  th {
    border: 0px solid ${grey[20]};
    text-align: left;
    padding: 2px;
    padding-left: 24px;
    padding-right: 24px;
    text-transform: uppercase;
    color: #00263A;
	font-size: 19px;
  }

  th {
    font-size: 24px;

  }
  tbody {

  }
  thead {
    th {
        border-bottom-style: inset;
        border-bottom-color: hsla(100, 0%, 0%, 0.1);
        border-bottom-width: 1px;
		text-align: right;
		padding-right: 10px;
      }
  }
  `
);

const LabelCSS = styled(Paper)(({ theme }) => ({
	textAlign: "center",
	position: "absolute",
	backdropFilter: "blur(5px)",
	background: "rgba(202, 214, 220, 0.6)",
	palette: {
		primary: {
			main: "#0971f1",
			darker: "#053e85",
		},
		neutral: {
			main: "#64748B",
			contrastText: "#fff",
		},
		dark: {
			main: "#64748B",
			contrastText: "#fff",
		},
	},
}));

const buttontheme = createTheme({
	palette: {
		neutral: {
			main: "#00BFBF",
			contrastText: "#fff",
		},
	},
});

declare module "@mui/material/styles" {
	interface Palette
	{
		neutral: Palette["primary"];
	}
	interface PaletteOptions
	{
		// allow configuration using `createTheme`
		neutral?: PaletteOptions["primary"];
	}
}

declare module "@mui/material/Button" {
	// Update the Button's color prop options
	interface ButtonPropsColorOverrides
	{
		neutral: true;
	}
}

const ExploreButton = (prop: { unit: string }) =>
{
	const { ItemInfoArray } = React.useContext(ExteriorContext);
	const [isDrawerLinkOpen, setIsDrawerLinkOpen] = useState<boolean>(false);
	// const [UnitLink, setUnitLink] = useState<string>(findUnit(prop.unit));
	const setOverlayInfo = useSetRecoilState(rOverlayInput);
	const SetRecoilOverlayButtonEnabled = useSetRecoilState(rOverlayButtonEnabled);
	const SetUnitPage = useSetRecoilState(rCurrentUnit);
	// function findUnit(link: string)
	// {
	// 	if (CMSDataJson === undefined)
	// 		return "";
	// 	//console.log(apartmentLink)
	// 	for (let i = 0; i < CMSDataJson.Units.length; i++)
	// 	{
	// 		console.log("Checking " + CMSDataJson.Types[i].unitName);
	// 		if (prop.unit === CMSDataJson.Types[i].unitName)
	// 		{
	// 			return "custom link later :)";
	// 			// return CMSDataJson.Types[i].Link;
	// 		}
	// 	}
	// 	return "";
	// }

	// useEffect(() =>
	// {
	// 	//label position
	// 	// console.log(prop.unit);
	// 	setUnitLink(prop.link);
	// 	// findUnit(prop.unit));
	// }, [prop.unit]);

	function OpenUnitPage()
	{
		// TODO: Replace by storing the info when apartment label clicked it, OR store the index in the array (could be unsafe)
		let NewUnitInfo = undefined;
		for (let i = 0; i < ItemInfoArray.length; i++)
		{
			const ItemInfo = ItemInfoArray[i];
			if (ItemInfo.UnitInfo.name == prop.unit)
			{
				NewUnitInfo = ItemInfo;
			}
		}

		SetUnitPage(NewUnitInfo);
		// SetUnitPage(prop.unit);
	}

	// const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) =>
	// {
	// 	if (event && event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift"))
	// 	{
	// 		console.log(event);
	// 		return;
	// 	}
	// 	setIsDrawerLinkOpen(open);
	// 	if (open === true)
	// 	{
	// 		OpenOverlay();
	// 	}
	// };

	// function OpenOverlay()
	// {
	// 	SetRecoilOverlayButtonEnabled(false);
	// 	setOverlayInfo(["iframe", UnitLink]);
	// }
	return (
		<React.Fragment key={"bottom"}>
			<ThemeProvider theme={buttontheme}>
				<Button onClick={OpenUnitPage} variant="contained" size="large" color="neutral" sx={{ padding: 0, marginTop: 1, boxShadow: "none", width: "88%", height: 48, borderRadius: 1, mb: 2, ml: 2, mr: 2, fontSize: "24px" }}>
					{"EXPLORE"}
				</Button>
			</ThemeProvider>
			{/* <SwipeableDrawer anchor={"bottom"} open={isDrawerLinkOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
				<Box component="div" sx={{ width: "auto", height: "100vh" }} style={{ overflow: "hidden" }} >
					<iframe title={"ExploreBox"} height={"100%"} width={"100%"} src={UnitLink}></iframe>
				</Box>
				<IconButton onClick={toggleDrawer(false)} style={{ position: "absolute", right: "3.8%", top: "3.2%", backgroundColor: "#FFF", padding: "0.9%", boxSizing: "content-box", borderRadius: 0 }}>
					<img alt="" src={cross} width={24}></img>
				</IconButton>
			</SwipeableDrawer> */}
		</React.Fragment>
	);
};

export const RecoilApartmentLabel = (prop: {}) =>
{
	var LabelWidth = 500;
	var LabelHeight = 280;
	var HardLabelOffset = 300;
	const LabelRef = useRef<HTMLDivElement>(null);
	let rightrowwidth = "auto";
	let rowheight = 20;

	const apartmentData = useRecoilValue(rApartmentInfo);
	const apartmentPosition = useRecoilValue(ApartmentLabelPosition);

	const IsDragging = useRef<boolean>(false);
	const setApartmentLabel = useSetRecoilState(rApartmentLabelSlot);

	const CurrentLabelPoint = useRef<[number, number]>();

	const PrevMouseClientPosRef = useRef({ x: 0, y: 0 });
	const MouseClientPosRef = useRef({ x: 0, y: 0 });
	const PrevTouch1PosRef = useRef({ x: 0, y: 0 });
	const Touch1PosRef = useRef({ x: 0, y: 0 });

	const [IsDisplayed, setIsDisplayed] = useState<boolean>(false);

	const EdgeOffset = 10;
	if (LabelRef)
	{
		let node = LabelRef.current;
		if (node)
		{
			LabelWidth = (node as HTMLElement).clientWidth;
			LabelHeight = (node as HTMLElement).clientHeight;
		}
	}
	useEffect(() =>
	{
		if (!IsDisplayed)
		{
			let tempPoint = new Vector2(apartmentPosition.x, apartmentPosition.y);
			if (tempPoint)
			{
				tempPoint.x = tempPoint.x - LabelWidth - HardLabelOffset;
				tempPoint.y = tempPoint.y - LabelHeight / 2;
				CurrentLabelPoint.current = [tempPoint.x, tempPoint.y]
			}
		}
		setIsDisplayed(true);
	}, [apartmentPosition])

	useEffect(() =>
	{
		// 👇️ get global mouse coordinates
		const handleWindowMouseMove = event =>
		{
			MouseClientPosRef.current.x = event.clientX;
			MouseClientPosRef.current.y = event.clientY;
			OnMouseDrag();
			// setmousepos([event.clientX, event.clientY]);
		};

		const handleWindowTouchMove = event =>
		{
			Touch1PosRef.current.x = event.touches[0].clientX;
			Touch1PosRef.current.y = event.touches[0].clientY;
			OnTouchDrag();
		};
		window.addEventListener('mousemove', handleWindowMouseMove);
		window.addEventListener('mouseup', onEndMouseDrag);
		window.addEventListener('touchmove', handleWindowTouchMove);
		window.addEventListener('touchend', onEndTouchDrag);
		return () =>
		{
			window.removeEventListener('mousemove', handleWindowMouseMove);
			window.removeEventListener('mouseup', onEndMouseDrag);
			window.removeEventListener('touchmove', handleWindowTouchMove);
			window.removeEventListener('touchend', onEndTouchDrag);
		};
	}, []);




	function onCloseLabel()
	{
		setIsDisplayed(false);
		// setApartmentLabel(<></>);
	}
	function onStartTouchDrag(event: React.TouchEvent)
	{
		IsDragging.current = true;
		Touch1PosRef.current.x = event.touches[0].clientX;
		Touch1PosRef.current.y = event.touches[0].clientY;
		PrevTouch1PosRef.current.x = event.touches[0].clientX;
		PrevTouch1PosRef.current.y = event.touches[0].clientY;
	}
	function onEndTouchDrag(event)
	{
		IsDragging.current = false;
	}
	function OnTouchDrag()
	{
		if (IsDragging.current && (Touch1PosRef.current.x !== 0 || Touch1PosRef.current.y !== 0))
		{
			if (PrevTouch1PosRef.current.x === 0 && PrevTouch1PosRef.current.y === 0)
			{
				PrevTouch1PosRef.current.x = Touch1PosRef.current.x;
				PrevTouch1PosRef.current.y = Touch1PosRef.current.y;
			}
			let TouchXMovement = Touch1PosRef.current.x - PrevTouch1PosRef.current.x;
			let TouchYMovement = Touch1PosRef.current.y - PrevTouch1PosRef.current.y;

			CurrentLabelPoint.current[0] = CurrentLabelPoint.current[0] + TouchXMovement;
			CurrentLabelPoint.current[1] = CurrentLabelPoint.current[1] + TouchYMovement;

			PrevTouch1PosRef.current.x = Touch1PosRef.current.x;
			PrevTouch1PosRef.current.y = Touch1PosRef.current.y;
			LabelRef.current.style.left = CurrentLabelPoint.current[0].toString() + "px";
			LabelRef.current.style.top = CurrentLabelPoint.current[1].toString() + "px";

			MaintainLabelTopLeftInWindow(LabelRef.current);
		}
	}



	function onStartMouseDrag(event: React.MouseEvent)
	{
		IsDragging.current = true;
		PrevMouseClientPosRef.current.x = MouseClientPosRef.current.x;
		PrevMouseClientPosRef.current.y = MouseClientPosRef.current.y;
	}
	function onEndMouseDrag(event)
	{
		IsDragging.current = false;
	}
	function OnMouseDrag()
	{
		if (IsDragging.current && (MouseClientPosRef.current.x !== 0 || MouseClientPosRef.current.y !== 0))
		{
			let MouseXmovement = MouseClientPosRef.current.x - PrevMouseClientPosRef.current.x;
			let MouseYmovement = MouseClientPosRef.current.y - PrevMouseClientPosRef.current.y;

			CurrentLabelPoint.current[0] = CurrentLabelPoint.current[0] + MouseXmovement;
			CurrentLabelPoint.current[1] = CurrentLabelPoint.current[1] + MouseYmovement;

			PrevMouseClientPosRef.current.x = MouseClientPosRef.current.x;
			PrevMouseClientPosRef.current.y = MouseClientPosRef.current.y;
			LabelRef.current.style.left = CurrentLabelPoint.current[0].toString() + "px";
			LabelRef.current.style.top = CurrentLabelPoint.current[1].toString() + "px";

			MaintainLabelTopLeftInWindow(LabelRef.current);
		}
	}


	function MaintainLabelTopLeftInWindow(CheckingDiv: HTMLDivElement)
	{
		console.log(CheckingDiv.clientLeft);

		if (CheckingDiv.offsetLeft < 0 + EdgeOffset)
		{
			CheckingDiv.style.left = "10px";
		}
		else if (CheckingDiv.offsetLeft + CheckingDiv.scrollWidth > window.outerWidth - EdgeOffset)
		{
			let leftpos = window.outerWidth - CheckingDiv.scrollWidth - EdgeOffset;
			CheckingDiv.style.left = leftpos.toString() + "px";
		}

		if (CheckingDiv.offsetTop < 0 + EdgeOffset)
		{
			CheckingDiv.style.top = "10px";
		}
		else if (CheckingDiv.offsetTop + CheckingDiv.scrollHeight > window.outerHeight - EdgeOffset)
		{
			let toppos = window.outerHeight - CheckingDiv.scrollHeight - EdgeOffset;
			CheckingDiv.style.top = toppos.toString() + "px";
		}

	}


	if (CurrentLabelPoint && CurrentLabelPoint.current && CurrentLabelPoint.current[0] && CurrentLabelPoint.current[1] && IsDisplayed)
	{
		return (
			<LabelCSS ref={LabelRef} style={{ left: CurrentLabelPoint.current[0], top: CurrentLabelPoint.current[1], visibility: "visible", height: "auto" }}>
				<Table padding="none" sx={{ borderRadius: 0 }}>
					<TableBody>
						<TableRow sx={{ borderRadius: 99 }}>
							<TableCell padding="none" sx={{ bgcolor: "#00BFBF", width: "6px", borderBottom: "0px" }}></TableCell>
							<TableCell padding="none">
								<Root>
									<Table padding="none" sx={{ borderRadius: 0 }}>
										<TableHead style={{ position: "relative" }} onMouseDown={onStartMouseDrag} onMouseUp={onEndMouseDrag} onTouchStart={onStartTouchDrag} onTouchEnd={onEndTouchDrag}>
											<TableRow>
												<TableCell style={{ paddingTop: 15, paddingBottom: 15 }} sx={{ fontWeight: "bold" }}>{apartmentData.unit}</TableCell>
												<TableCell>
													<IconButton onMouseDown={onEndMouseDrag} onClick={onCloseLabel}>
														<img alt="" src={cross}></img>
													</IconButton>
												</TableCell>
											</TableRow>
										</TableHead>

										<TableBody>
											{apartmentData.data.map((row) => (
												<TableRow key={row[0]} style={{ padding: 10 }}>
													<TableCell sx={{ fontWeight: "normal", fontSize: 16 }} style={{ width: rightrowwidth, height: rowheight, paddingTop: 7, paddingBottom: 7 }}>
														{row[0].replace("unitName", "Unit Type")}
													</TableCell>
													<TableCell sx={{ fontWeight: "normal", fontSize: 16 }} style={{ width: rightrowwidth, height: rowheight, paddingTop: 7, paddingBottom: 7 }} align="right">
														{row[1]}
													</TableCell>
												</TableRow>
											))}
										</TableBody>
									</Table>
									{apartmentData.type !== "" ? <ExploreButton unit={apartmentData.unit} /> : <></>}
								</Root>
							</TableCell>
						</TableRow>
					</TableBody>
				</Table>
			</LabelCSS>
		);
	}
	else
	{
		return <></>;
	}

};
