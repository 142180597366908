import { ListItem, Grid, styled } from "@mui/material";
import { Box, Container } from "@mui/system";
import Image from "components/Image";
import Button from "components/UI/Button";
import theme from "../theme/theme";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { rCurrentUnit, rOverlayButtonEnabled, rOverlayInput, rUseDisplayOverride, rLevelData, rMediaData } from "recoil/atoms";
import { useEffect, useState } from "react";
import { JsxEmit } from "typescript";
import Typography from "components/UI/Typography";
import { UnitTypeKeys } from "components/Types";
import { object } from "yup";
import { getApp } from "firebase/app";
import { getFirestore } from "firebase/firestore/lite";
import TileGallery from "Core/TileGallery";
import { ImageViewer } from "Core/ImageViewer";
import GalleryTileInfo from "Core/Types/GalleryTypes";
export default function UnitPage()
{
	let isMetric = true;
	//Firebase CMS data


	const UnitInfoIcons: [string, string, string][] = [];
	UnitInfoIcons.push(["bedrooms", "https://realspace-core-iris.pages.dev/images/62cd561d4760d4b750dd508f_Unit-Bed.svg", "Bedrooms"]);
	UnitInfoIcons.push(["bathrooms", "https://realspace-core-iris.pages.dev/images/62cd561d5537f7324523b0fb_Unit-Bath.svg", "Bathrooms"]);
	UnitInfoIcons.push(["carparks", "https://upload.wikimedia.org/wikipedia/commons/5/5a/Car_icon_alone.png", "Carparks"]);
	if (isMetric)
	{
		UnitInfoIcons.push(["size", "https://realspace-core-iris.pages.dev/images/62cd5af36daba3be97d6b27e_Unit-Area.svg", " m² Total area"]);
	}
	else
	{
		UnitInfoIcons.push(["size", "https://realspace-core-iris.pages.dev/images/62cd5af36daba3be97d6b27e_Unit-Area.svg", "ft²"]);
	}
	UnitInfoIcons.push(["level", "https://upload.wikimedia.org/wikipedia/commons/5/5a/Car_icon_alone.png", "Floor"]);
	const [UnitCoreInformation, setUnitCoreInformation] = useState<[IconUrl: string, InfoType: string, Info: string][]>([]);
	const CurrentUnit = useRecoilValue(rCurrentUnit);
	const setOverlayInfo = useSetRecoilState(rOverlayInput);
	const SetRecoilOverlayButtonEnabled = useSetRecoilState(rOverlayButtonEnabled);
	const UseDisplayOverride = useRecoilValue(rUseDisplayOverride);

	const DatabaseLevelData = useRecoilValue(rLevelData);
	const DatabaseMediaData = useRecoilValue(rMediaData);


	const [CurrentImageInSet, setCurrentImageInSet] = useState<[number, typeof GalleryTileInfo, typeof GalleryTileInfo[]]>([-1, GalleryTileInfo, []]);


	useEffect(() =>
	{
		ParseCurrentUnitInfo();
	}, [CurrentUnit]);
	function ParseCurrentUnitInfo()
	{
		let Properties: [string, any][] = [];
		if (CurrentUnit != undefined && CurrentUnit.UnitInfo != undefined)
		{
			Object.entries(CurrentUnit.UnitInfo).forEach(property =>
			{
				Properties.push(property);
			});
		}
		if (CurrentUnit != undefined && CurrentUnit.TypeInfo != undefined)
		{
			Object.entries(CurrentUnit.TypeInfo).forEach(property =>
			{
				Properties.push(property);
			});
		}
		let tempUnitCoreInfo: [IconUrl: string, InfoType: string, Info: string][] = [];
		for (let j = 0; j < UnitInfoIcons.length; j++)
		{
			for (let i = 0; i < Properties.length; i++)
			{
				if (tempUnitCoreInfo.findIndex((value) =>
				{
					if (value[1] == UnitInfoIcons[j][0].toLowerCase())
						return true;
				}) != -1) continue;

				if (Properties[i][0].toLowerCase() === UnitInfoIcons[j][0].toLowerCase())
				{
					let FirstLetterCapitalType = (UnitInfoIcons[j][2].charAt(0).toUpperCase() + UnitInfoIcons[j][2].slice(1));
					if (typeof Properties[i][1] === "string" || typeof Properties[i][1] === "number")
					{
						let parsedinfo: [IconUrl: string, InfoType: string, Info: string] =
							[UnitInfoIcons[j][1], FirstLetterCapitalType, Properties[i][1].toString()];

						tempUnitCoreInfo.push(parsedinfo);
					}
				}
			}
		}
		setUnitCoreInformation(tempUnitCoreInfo);
	}

	function ExploreClicked()
	{
		if (CurrentUnit.TypeInfo.unrealLevel && UseDisplayOverride)
		{
			(window as { [key: string]: any; })['blu_event']("OpenLevel", CurrentUnit.TypeInfo.unrealLevel);
			return;
		}

		SetRecoilOverlayButtonEnabled(true);
		setOverlayInfo(["iframe", CurrentUnit.TypeInfo.walkthrough]);
		console.log("EXPLORE");
	}


	let UnitName = "APARTMENT NUMBER";
	if (CurrentUnit != undefined && CurrentUnit.UnitInfo != undefined && CurrentUnit.UnitInfo.name != "")
	{
		UnitName = CurrentUnit.UnitInfo.name.toUpperCase();
	}
	//In Types
	let FloorPlanURL = "";
	//In Levels
	let FloorPlateURL = "";
	//In Types
	let Description = "";
	//In Levels override in Units
	let ViewLineURL = "";
	//In Media
	let VideoURL = "";
	// In Media
	let GalleryContent: typeof GalleryTileInfo[] = [];

	if (CurrentUnit != undefined && CurrentUnit.UnitInfo != undefined && DatabaseMediaData)
	{
		for (let i = 0; i < DatabaseMediaData.length; i++)
		{
			if (DatabaseMediaData[i].hasOwnProperty("unitType"))
			{
				if (DatabaseMediaData[i].hasOwnProperty("videoFile") && DatabaseMediaData[i]["videoFile"] != "")
				{
					let dbType = DatabaseMediaData[i]["unitType"].toLowerCase().replace(/\s+/g, '').replaceAll('-', '');
					let UnitType = CurrentUnit.UnitInfo.type.toLowerCase().replace(/\s+/g, '').replaceAll('-', '');
					if (dbType === UnitType)
					{
						VideoURL = DatabaseMediaData[i]["videoFile"][0]["downloadURL"];
						let Filters: string[] = [];
						for (let j = 0; j < (DatabaseMediaData[i]["filterCategoriesMultiReference"] as Array<string>).length; j++)
						{
							Filters.push(DatabaseMediaData[i]["filterCategoriesMultiReference"][j].toString());
						}
						GalleryContent.push(
							{
								name: DatabaseMediaData[i]["name"].toString(),
								type: "video",
								url: VideoURL,
								tags: Filters,
							}
						)

					}
				}
				else if (DatabaseMediaData[i].hasOwnProperty("imagefile") && DatabaseMediaData[i]["imagefile"] != "")
				{
					let dbType = DatabaseMediaData[i]["unitType"].toLowerCase().replace(/\s+/g, '').replaceAll('-', '');
					let UnitType = CurrentUnit.UnitInfo.type.toLowerCase().replace(/\s+/g, '').replaceAll('-', '');
					if (dbType === UnitType)
					{
						let ImageURL = DatabaseMediaData[i]["imagefile"][0]["downloadURL"];
						let Filters: string[] = [];
						for (let j = 0; j < (DatabaseMediaData[i]["filterCategoriesMultiReference"] as Array<string>).length; j++)
						{
							Filters.push(DatabaseMediaData[i]["filterCategoriesMultiReference"][j].toString());
						}
						GalleryContent.push(
							{
								name: DatabaseMediaData[i]["name"].toString(),
								type: "image",
								url: ImageURL,
								tags: Filters,
							}
						)

					}
				}
			}
		}
		console.log(GalleryContent);
	}

	if (CurrentUnit != undefined && CurrentUnit.UnitInfo != undefined && DatabaseLevelData)
	{
		for (let i = 0; i < DatabaseLevelData.length; i++)
		{
			if (DatabaseLevelData[i].hasOwnProperty("level"))
			{
				if (DatabaseLevelData[i]["level"] == CurrentUnit.UnitInfo.level)
				{
					if (DatabaseLevelData[i].hasOwnProperty("viewline"))
					{

						if ((DatabaseLevelData[i]["viewline"] as Array<any>).length > 0)
						{
							ViewLineURL = DatabaseLevelData[i]["viewline"][0]["downloadURL"];
						}

					}
					if (DatabaseLevelData[i].hasOwnProperty("floorplate"))
					{

						if ((DatabaseLevelData[i]["floorplate"] as Array<any>).length > 0)
						{
							FloorPlateURL = DatabaseLevelData[i]["floorplate"][0]["downloadURL"];
						}
					}
				}
			}
		}
	}

	if (CurrentUnit != undefined && CurrentUnit.TypeInfo != undefined)
	{
		if (CurrentUnit.TypeInfo["floorplan"] != undefined)
		{
			let type = typeof CurrentUnit.TypeInfo["floorplan"];
			if (type === 'object')
			{
				if ((CurrentUnit.TypeInfo["floorplan"] as Array<any>).length > 0)
				{
					FloorPlanURL = (CurrentUnit.TypeInfo["floorplan"][0]["downloadURL"] as string);
				}
			}

		}
	}

	if (CurrentUnit != undefined && CurrentUnit.TypeInfo != undefined)
	{
		if (CurrentUnit.TypeInfo["description"] != undefined && typeof CurrentUnit.TypeInfo["description"] === "string")
		{
			Description = (CurrentUnit.TypeInfo["description"] as string);
		}
	}
	const HorizontalBorder = <Box sx={{ borderTop: "1px solid #d1cdca", marginLeft: "3rem", marginRight: "3rem" }} />;
	let Clientwidth = document.documentElement.clientWidth
	let Clientheight = document.documentElement.clientHeight


	let UnitInfoSection =
		<Box
			sx={{
				display: "flex",
				flexDirection: "column",
				width: "50%",
				alignSelf: "center",
				minHeight: "100%",
			}}>
			{UnitCoreInformation.map((item) => (
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
					}}>
					<Image src={item[0]} sx={{ height: "5rem", width: "5rem", marginRight: "1rem", }} />
					<Box
						sx={{
							fontSize: "2rem",
							fontWeight: 300,
							fontFamily: "'Roboto Condensed',sans-serif",
							letterSpacing: ".05rem",
							marginRight: "1rem",
							alignContent: 'center',
						}}>
						{item[2]}
					</Box>
					<Box
						sx={{
							fontSize: "2rem",
							fontWeight: 300,
							fontFamily: "'Roboto Condensed',sans-serif",
							letterSpacing: ".05rem",
							alignContent: 'center',
						}}>
						{item[1]}
					</Box>
				</Box>
			))}
			<Typography variant={"subtitle1"} whiteSpace={"pre-wrap"} sx={{ marginRight: 5, marginTop: 5 }}>
				{Description}
			</Typography>
		</Box>;

	let FloorPlanContent: typeof GalleryTileInfo[] = [];
	let FloorPlateContent: typeof GalleryTileInfo[] = [];
	let ViewLineContent: typeof GalleryTileInfo[] = [];
	if (FloorPlanURL != '')
	{
		let FloorPlanInfo: typeof GalleryTileInfo =
		{
			name: "floorplan",
			type: "image",
			url: FloorPlanURL,
			tags: [""]
		};
		FloorPlanContent.push(FloorPlanInfo);

	}
	if (FloorPlateURL != "")
	{

		let FloorPlateInfo: typeof GalleryTileInfo =
		{
			name: "floorplate",
			type: "image",
			url: FloorPlateURL,
			tags: [""]
		};
		FloorPlateContent.push(FloorPlateInfo);
	}

	if (ViewLineURL != "")
	{

		let ViewLienInfo: typeof GalleryTileInfo =
		{
			name: "viewline",
			type: "image",
			url: ViewLineURL,
			tags: [""]
		};
		ViewLineContent.push(ViewLienInfo);
	}

	const UnitFloorplanSection = <Box
		sx={{
			display: "flex",
			flexDirection: "column",
			width: "50%",
			minHeight: "100%",
			marginLeft: 2.5,
		}}>
		{FloorPlanURL != "" ?
			< TileGallery ContentTiles={FloorPlanContent} SetClickedTile={setCurrentImageInSet} StopForcedAspectRatio={true}></TileGallery>
			:
			<></>
		}
		{FloorPlateURL != "" ?
			< TileGallery ContentTiles={FloorPlateContent} SetClickedTile={setCurrentImageInSet} StopForcedAspectRatio={true}></TileGallery>
			:
			<></>
		}

	</Box>;
	const UnitViewlineSection = <Box id="UnitViewlineBox"
		sx={{
			padding: "3rem",
			display: "flex",
			flexDirection: "column",
			justifyContent: "center",
		}}>
		<Typography variant={"h4"}>Viewline</Typography>
		< TileGallery ContentTiles={ViewLineContent} SetClickedTile={setCurrentImageInSet} StopForcedAspectRatio={true} ></TileGallery>
	</Box>



	if (CurrentUnit != undefined)
	{
		return (


			<Box
				sx={{
					// mt: 3,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
					height: Clientheight,
					width: Clientwidth,
				}}
			>
				<Box
					id="UnitPageBackground"
					sx={{
						backgroundImage: "url(https://realspace-core-iris.pages.dev/images/62c428b543620ea167977813_BM_Aerial.jpg)",
						backgroundPosition: "center",
						backgroundRepeat: "no-repeat",
						backgroundSize: "cover",
						height: "100%",
						width: "100vw",
						position: "fixed",
						zIndex: -1,
					}}
				/>
				<Container
					data-aos={'fade-up'}
					style={{ maxWidth: "54vw" }}
					sx={{
						position: "absolute",
						display: 'flex',
						flexDirection: "column",
						alignItems: 'center',
						marginTop: "10vh",
						width: "100vw",
						justifyContent: "space-between",
						// backgroundColor: "#9F2B68",
					}}
				>
					<Box
						sx={{
							alignSelf: "flex-start",
							flexGrow: 1,
							height: "4rem",
							padding: "1rem 2rem",
							display: 'flex',
							textAlign: "center",
							alignItems: 'center',
							justifyContent: 'center',
							backgroundColor: "#d64f4f",
							color: "#fff",
							fontSize: "1.5rem",
							fontWeight: "bold",
							letterSpacing: ".1rem"
						}}>
						SOLD
					</Box>
					<Box id="UnitPageBannerBox"
						sx={{
							padding: "5rem",
							display: "flex",
							flexDirection: "row",
							width: "100%",
							backgroundColor: "hsla(0,0%,100%,.7)",
							justifyContent: "space-between",
							alignItems: 'center',
						}}>
						<Box
							sx={{
								fontSize: "4rem",
								fontWeight: 300,
								fontFamily: "'Roboto Condensed',sans-serif",
								letterSpacing: ".05rem",
							}}>
							{UnitName}
						</Box>
						{(CurrentUnit.TypeInfo.walkthrough || CurrentUnit.TypeInfo.unrealLevel) && <Button
							onClick={ExploreClicked}
							sx={{
								maxHeight: "5vh",
								backgroundColor: "#00bfbf",
								color: "#fff",
								alignContent: 'center',
							}}>
							<Image
								src={"https://realspace-core-iris.pages.dev/images/62cd62286cb0be39799dc7b2_Realspace-wordmark-white.svg"}>
							</Image>
						</Button>}
					</Box>
					<Box
						sx={{
							backgroundColor: "#fff",
							width: "100%",
						}}>
						<Box id="UnitInfoBox"
							sx={{
								padding: "3rem",
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: 'stretch',
								width: "100%",
								boxSizing: "border-box",
							}}>
							{UnitInfoSection}
							<Box sx={{ width: "1px", borderRight: "1px solid #d1cdca" }} />
							{UnitFloorplanSection}
						</Box>
						{HorizontalBorder}
						{ViewLineURL != "" ?
							<>
								{UnitViewlineSection}
								{HorizontalBorder}
							</>
							:
							<></>}
						{VideoURL != "" ?
							<>
								<Box id="UnitViewlineBox"
									sx={{
										padding: "3rem",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}>
									<Typography variant={"h4"}>Video</Typography>
									<video autoPlay muted loop controls
										src={VideoURL}
										style={{ width: "100%" }} />
								</Box>
								{HorizontalBorder}
							</>
							:
							<></>}
						{GalleryContent.length > 0 ?
							<>
								<Box id="UnitGalleryBox"
									sx={{
										padding: "3rem",
										display: "flex",
										flexDirection: "column",
										justifyContent: "center",
									}}>
									<Typography variant={"h4"}>Gallery</Typography>
									< TileGallery
										ContentTiles={GalleryContent}
										SetClickedTile={setCurrentImageInSet}
										OverrideMinColumnWidth={400} />
								</Box>
							</>
							:
							<></>
						}
					</Box>

				</Container >

				<ImageViewer CurrentImageAndImageSet={CurrentImageInSet} setCurrentImageInSet={setCurrentImageInSet}></ImageViewer>
			</Box >
		);
	}
	return <></>;
}