import { ListItem } from "@mui/material";
import { Box, Container } from "@mui/system";
import Image from "components/Image";
export default function CollaboratorPage()
{
	return (
		<Box
			sx={{
				// mt: 3,
				mb: 14,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}>

			<ListItem
				component="div"
				disableGutters
				// data-aos-delay={100}
				// data-aos-offset={100}
				// data-aos-duration={600}
				sx={{
					flexDirection: 'column',
					alignItems: 'center',
					padding: 0,
				}}
			>
				<Box id="TopBannerImageBox" data-aos={'fade-up'}
					sx={{ width: "100vw", height: "50vh", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Image src="https://realspace-core-iris.pages.dev/images/62cce3766149ee55e025c2a1_download%20(26).jpeg" style={{ width: "100vw" }}></Image>
				</Box>
				<Box data-aos={'fade-up'}
					sx={{ width: "100vw", height: "50vh", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Image src="https://realspace-core-iris.pages.dev/images/62cce3766149ee55e025c2a1_download%20(26).jpeg" style={{ width: "100vw" }}></Image>
				</Box>
				<Box data-aos={'fade-up'}
					sx={{ width: "100vw", height: "50vh", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Image src="https://realspace-core-iris.pages.dev/images/62cce3766149ee55e025c2a1_download%20(26).jpeg" style={{ width: "100vw" }}></Image>
				</Box>
				<Box data-aos={'fade-up'}
					sx={{ width: "100vw", height: "50vh", overflow: "hidden", display: "flex", alignItems: "center", justifyContent: "center" }}>
					<Image src="https://realspace-core-iris.pages.dev/images/62cce3766149ee55e025c2a1_download%20(26).jpeg" style={{ width: "100vw" }}></Image>
				</Box>
			</ ListItem >

		</Box>
	);
}