import { GlobalStyles, Box, BoxProps } from "@mui/material";
import { alpha } from "@mui/material/styles";

import bgPattern from "bg-pattern.svg";
import bgPatternDark from "bg-pattern-dark.svg";
import logo from "logo.svg";
import { use100vh } from "react-div-100vh";
import BM_Aerial from "./Assets/BM_Aerial.jpg";

const paper = "#101013"; // "#00BFBF";
const main = "#385657";//"rgb(56, 86, 87)";
const grey = "#343B3D";//"rgb(52, 59, 61)";
const darker = "#2F2F31"; //"#100";
const dark = "#343B3D"; //"#300";
const neutral = "#343B3D"; //"#400";
const black = "#101013"; //"#500";

export default function BrandedBackground()
{
  return (
    <GlobalStyles
      styles={(theme) => `
          body {
            background-size: 100%;
            background: linear-gradient(#0d1f1f, #0d1212);
            background-image: ${
        // prettier-ignore
        [
          // `radial-gradient(circle   at  85% 100%, ${paper} 20%, ${alpha(paper, 0)})`,
          // `radial-gradient(80%  80% at  15% 100%, ${alpha(dark, 0.1)} 25%, ${alpha(darker, 0.1)} 50%, ${alpha(grey, 0)} 100%)`,
          // `linear-gradient(to top, ${alpha(paper, 1)}, ${alpha(paper, 0)})`,
          // `radial-gradient(60% 180% at 100%  15%, ${alpha(neutral, 0.3)} 25%, ${alpha(black, 0.2)} 50%, ${alpha(grey, 0)} 100%)`,
          // `linear-gradient(${alpha(main, 0.2)}, ${alpha(main, 0.2)})`,
        ].join(", ")
        };
          }
          body::before {
            content: "";
            display: block;
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            z-index: -1;

            background-image: url('${bgPatternDark
        }');
            background-size: ${(480 * 10) / 14}px;
            mix-blend-mode: overlay;
          }

          #root#root {
            background-color: transparent;
          }
        `}
    />
  );
}

export function Wrapper(props: BoxProps)
{
  const fullScreenHeight = use100vh() ?? 0;

  return (
    <Box
      {...props}
      sx={{
        display: "grid",
        placeItems: "center",
        alignContent: "center",
        gap: (theme) => ({ xs: theme.spacing(2), sm: theme.spacing(3) }),
        gridAutoRows: "max-content",
        minHeight: fullScreenHeight > 0 ? `${fullScreenHeight}px` : "100vh",

        pt: (theme) => `max(env(safe-area-inset-top), ${theme.spacing(1)})`,
        pb: (theme) => `max(env(safe-area-inset-bottom), ${theme.spacing(1)})`,
        pl: (theme) => `max(env(safe-area-inset-left), ${theme.spacing(1)})`,
        pr: (theme) => `max(env(safe-area-inset-right), ${theme.spacing(1)})`,
        ...props.sx,
      }}
    />
  );
}
