import { IconButton } from "@mui/material";
import { useRecoilValue, useSetRecoilState } from "recoil";
import { rApartmentLabelSlot, rCurrentUnit } from "recoil/atoms";
import UnitPage from "../Pages/UnitPage";
import cross from "Assets/icon-cross-exit.svg";
import { ExteriorContext } from "Exterior/ExteriorContext";
import { BabylonContextProvider } from "Exterior/Babylon/ExteriorBabylonScene";
import { useContext } from "react";
import FullscreenOverlay from "Exterior/components/FullscreenOverlay";
import BasicNavPanel from "Exterior/Panel/BasicNavPanel";
import { FilterBarMemo } from "Exterior/Panel/FilterBar";

export function ExteriorContainer()
{
	const CurrentUnit = useRecoilValue(rCurrentUnit);
	const SetCurrentUnit = useSetRecoilState(rCurrentUnit);

	const { ItemInfoArray } = useContext(ExteriorContext);

	const ApartmentLabel = useRecoilValue(rApartmentLabelSlot);

	let UnitWrapperClassName = '';
	if (CurrentUnit != undefined)
		UnitWrapperClassName = "ExteriorUnitPage";

	let ExteriorDisplayState = "block";
	let UnitPageDisplayState = "none";
	if (CurrentUnit != undefined)
	{
		ExteriorDisplayState = "none";
		UnitPageDisplayState = "block";
	}

	return <div>
		<div className="OverflowHiddenRoot" style={{ display: ExteriorDisplayState }}>
			<img id="Logo" className="CenteredLoadingLogo" src="./LoadingLogo.png"></img>
			<div id="Splash" className="LoadingBackground" style={{ backgroundColor: "#757575", width: "100vw", height: "100vh" }}></div>
			{/* <ApartmentTable title="hi" data={apartmentData}></ApartmentTable> */}

			<div className="ReactUIContainer">
				<BabylonContextProvider></BabylonContextProvider>
				<div id="ReactUI">
					{ApartmentLabel}
					{ItemInfoArray === undefined || ItemInfoArray.length <= 0 ? <BasicNavPanel></BasicNavPanel> : <FilterBarMemo></FilterBarMemo>}
				</div>
			</div>
		</div>
		<div style={{ display: UnitPageDisplayState }}
			className={UnitWrapperClassName}>
			<UnitPage />
			{CurrentUnit != undefined && <IconButton onClick={() => { SetCurrentUnit(undefined); }} style={{ position: "fixed", right: "3.8%", top: "3.2%", backgroundColor: "#FFF", padding: "0.9%", boxSizing: "content-box", borderRadius: 0 }}>
				<img alt="" src={cross} width={24}></img>
			</IconButton>}
		</div>
		<FullscreenOverlay />
	</div>;
}
