import { UniversalCamera, Vector2 } from "@babylonjs/core";
import { ApartmentInfo, tUnitInfoType } from "components/Types";
import { ReactNode } from "react";
import { atom } from "recoil";

export const rCMSDataLoaded = atom<boolean>({
	key: "rCMSDataLoaded",
	default: false,
});

export const rCurrentUnit = atom<typeof tUnitInfoType | undefined>({
	key: "rCurrentUnit",
	default: undefined,
});

export const rUseDisplayOverride = atom<boolean>({
	key: "rUseDisplayOverride",
	default: false,
});

export const rOverlayInput = atom<[string, string]>({
	key: "rOverlayInput",
	default: ["", ""],
});

export const rOverlayButtonEnabled = atom<boolean>({
	key: "rOverlayButtonEnabled",
	default: false,
});

// Exterior Interative
export const rCurrentFrame = atom<number>({
	key: "rCurrentFrame",
	default: 0,
});

export const rPOIPositions = atom<[string, Vector2][]>({
	key: "rPOIPositions",
	default: [],
});

export const rPOIArray = atom<ReactNode[]>({
	key: "rPOIArray",
	default: [],
});

export const ApartmentLabelPosition = atom<Vector2>({
	key: "ApartmentLabelPosition",
	default: new Vector2(),
});

export const rApartmentLabelSlot = atom<ReactNode>({
	key: "rApartmentLabelSlot",
	default: <></>,
});

export const rApartmentInfo = atom<ApartmentInfo>({
	key: "rApartmentInfo",
	default: { type: "", unit: "", data: [] },
});

export const rMainCamera = atom<UniversalCamera | undefined>({
	key: "rMainCamera",
	default: undefined,
});

export const firebaseSignedIn = atom<boolean>({
	key: "firebaseSignedIn",
	default: false,
});

export const rMediaData = atom<Object[]>(
	{
		key: "rMediaData",
		default: [],
	}
)
export const rLevelData = atom<Object[]>(
	{
		key: "rLevelData",
		default: [],
	}
)
export const rBuildingData = atom<Object[]>(
	{
		key: "rBuildingData",
		default: [],
	}
)

export const rPoIData = atom<Object[]>(
	{
		key: "rPoIData",
		default: [],
	}
)

export const rTypeData = atom<Object[]>(
	{
		key: "rTypeData",
		default: [],
	}
)

export const rUnitData = atom<Object[]>(
	{
		key: "rUnitData",
		default: [],
	}
)

export const rMiscData = atom<Object[]>(
	{
		key: "rMiscData",
		default: [],
	}
)

