import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { GlobalContextProvider } from "./context/GlobalContext";
import { RecoilRoot } from "recoil";
import { Box, ListItem } from "@mui/material";
import Page from "components/Page";
import Image from "components/Image";
import { BrowserRouter } from "react-router-dom";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { Auth0Provider } from "@auth0/auth0-react";

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement);

// Configure Firebase.
const firebaseConfig = {
	apiKey: "AIzaSyBkz6XIoRNsXWtTPfc7m2nsRyX_2uuRmDs",
	authDomain: "iris-90830.firebaseapp.com",
	projectId: "iris-90830",
	storageBucket: "iris-90830.appspot.com",
	messagingSenderId: "497274020230",
	appId: "1:497274020230:web:a94d44733028b220a10c07",
	measurementId: "G-EDENW8P2FR"
};
firebase.initializeApp(firebaseConfig);

root.render(
	<GlobalContextProvider>
		<RecoilRoot>
			<BrowserRouter>
				<Auth0Provider
					domain="realspace.au.auth0.com"
					audience="https://realspace.au.auth0.com/api/v2/"//"https://realspace.au.auth0.com/api/v2/"
					clientId="TfMeriTAkgQIhGM7Stn7hd7EOhrlgRt8"
					redirectUri={window.location.origin}
					responseType="token id_token"
					scope="openid profile"//"read:current_user update:current_user_metadata"
				>
					<App />
				</Auth0Provider>
			</BrowserRouter>
		</RecoilRoot>
	</GlobalContextProvider >
);
// domain: 'realspace.au.auth0.com',
// audience: 'https://realspace.au.auth0.com/api/v2/',
// clientID: 'TfMeriTAkgQIhGM7Stn7hd7EOhrlgRt8',
// redirectUri: 'http://localhost:3001/',
// responseType: 'token id_token',
// scope: 'openid profile'


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
