import * as React from "react";
import { useEffect, useReducer } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Slider, { SliderProps } from "@mui/material/Slider";
import { styled, useTheme } from "@mui/material/styles";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import IconButton from "@mui/material/IconButton";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import HideFiltersImage from "Assets/Hide-Filters.png";

import { Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";

import { ExteriorContext } from "Exterior/ExteriorContext";
import { useRecoilValue } from "recoil";
import TypeDropdown from "Exterior/components/TypeDropdown";
import { MinimumDistanceSlider } from "Exterior/components/MinimumDistanceSlider";
import { ToggleCheckbox } from "Exterior/components/ToggleCheckbox";
import { SelectorButtonGroup } from "Exterior/components/SelectorButtonGroup";
import { rUseDisplayOverride } from "recoil/atoms";
import { MemoFilterCheckboxComponent } from "Core/FilterCheckboxComponent";

export const FieldInfoType = { min: 0, max: 100 };

const FilterBar = (prop: {}) =>
{

	const InitialFiltersState =
	{

	};

	//Reduced for Filter States 
	const FilterReducer = (state: {},
		action: FiltersAction) =>
	{
		let tempState: {};
		switch (action.type)
		{
			case 'boolUpdate':
				tempState = structuredClone(state);
				for (let i = 0; i < action.payload.DisableStates.length; i++)
				{
					tempState[action.payload.DisableStates[i]] = false;
				}
				tempState[action.payload.FilterName] = action.payload.State;
				return tempState;
			case 'reset':
				setResetFilters(true);
				return InitialFiltersState;
			case 'increment':
				tempState = structuredClone(state);
				tempState[action.payload.FilterName] = (tempState[action.payload.FilterName] + 1);
				return tempState;
			case 'decrement':
				tempState = structuredClone(state);
				tempState[action.payload.FilterName] = (tempState[action.payload.FilterName] - 1);
				return tempState;
			case 'numUpdate':
				return { ...state, [action.payload.FilterName]: action.payload.NumberState };
			case 'initialize':
				return action.payload;
			case 'minNumUpdate':
				tempState = structuredClone(state);
				if (typeof tempState[action.payload.FilterName] === typeof [])
				{
					if (tempState[action.payload.FilterName].length === 2)
					{
						if (typeof tempState[action.payload.FilterName][0] === 'number' && typeof tempState[action.payload.FilterName][1] === 'number')
						{
							tempState[action.payload.FilterName][0] = action.payload.NumberState;
							return tempState;
						}
					}
				}
				break;
			case 'maxNumUpdate':
				tempState = structuredClone(state);
				if (typeof tempState[action.payload.FilterName] === typeof [])
				{
					if (tempState[action.payload.FilterName].length === 2)
					{
						if (typeof tempState[action.payload.FilterName][0] === 'number' && typeof tempState[action.payload.FilterName][1] === 'number')
						{
							tempState[action.payload.FilterName][1] = action.payload.NumberState;
							return tempState;
						}
					}
				}
				break;
			default:
				throw new Error('Unknown action type: ${action.type}');
		}
	}


	const [UnionFiltersState, UnionFiltersDispatch] = useReducer(FilterReducer, InitialFiltersState);
	const [IntersectionFiltersState, IntersectionFiltersDispatch] = useReducer(FilterReducer, InitialFiltersState);


	function ParseFilterStates()
	{
		let NewFilters = new Map();
		function ParseStateObjectToMap(FilterObject: any, FilterMap: Map<any, any>, IsUnion: boolean)
		{
			Object.entries(FilterObject).forEach(property =>
			{
				let NumberTuple;
				switch (typeof property[1])
				{
					case 'number':
						NumberTuple = [property[1], property[1]];
						FilterMap.set(property[0], NumberTuple);
						break;
					case typeof []:
						NumberTuple = property[1] as [number, number];
						if (NumberTuple)
						{
							if (NumberTuple.length === 2)
							{
								if (typeof property[1][0] === 'number' && typeof property[1][1] === 'number')
								{
									FilterMap.set(property[0], NumberTuple);
								}
							}
						}
						break;
					case 'boolean':
						if (property[0].includes(">"))
						{
							if (IsUnion)
							{
								if (property[1] === true)
								{
									let ValueString: string;
									let Name = property[0].split(">")[0];
									let Value = property[0].split(">")[1];
									if (FilterMap.get(Name))
									{
										let FilterString = (FilterMap.get(Name) as string[]);
										if (FilterString && FilterString.length == 2)
										{
											ValueString = FilterString[0];
										}
									}
									if (ValueString)
									{

										let Found = ValueString.includes(Value);
										if (!Found)
										{
											ValueString += ("||" + Value);
										}
										FilterMap.set(Name, [ValueString, ""]);
									}
									else
									{
										ValueString = Value;
										FilterMap.set(Name, [ValueString, ""]);
									}

								}
								else
								{
									let ValueString: string;
									let Name = property[0].split(">")[0];
									let Value = property[0].split(">")[1];
									if (FilterMap.get(Name))
									{
										let FilterString = (FilterMap.get(Name) as string[]);
										if (FilterString && FilterString.length == 2)
										{
											ValueString = FilterString[0];
										}
									}
									else
									{
										FilterMap.set(Name, ["", ""]);
									}
									if (ValueString)
									{
										let Found = ValueString.includes(Value);
										if (Found)
										{
											ValueString.replace(Value, "");
										}
										FilterMap.set(Name, [ValueString, ""]);
									}
								}
							}
							else
							{
								if (property[1] === true)
								{
									let ValueArray: string[] = [];
									let Name = property[0].split(">")[0];
									let Value = property[0].split(">")[1];
									if (FilterMap.get(Name))
									{
										let FilterArray = (FilterMap.get(Name) as string[]);
										if (FilterArray)
										{
											ValueArray = FilterArray;
										}
									}
									let found = ValueArray.findIndex((item, index) =>
									{
										if (item === Value)
										{
											return true;
										}
										return false;
									})
									if (found === -1)
									{
										ValueArray.push(Value);
									}
									FilterMap.set(Name, ValueArray);
								}
								else
								{
									let ValueArray: string[] = [];
									let Name = property[0].split(">")[0];
									let Value = property[0].split(">")[1];
									if (FilterMap.get(Name))
									{
										let FilterArray = (FilterMap.get(Name) as string[]);
										if (FilterArray)
										{
											ValueArray = FilterArray;
										}
									}
									let found = ValueArray.findIndex((item, index) =>
									{
										if (item === Value)
										{
											return true;
										}
										return false;
									})
									if (found !== -1)
									{
										ValueArray.splice(found);
									}
									FilterMap.set(Name, ValueArray);
								}
							}
						}
						else
						{
							FilterMap.set(property[0], true);
						}
						break;
					default:
						break;
				}
			});
		}

		if (IntersectionFiltersState)
		{
			ParseStateObjectToMap(IntersectionFiltersState, NewFilters, false);
		}
		if (UnionFiltersState)
		{
			ParseStateObjectToMap(UnionFiltersState, NewFilters, true);
		}
		return NewFilters;
	}

	const theme = useTheme();
	let drawerWidth = window.innerWidth / 7;
	if (drawerWidth < 270)
		drawerWidth = 270;
	const DefaultFontWindowHeight = 1440;
	let FontScalingRatio = window.innerHeight / DefaultFontWindowHeight;
	let Header1FontSize = 40 * FontScalingRatio;
	let Header2FontSize = 30 * FontScalingRatio;
	let SectionHeaderFontSize = 18 * FontScalingRatio;
	let ButtonFontSize = 20 * FontScalingRatio;
	let SliderValueFontSize = 20 * FontScalingRatio;
	let CheckBoxFontSize = 15 * FontScalingRatio;
	let CheckBoxSize = 30 * FontScalingRatio;
	const { CurrentFilters, SetCurrentFilters, UnitTypes } = React.useContext(ExteriorContext);
	const { ParsedItemInfoArray } = React.useContext(ExteriorContext);
	const { SetFiltersEnabled } = React.useContext(ExteriorContext);
	const UseDisplayOverride = useRecoilValue(rUseDisplayOverride);

	const [open, setOpen] = React.useState(false);
	const [FieldInfos, setFieldInfos] = React.useState(new Map<string, typeof FieldInfoType>());
	let ToggleSetsTemplate: [string, [string, string][]][] = [["", [["", ""]]]];
	const [ToggleSets, setToggleSets] = React.useState(ToggleSetsTemplate);
	const [ResetFilters, setResetFilters] = React.useState<boolean>(false);
	// Constants
	const SelectorSets: [string, string][] = [["Bedrooms", "bedrooms"]];
	const ToggleCategoryDisplays: Map<string, string> = new Map<string, string>([["availability", "Availability"]]);
	const ToggleOptionDisplays: Map<string, string> = new Map<string, string>([
		["Available", "Available"],
		["Sold", "Sold"],
		["Held", "Held"],
	]);
	const SliderSets: [string, string][] = [
		["Area m2", "size"],
		["Price $", "price"],
		["Floors", "level"],
	];

	let CurrentSliderDisplayRanges: Map<string, [string, string]> = new Map<string, [string, string]>();
	// const [typeDropdown, setTypeDropdown] = React.useState(<TypeDropdown key={""} typename="type"></TypeDropdown>);
	/************************************** BASE SETUP **************************************/
	useEffect(() =>
	{
		if (ResetFilters === true)
		{
			setResetFilters(false);
		}
	}, [ResetFilters])
	useEffect(() =>
	{
		// Initial Setup
		SelectorSets.forEach((element) =>
		{
			const ItemInfoKey = element[1];
			let currentMin = 99999999,
				currentMax = -999999,
				currentStart = 0,
				currentEnd = 0;
			FieldInfos.set(ItemInfoKey, { min: currentMin, max: currentMax });
		});
		SliderSets.forEach((element) =>
		{
			const ItemInfoKey = element[1];
			let currentMin = 99999999,
				currentMax = -999999,
				currentStart = 0,
				currentEnd = 0;
			FieldInfos.set(ItemInfoKey, { min: currentMin, max: currentMax });
		});

		SetNumberControls();
		// let ChangedFilters = new Map(CurrentFilters);
		// ChangedFilters.set("Availability", ["Availabile", ""] as [string, string]);
		// SetCurrentFilters(ChangedFilters);
		// console.log("asdasgrger");
	}, []);

	useEffect(() =>
	{
		// On Parsed Item info array changed (for when it was empty on initial use effect)
		SetNumberControls();
	}, [ParsedItemInfoArray]);

	const handleDrawerClose = () =>
	{
		setOpen(!open);
		SetFiltersEnabled(!open);
	};


	useEffect(() =>
	{
		console.log(CurrentFilters);

	}, [CurrentFilters])
	useEffect(() =>
	{
		let FiltersMap = ParseFilterStates();
		let CurrentFiltercompare = CurrentFilters;
		SetCurrentFilters(FiltersMap);
	}, [UnionFiltersState, IntersectionFiltersState])
	/************************************** NUMBER BASED UI **************************************/

	function CheckMinMaxFromItem(element: [string, string], ItemInfo: Map<string, string | number | boolean>)
	{
		const ItemInfoKey = element[1];
		let CurrentFieldInfo = FieldInfos.get(ItemInfoKey);
		let CurrentValue = ItemInfo.get(ItemInfoKey);

		if (CurrentValue && Number.isInteger(CurrentValue) && CurrentFieldInfo)
		{
			const CurrentNumber = CurrentValue as number;
			if (CurrentNumber < CurrentFieldInfo?.min)
			{
				CurrentFieldInfo.min = CurrentNumber;
			}
			if (CurrentNumber > CurrentFieldInfo?.max)
			{
				CurrentFieldInfo.max = CurrentNumber;
			}
		}
	}

	function SetNumberControls()
	{
		if (ParsedItemInfoArray == undefined) return;

		ParsedItemInfoArray.forEach((ItemInfo) =>
		{
			if (ItemInfo.size > 0)
			{
				SelectorSets.forEach((element) =>
				{
					CheckMinMaxFromItem(element, ItemInfo);
				});
				SliderSets.forEach((element) =>
				{
					CheckMinMaxFromItem(element, ItemInfo);
				});
			}
		});
		let IntersectionFiltersObject = {};
		let UnionFilterObjects = {};
		let ChangedFilters = new Map(CurrentFilters);
		for (let i = SelectorSets.length - 1; i >= 0; i--)
		{
			const ItemInfoKey = SelectorSets[i][1];
			let CurrentFieldInfo = FieldInfos.get(ItemInfoKey);
			// Initial selector as min (include all)
			const CurrentSet = [CurrentFieldInfo?.min, CurrentFieldInfo?.max] as [number, number];
			if (CurrentSet[0] >= 99999999)
			{
				SelectorSets.splice(i);
			} else
			{
				IntersectionFiltersObject[ItemInfoKey] = CurrentSet;
				ChangedFilters.set(ItemInfoKey, CurrentSet);
			}
		}
		SliderSets.forEach((element) =>
		{
			const ItemInfoKey = element[1];
			let CurrentFieldInfo = FieldInfos.get(ItemInfoKey);

			IntersectionFiltersObject[ItemInfoKey] = [CurrentFieldInfo?.min, CurrentFieldInfo?.max];
			ChangedFilters.set(ItemInfoKey, [CurrentFieldInfo?.min, CurrentFieldInfo?.max] as [number, number]);
		});
		// FiltersObject["SHAREDSPACES"] = false;
		ChangedFilters.set("SHAREDSPACES", [false, false] as [boolean, boolean]);

		// Create empty new toggle set
		let NewToggleSets: [string, [string, string][]][] = [["", [["", ""]]]];
		while (NewToggleSets.length > 0)
		{
			NewToggleSets.pop();
		}

		let TogglesCategoriesKeys = Array.from(ToggleCategoryDisplays.keys());
		// Toggle categories to look for
		for (let i = 0; i < TogglesCategoriesKeys.length; i++)
		{
			const ToggleCategory = TogglesCategoriesKeys[i];
			//const ToggleCategoryDisplay = ToggleCategoryDisplays.get(ToggleCategory);
			let CurrentAddedOptions: string[] = [];

			const NewToggleSet: [string, string][] = [];

			ParsedItemInfoArray.forEach((element) =>
			{
				if (element.has(ToggleCategory))
				{
					// Current Toggle option for this element
					let ToggleOption = element.get(ToggleCategory) as string;

					// Only add new option that hasn't been added
					if (!CurrentAddedOptions.includes(ToggleOption) && ToggleOption !== "")
					{
						CurrentAddedOptions.push(ToggleOption);
						let DisplayOption = ToggleOption;
						if (ToggleOptionDisplays.has(ToggleOption)) DisplayOption = ToggleOptionDisplays.get(ToggleOption) as string;
						NewToggleSet.push([ToggleOption, DisplayOption]);
					}
				}
			});
			// Add toggle set with category
			if (NewToggleSet.length > 0) NewToggleSets.push([ToggleCategory, NewToggleSet]);
		}
		setToggleSets(NewToggleSets);

		for (let i = 0; i < TogglesCategoriesKeys.length; i++)
		{
			const ToggleCategory = TogglesCategoriesKeys[i];
			let SplitUp: string[] = [];
			ParsedItemInfoArray.forEach((element) =>
			{
				let Option = element.get(ToggleCategory) as string;
				if (Option && !SplitUp.includes(Option))
				{
					SplitUp.push(Option);
				}
			});
			//Default Availability to Available only

			if (SplitUp.includes("Available"))
			{
				UnionFilterObjects[ToggleCategory + ">Available"] = true;
				ChangedFilters.set(ToggleCategory, ["Available", ""] as [string, string]);
			}
			else
			{
				SplitUp.forEach((element) =>
				{
					UnionFilterObjects[ToggleCategory + ">" + element] = true;
				});
				ChangedFilters.set(ToggleCategory, [SplitUp.join("||"), ""] as [string, string]); //all availablitity
			}

		}
		//Unit Types
		const filterNames: string[] = Array.from(UnitTypes.keys()).sort();
		ChangedFilters.set("type", [filterNames.join("||"), ""] as [string, string]);
		filterNames.forEach((element) =>
		{
			IntersectionFiltersObject["type" + ">" + element] = false;
		});

		//complete reset
		IntersectionFiltersDispatch(
			{
				type: 'initialize',
				payload: IntersectionFiltersObject,
			}
		)
		UnionFiltersDispatch(
			{
				type: 'initialize',
				payload: UnionFilterObjects,
			}
		)


		SetCurrentFilters(ChangedFilters);
		setFieldInfos(new Map(FieldInfos));
		//setTypeDropdown(<TypeDropdown key={Math.random().toString()} typename="Type"></TypeDropdown>);
		//console.log(ChangedFilters);
		//console.log(CurrentFilters);
	}

	/************************************** SLIDERS **************************************/

	// Set Slider range display texts and formatting
	SliderSets.forEach((element) =>
	{
		let CurrentRange = [99999999, -999999];
		if (CurrentFilters.has(element[1]))
		{
			CurrentRange = structuredClone(CurrentFilters.get(element[1]) as [number, number]);
		}
		let CurrentDisplayRange = ["", ""];
		for (let index = 0; index < CurrentRange.length; index++)
		{
			// Override strings, if overriding, make sure to set CurrentDisplayRange
			switch (element[1])
			{
				case "price": {
					let suffix = "k";
					CurrentRange[index] /= 1000;
					// If in the millions
					if (CurrentRange[index] > 999)
					{
						// Round to 1 decimal
						CurrentRange[index] /= 1000;
						suffix = "m";
						CurrentDisplayRange[index] = CurrentRange[index].toFixed(1) + suffix;
					} // fully round if not in the millions
					else
					{
						CurrentRange[index] = Math.round(CurrentRange[index]);
						CurrentDisplayRange[index] = CurrentRange[index].toFixed(0) + suffix;
					}
					break;
				}
				default: {
					// Default rounds and changes to string
					CurrentRange[index] = Math.round(CurrentRange[index]);
					CurrentDisplayRange[index] = CurrentRange[index].toString();
				}
			}
		}
		CurrentSliderDisplayRanges.set(element[1], [CurrentDisplayRange[0], CurrentDisplayRange[1]]);
	});

	/************************************** TOGGLES **************************************/

	// ToggleSets.pop();
	// ParsedItemInfoArray.forEach((element) => {
	// 	let Availability = element.get("SOLD") as string;
	// 	let AlreadyExists = false;
	// 	for (let i = 0; i < ToggleSets.length; i++)
	// 	{
	// 		if (ToggleSets[i][0] == Availability)
	// 		AlreadyExists = true;
	// 	}
	// 	if (Availability && !AlreadyExists)
	// 	{
	// 		ToggleSets.push([Availability, "SOLD"]);
	// 	}
	// });
	/************************************** SHARED SPACES **************************************/

	let SharedSpacesEnabled: boolean = false;
	{
		const SharedSpacesVal = CurrentFilters.get("SHAREDSPACES");
		if (SharedSpacesVal) SharedSpacesEnabled = SharedSpacesVal[0] as boolean;
	}
	function ResetFiltersToDefault()
	{
		// SelectorSets.forEach((element) => {
		// 	const ItemInfoKey = element[1];
		// 	let currentMin = 99999999, currentMax = -999999, currentStart = 0, currentEnd = 0;
		// 	FieldInfos.set(ItemInfoKey, { min: currentMin, max: currentMax });
		// });
		// SliderSets.forEach((element) => {
		// 	const ItemInfoKey = element[1];
		// 	let currentMin = 99999999, currentMax = -999999, currentStart = 0, currentEnd = 0;
		// 	FieldInfos.set(ItemInfoKey, { min: currentMin, max: currentMax });
		// });
		//Unit Types
		// let ChangedFilters = new Map(CurrentFilters);
		// const filterNames: string[] = Array.from(UnitTypes.keys()).sort();
		// ChangedFilters.set("Type", ["RESET", ""] as [string, string]);
		// SetCurrentFilters(ChangedFilters);
		SetNumberControls();
		setResetFilters(true);
		// SharedSpacesEnabled = false;
		// let ChangedFilters = new Map(CurrentFilters);
		// ChangedFilters.set("SHAREDSPACES", [SharedSpacesEnabled, SharedSpacesEnabled] as [boolean, boolean])
		// SetCurrentFilters(ChangedFilters);
	}

	function SwitchSharedSpaces()
	{
		SharedSpacesEnabled = !SharedSpacesEnabled;
		let ChangedFilters = new Map(CurrentFilters);
		ChangedFilters.set("SHAREDSPACES", [SharedSpacesEnabled, SharedSpacesEnabled] as [boolean, boolean]);
		SetCurrentFilters(ChangedFilters);
	}

	function ExitClicked()
	{
		if (UseDisplayOverride)
		{
			(window as { [key: string]: any; })['blu_event']("CloseExploreExterior", "");
		}
		else
		{
			window.location.href = "/";
		}
	}

	return (
		<Box component="div" sx={{ display: "flex" }}>
			<CssBaseline />
			{/* <Button onClick={handleDrawerOpen}>
				<MenuIcon />
			</Button> */}
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
						background: "rgba(202, 214, 220, 0.6)",
						backdropFilter: "blur(23px)",
						borderWidth: 0,
						padding: theme.spacing(5, 4),
						overflowY: "visible !important",
					},
				}}
				variant="persistent"
				anchor="right"
				open={open}>
				<div style={{ display: "flex", justifyContent: "center", position: "absolute", left: -24, top: "calc(50% - 24px)", visibility: "visible", width: 24, height: 96, backgroundImage: "url(" + HideFiltersImage + ")", backgroundSize: "cover" }}>
					<IconButton style={{ visibility: "visible" }} onClick={handleDrawerClose}>
						{!open ? <ChevronLeftIcon /> : <ChevronRightIcon />}
					</IconButton>
				</div>
				{/* Title */}
				<Typography variant="h3" fontSize={SharedSpacesEnabled ? Header2FontSize : Header1FontSize} fontWeight={500} color={"#00263A"}>
					{SharedSpacesEnabled ? "Shared Spaces" : "Filters"}
				</Typography>
				{!SharedSpacesEnabled && (
					<div>
						<List>
							{SelectorSets.map((Values, index) => (
								<SelectorButtonGroup FontSize={SectionHeaderFontSize} key={index} DisplayName={Values[0]} InfoName={Values[1]} value={index} min={FieldInfos.get(Values[1])?.min as number} max={FieldInfos.get(Values[1])?.max as number} FieldInfos={FieldInfos}></SelectorButtonGroup>
							))}
						</List>
						{/* Sliders */}
						<List>
							{SliderSets.map((Values, index) => (
								<div key={index}>
									<ListItem disablePadding>
										<TableContainer>
											<Table aria-label="simple table">
												<TableBody>
													<TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
														<TableCell sx={{ fontSize: SliderValueFontSize, color: "#00263A", paddingLeft: 0, paddingRight: 0 }} align="left">
															{(CurrentSliderDisplayRanges?.get(Values[1]) as [string, string])[0]}
														</TableCell>
														<TableCell sx={{ fontSize: SliderValueFontSize, color: "#626A6D" }} align="center">
															{Values[0]}
														</TableCell>
														<TableCell sx={{ fontSize: SliderValueFontSize, color: "#00263A", paddingLeft: 0, paddingRight: 0 }} align="right">
															{(CurrentSliderDisplayRanges?.get(Values[1]) as [string, string])[1]}
														</TableCell>
													</TableRow>
												</TableBody>
											</Table>
										</TableContainer>
									</ListItem>
									<div>
										<ListItem disablePadding>
											<MinimumDistanceSlider Name={Values[1]} MinRange={FieldInfos.get(Values[1])?.min as number} MaxRange={FieldInfos.get(Values[1])?.max as number} FieldInfos={FieldInfos}></MinimumDistanceSlider>
										</ListItem>
									</div>
								</div>
							))}
						</List>
						{/* Toggles */}
						{ToggleSets.map((ToggleSet, index) => (
							<List key={index}>
								<ListItem disablePadding>
									{/* "Availability" */}
									<ListItemText primary={ToggleCategoryDisplays.get(ToggleSet[0])} sx={{ "& .MuiTypography-root": { fontSize: SectionHeaderFontSize, color: "#00263A" } }}></ListItemText>
								</ListItem>
								{ToggleSet[1].map((Values, index) => (
									<>
										<MemoFilterCheckboxComponent CheckboxName={Values[1] + " New"} FilterName={ToggleSet[0] + ">" + Values[0]} reset={ResetFilters} DefaultState={true} FilterStateDispatch={UnionFiltersDispatch}></MemoFilterCheckboxComponent>
										{/* <ToggleCheckbox CheckBoxSize={CheckBoxSize} FontSize={CheckBoxFontSize} key={index} InfoName={Values[0]} DisplayName={Values[1]} DataCategory={ToggleSet[0]}></ToggleCheckbox> */}
									</>
								))}
							</List>
						))}
						<TypeDropdown key={""} typename="type" reset={ResetFilters}></TypeDropdown>
					</div>
				)}

				<Button variant="outlined" style={{ fontSize: ButtonFontSize, color: "#00263A", borderRadius: 0, borderColor: "#000000", padding: 10, marginTop: 25, marginRight: 0, textTransform: "none" }} onClick={SwitchSharedSpaces}>
					{SharedSpacesEnabled ? "Back to Apartments" : "Shared Spaces"}
				</Button>
				<Button variant="outlined" style={{ fontSize: ButtonFontSize, color: "#00263A", borderRadius: 0, borderColor: "#000000", padding: 10, marginTop: 25, marginRight: 0, textTransform: "none" }} onClick={ResetFiltersToDefault}>
					{"Reset Filters"}
				</Button>
				<Button variant="contained" style={{ fontSize: ButtonFontSize, color: "#FFF", backgroundColor: "#00bfbf", boxShadow: "none", borderRadius: 0, borderColor: "#000000", padding: 10, marginTop: 25, marginRight: 0, textTransform: "none", position: "absolute", bottom: 25, right: 25, left: 25, width: "calc(100% - 50px)" }} onClick={ExitClicked}>
					{"EXIT"}
				</Button>
			</Drawer>
		</Box>
	);
};

function filterPropsAreEqual(PrevProps: any, NextProps: any)
{
	return false;
}


export const FilterBarMemo = React.memo(FilterBar, filterPropsAreEqual);
