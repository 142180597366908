import { RedirectLoginOptions, useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import React from "react";
import AuthLayout from "../AuthLayout";

const LoginButton = () =>
{
    const { loginWithRedirect } = useAuth0();

    return (
        <AuthLayout>
            <Button variant="outlined" onClick={() => loginWithRedirect()}>Log In</Button>
        </AuthLayout>

    );
};

export default LoginButton;