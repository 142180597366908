import { Box, Container } from '@mui/material';

const backgroundImage =
	'https://realspace-core-iris.pages.dev/images/62c428b543620ea167977813_BM_Aerial.jpg';

export default function HomePage()
{
	return (
		<Container
			sx={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				height: "100%",
			}}
		>
			<Box sx={{
				position: 'absolute',
				left: "9vh",
				bottom: "9vh",
			}}
			>
				<img
					src="https://realspace-core-iris.pages.dev/images/62c4feb13c2c9f2d9e4d4780_V-A-Monogram.svg"
					alt="Project-Logo"
					width="248"
					height="110"
				/>
			</Box>
			<Box
				sx={{
					position: 'relative',
					width: "100vw",
					height: "100%",
					backgroundColor: 'common.black',
					overflow: 'hidden',
				}}>

				<iframe width="100%" height="100%" src="https://api.mapbox.com/styles/v1/gross1000/cl7qupzek000015ntmrwlomab.html?title=false&access_token=pk.eyJ1IjoiZ3Jvc3MxMDAwIiwiYSI6ImNsN3F1bDB1bzAxY3M0MW55bGE1OGVvY3QifQ.7KkB-AuA-W22lOXXWBbasg&zoomwheel=true#15.4/-28.028315/153.431943/313.7/56" title="Monochrome" style={{ border: "none" }}></iframe>

			</Box>



		</Container>
	);
}