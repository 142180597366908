import BrandedBackground, { Wrapper } from "BrandedBackground";
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import Page from './Page';
import { LogOutButton } from "./LogOutButton";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useSetRecoilState } from 'recoil';
import { firebaseSignedIn } from 'recoil/atoms';

const validationSchema = yup.object({
  currentPassword: yup.string().required('Please specify your password'),
  newPassword: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
  repeatPassword: yup
    .string()
    .required('Please specify your password')
    .min(8, 'The password should have at minimum length of 8'),
});

const ChangePasswordPage = (): JSX.Element =>
{
  const [hasSentPasswordResetEmail, setHasSentPasswordResetEmail] = useState<boolean>(false);
  const initialValues = {
    currentPassword: '',
    newPassword: '',
    repeatPassword: '',
  };

  const onSubmit = (values) =>
  {
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  function sendResetPasswordRequest(): React.MouseEventHandler<HTMLButtonElement>
  {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be in the authorized domains list in the Firebase Console.
      url: "https://iris-90830.firebaseapp.com",
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: 'com.iris-90830.ios'
      },
      android: {
        packageName: 'com.iris-90830.android',
        installApp: true,
        minimumVersion: '12'
      },
      dynamicLinkDomain: 'realspacecms.page.link'
    };
    return () =>
    {
      firebase.auth()
        //.sendPasswordResetEmail(firebase.auth().currentUser.email)
        //.sendSignInLinkToEmail("buildmediatestuser2@gmail.com", actionCodeSettings)
        .sendPasswordResetEmail(firebase.auth().currentUser.email)
        .then(function (e)
        {
          setHasSentPasswordResetEmail(true);
        })
        .catch(function (error) { console.log(error); });
    };
  }

  return (
    <Page>
      <Box>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          <Typography variant="h6" fontWeight={700}>
            Change your password:
          </Typography>
          <Typography variant="subtitle2" fontWeight={700} color="grey">
            {firebase.auth().currentUser.email}
          </Typography>
          {<LogOutButton />}
        </Box>
        <Box paddingY={4}>
          <Divider />
        </Box>
        <form onSubmit={formik.handleSubmit}>
          <Grid container spacing={4}>
            <Grid item container xs={12}>
              <Box
                display="flex"
                flexDirection={{ xs: 'column', sm: 'row' }}
                alignItems={{ xs: 'stretched', sm: 'center' }}
                justifyContent={'space-between'}
                width={1}
                margin={'0 auto'}
              >
                <Box marginBottom={{ xs: 1, sm: 0 }}>
                </Box>
                {
                  hasSentPasswordResetEmail
                    ?
                    <Typography variant="subtitle2" fontWeight={700} color="grey">
                      {"A password reset email has been sent to " + firebase.auth().currentUser.email}
                    </Typography>
                    :
                    <Button size={'large'} variant={'contained'} type={'submit'} onClick={sendResetPasswordRequest()}>
                      Send Reset password Request
                    </Button>
                }

              </Box>
            </Grid>
          </Grid>
        </form>
      </Box>
    </Page>

  );
};

export default ChangePasswordPage;


//onClick={() => { firebase.auth().sendPasswordResetEmail("useremail"); }}

//firebase.auth().currentUser.verifyBeforeUpdateEmail("newEmail");
// const actionCodeSettings = {
		// 	// URL you want to redirect back to. The domain (www.example.com) for
		// 	// this URL must be whitelisted in the Firebase Console.
		// 	url: 'https://www.example.com/checkout?cartId=1234',
		// 	// This must be true for email link sign-in.
		// 	handleCodeInApp: true,
		// 	iOS: {
		// 		bundleId: 'com.example.ios',
		// 	},
		// 	android: {
		// 		packageName: 'com.example.android',
		// 		installApp: true,
		// 		minimumVersion: '12',
		// 	},
		// 	// FDL custom domain.
		// 	dynamicLinkDomain: 'coolapp.page.link',
		// };
		// const useremail = 'christopher@buildmedia.com';
		// 		// const useremail = 'christopher@buildmedia.com';
		// firebase.auth().sendPasswordResetEmail(useremail);

		// import { getAuth, updateEmail } from "firebase/auth";
		// const auth = getAuth();
		// updateEmail(auth.currentUser, "user@example.com").then(() =>
		// {
		// 	// Email updated!
		// 	// ...
		// }).catch((error) =>
		// {
		// 	// An error occurred
		// 	// ...
		// });
