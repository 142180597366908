
import React, { useEffect, useRef } from "react";
import { Outlet } from "react-router-dom";
import AppBar from "./AppBar";
import HomePage from "../Pages/HomePage";
import withRoot from "../components/withRoot";


const CoreContainer = () =>
{


	return (
		<React.Fragment >
			<div style={{ display: "flex", flexDirection: "column", width: "100%", height: "100vh" }}>
				<AppBar />
				<Outlet />
			</div>

		</React.Fragment>
	);
};

export default withRoot(CoreContainer);
