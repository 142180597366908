import { atom, useRecoilValue, useResetRecoilState, useSetRecoilState } from "recoil";
import ButtonMUI from "@mui/material/Button";
import { Table, TableBody, TableRow, TableCell, TableHead, IconButton, Box, Button, createTheme, Paper, styled, SwipeableDrawer, ThemeProvider } from "@mui/material";
import { useEffect, useState, useRef } from "react";
import React from "react";
import cross from "Assets/icon-cross-exit.svg";
import { rOverlayButtonEnabled, rOverlayInput, rUseDisplayOverride } from "recoil/atoms";

const FullscreenOverlay = () =>
{
	const RecoilOverlayType: [string, string] = useRecoilValue(rOverlayInput);
	const RecoilOverlayButtonEnabled: boolean = useRecoilValue(rOverlayButtonEnabled);
	const resetOverlayInput = useResetRecoilState(rOverlayInput);
	const [isDrawerLinkOpen, setIsDrawerLinkOpen] = useState<boolean>(true);
	let Overlay = <></>;
	const IFrameRef: React.RefObject<HTMLIFrameElement> = useRef(null);
	const [HasSendEvent, setHasSentEvent] = useState<boolean>(false);
	const UseDisplayOverride = useRecoilValue(rUseDisplayOverride);
	const OnDrawerStateChange = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) =>
	{

	}
	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) =>
	{
		if (event && event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift"))
		{
			console.log(event);
			return;
		}
		setIsDrawerLinkOpen(open);
		if (open === false)
		{
			resetOverlayInput();
			setHasSentEvent(false);
		}
	};
	function SendSwitchToDisplayFunctionCall()
	{
		if (IFrameRef.current !== null)// && HasSendEvent === false)
		{
			// setHasSentEvent(true);
			try
			{
				(IFrameRef.current.contentWindow as { [key: string]: any })['SwitchToExterior'](false);
				if (UseDisplayOverride)
					(IFrameRef.current.contentWindow as { [key: string]: any })['SwitchToDisplay'](false);
			}
			catch (e)
			{
				console.log("Caught Iframe Function failure: " + e);
			}
		}
	}


	function SetIFrameOverlay(URL: string)
	{
		Overlay =
			<SwipeableDrawer id="FullscreenOverlayContainer" anchor={"bottom"} open={true} onClose={OnDrawerStateChange(false)} onOpen={OnDrawerStateChange(true)}>
				<Box component="div" sx={{ width: "auto", height: "100vh" }} style={{ overflow: "hidden", justifyContent: "center", display: "flex", backgroundColor: "#000" }} >
					<iframe onLoad={SendSwitchToDisplayFunctionCall} ref={IFrameRef} id={"OverlayIFrame"} title={"ExploreBox"} height={"100%"} width={"100%"} src={URL}></iframe>
				</Box>
				{RecoilOverlayButtonEnabled && <IconButton onClick={toggleDrawer(false)} style={{ position: "absolute", right: "3.8%", top: "3.2%", backgroundColor: "#FFF", padding: "0.9%", boxSizing: "content-box", borderRadius: 0 }}>
					<img alt="" src={cross} width={24}></img>
				</IconButton>}
			</SwipeableDrawer>

	}

	function SetImageOverlay(URL: string)
	{
		Overlay =
			<SwipeableDrawer id="FullscreenOverlayContainer" anchor={"bottom"} open={true} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
				<Box component="div" sx={{ width: "auto", height: "100vh" }} style={{ overflow: "hidden", justifyContent: "center", display: "flex", backgroundColor: "#000" }} >
					<img alt={"ExploreBox"} height={"100%"} width={"auto"} src={URL}></img>
				</Box>
				{RecoilOverlayButtonEnabled && <IconButton onClick={toggleDrawer(false)} style={{ position: "absolute", right: "3.8%", top: "3.2%", backgroundColor: "#FFF", padding: "0.9%", boxSizing: "content-box", borderRadius: 0 }}>
					<img alt="" src={cross} width={24}></img>
				</IconButton>}
			</SwipeableDrawer>
	}
	function SetVideoOverlay(URL: string)
	{
		Overlay =
			<SwipeableDrawer id="FullscreenOverlayContainer" anchor={"bottom"} open={true} onClose={OnDrawerStateChange(false)} onOpen={OnDrawerStateChange(true)}>
				<Box component="div" sx={{ width: "auto", height: "100vh" }} style={{ overflow: "hidden", justifyContent: "center", display: "flex", backgroundColor: "#000" }} >
					<video autoPlay={true} title={"ExploreBox"} height={"100%"} width={"100%"} src={URL}></video>
				</Box>
				{RecoilOverlayButtonEnabled && <IconButton onClick={toggleDrawer(false)} style={{ position: "absolute", right: "3.8%", top: "3.2%", backgroundColor: "#FFF", padding: "0.9%", boxSizing: "content-box", borderRadius: 0 }}>
					<img alt="" src={cross} width={24}></img>
				</IconButton>}
			</SwipeableDrawer>
	}


	useEffect(() =>
	{

	}, [RecoilOverlayType])

	switch (RecoilOverlayType[0])
	{
		case "iframe":
			SetIFrameOverlay(RecoilOverlayType[1]);
			break;
		case "image":
			SetImageOverlay(RecoilOverlayType[1]);
			break;
		case "video":
			SetVideoOverlay(RecoilOverlayType[1]);
			break;
		case "":
			Overlay = <></>;
			break;
	}


	return Overlay;
};
export default FullscreenOverlay;
