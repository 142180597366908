

import React, { useState, createContext, useEffect, useRef } from "react";
import { Engine } from "@babylonjs/core";
import { AbstractMesh, Scene, TargetCamera, Vector3 } from "@babylonjs/core";

import * as BABYLON from "@babylonjs/core";
import "@babylonjs/loaders";
import MeshManager from "./MeshManager";
import { CustomLoadingScreen } from "../Panel/CustomLoadingScreen";
import SequenceManager from "./SequenceManager";
import { useRecoilValue, useRecoilState } from "recoil";
import { rPOIArray } from "recoil/atoms";
import ExteriorManager from "./ExteriorBabylon"

type BabylonContextType = {
	engine: BABYLON.Engine | undefined;
	scene: BABYLON.Scene | undefined;
	CurrentlyPickedMesh: BABYLON.AbstractMesh | undefined;
	SetCurrentlyPickedMesh: React.Dispatch<React.SetStateAction<BABYLON.AbstractMesh | undefined>>;

	IsPointerDown: boolean;
	SetIsPointerDown: React.Dispatch<React.SetStateAction<boolean>>;
	CanvasRect: DOMRect | undefined;
	SetCanvasRect: React.Dispatch<React.SetStateAction<DOMRect | undefined>>;
};
export const BabylonContext = createContext<BabylonContextType>(undefined!);
export const BabylonContextProvider = ({ children }: React.PropsWithChildren<{}>) =>
{
	const [engine, SetEngine] = useState<Engine | undefined>();
	const [scene, SetScene] = useState<Scene | undefined>();
	const [CurrentlyPickedMesh, SetCurrentlyPickedMesh] = useState<AbstractMesh | undefined>();
	const [IsPointerDown, SetIsPointerDown] = useState<boolean>(false);
	const [CanvasRect, SetCanvasRect] = useState<DOMRect | undefined>();
	const RecoilPOI = useRecoilValue(rPOIArray);
	//----------------------------Setup Scene----------------------------//
	const reactCanvas: React.RefObject<HTMLCanvasElement> = useRef(null);
	useEffect(() =>
	{
		const NewEngine = new Engine(reactCanvas.current, true, {}, false);
		const NewScene = new Scene(NewEngine, {});

		const loadingscreen = new CustomLoadingScreen();
		NewEngine.loadingScreen = loadingscreen;

		if (!reactCanvas.current) return;

		const resize = () =>
		{
			NewScene.getEngine().resize();
		};

		if (window)
		{
			window.addEventListener("resize", resize);
		}

		if (NewScene.isReady())
		{
			SetCanvasSize();
			onSceneReady(NewScene);
		} else
		{
			NewScene.onReadyObservable.addOnce((NewScene) => onSceneReady(NewScene));
		}

		NewEngine.runRenderLoop(() =>
		{
			NewScene.render();
		});

		SetEngine(NewEngine);
		SetScene(NewScene);
		const resizeListener = () =>
		{
			// change width from the state object
			SetCanvasSize();
		};
		window.addEventListener("resize", resizeListener);

		return () =>
		{
			NewScene.getEngine().dispose();

			if (window)
			{
				window.removeEventListener("resize", resize);
			}
		};
	}, []);

	function onSceneReady(scene: Scene)
	{
		// This creates and positions a free camera (non-mesh)
		var camera = new TargetCamera("camera1", new Vector3(0, 5, -10), scene);
	}
	function SetCanvasSize()
	{
		const node = reactCanvas.current;
		if (node)
		{
			let HTMLDomNode = node as HTMLCanvasElement;
			if (HTMLDomNode)
			{
				if (reactCanvas && reactCanvas.current)
				{
					let ctx = reactCanvas.current.getContext("2d");
					if (ctx)
					{
						ctx.canvas.height = window.innerHeight;
						ctx.canvas.width = 4 * window.innerHeight / 3;
					}
				}
				let height = window.innerHeight;
				let width = window.innerHeight / 9 * 16;
				if (width < window.innerWidth)
				{
					width = window.innerWidth;
					height = window.innerWidth / 16 * 9;
				}
				HTMLDomNode.width = width;
				HTMLDomNode.height = height;

				SetCanvasRect(HTMLDomNode.getBoundingClientRect());
			}
		}
	}
	return (
		<div className="overflowHidden">
			<BabylonContext.Provider
				value={{
					engine,
					scene,
					CurrentlyPickedMesh,
					SetCurrentlyPickedMesh,
					IsPointerDown,
					SetIsPointerDown,
					CanvasRect,
					SetCanvasRect,
				}}>
				<div id="ExteriorBabylonWrapper">
					<img className="CenteredLoadingLogo" alt="" src="./V&ALogo.png"></img>
					<ExteriorManager></ExteriorManager>
					<MeshManager />
					{/* <>{RecoilPOI}</> */}
					<canvas ref={reactCanvas} id="BabylonCanvas" />
					<SequenceManager />
					{children}
				</div>
			</BabylonContext.Provider>
		</div>
	);
};
