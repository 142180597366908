import { ExteriorContextProvider } from "Exterior/ExteriorContext";
import { ExteriorContainer } from "Exterior/ExteriorContainer";


export default function ExteriorPage()
{

	return (
		<ExteriorContextProvider>
			<ExteriorContainer />
		</ExteriorContextProvider>
	);
}


