import * as React from 'react';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import MuiAppBar, { AppBarProps } from '@mui/material/AppBar';
import Button from '../components/UI/Button';
import { IconButton, Toolbar } from '@mui/material';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { firebaseSignedIn, rUseDisplayOverride } from 'recoil/atoms';
import LogoutIcon from '@mui/icons-material/Logout';
import SettingsIcon from '@mui/icons-material/Settings';
import { useEffect } from 'react';
import Auth0LogOut from 'Pages/Auth/Auth0/Auth0LogOut';

const navLink = {
	// fontSize: 30,
	color: 'common.black',
	// ml: 3,
	flex: 1,
	alignSelf: "center"
};

function AppBar()
{
	let AppBarRef = React.useRef<HTMLHeadingElement>(null);
	const [SpacerHeight, setSpacerHeight] = React.useState<number>(0);
	const UseDisplayOverride = useRecoilValue(rUseDisplayOverride);


	function ExplorePressed()
	{
		if (UseDisplayOverride)
		{
			(window as { [key: string]: any; })['blu_event']("OpenExploreExterior", "");
			return;
		}
		else
		{
			window.location.href = "/explore";
		}
	}

	useEffect(() =>
	{
		if (AppBarRef && AppBarRef.current && (AppBarRef.current.clientHeight != SpacerHeight))
		{
			setSpacerHeight(AppBarRef.current.clientHeight);
		}
	}, [AppBarRef]);

	return (
		<div >
			<div id="AppBarSpacer" style={{ height: SpacerHeight }} />
			<MuiAppBar ref={AppBarRef} elevation={0} position="fixed" style={{ paddingLeft: "3%", paddingRight: "3%", boxSizing: "border-box" }}>
				<Toolbar sx={{ justifyContent: 'space-between', flex: 1, paddingLeft: "0px !important", paddingRight: "0px !important", marginTop: 3.5, marginBottom: 3.5 }}>
					<Link
						variant="h6"
						underline="none"
						color="inherit"
						href="/"
						sx={{
							fontSize: 24,
							flex: 1,
							alignSelf: "center",
							display: "flex"
						}}
					>
						<img
							src="https://realspace-core-iris.pages.dev/images/62c42a321661d725998c9ab6_V-A-logo.svg"
							alt="wonder"
							width="275"
							height="62"
						/>
					</Link>
					<Box sx={{ flex: 1 }} />
					<Box sx={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
						<Box sx={{ display: 'flex', justifyContent: "space-between", gap: 5 }}>
							<Link
								color="inherit"
								variant="h4"
								underline="none"
								href="/Neighbourhood"
								sx={navLink}
							>
								{'Neighbourhood'}
							</Link>
							<Link
								color="inherit"
								variant="h4"
								underline="none"
								href="/Collaborators"
								sx={navLink}
							>
								{'Collaborators'}
							</Link>
							<Link
								color="inherit"
								variant="h4"
								underline="none"
								href="/Location"
								sx={navLink}
							>
								{'Location'}
							</Link>
							<Link
								color="inherit"
								variant="h4"
								underline="none"
								href="/Gallery"
								sx={navLink}
							>
								{'Gallery'}
							</Link>
							{/* <Link
								variant="h6"
								underline="none"
								href="/premium-themes/onepirate/sign-up/"
								sx={{ ...rightLink, color: 'secondary.main' }}
							>
								{'Sign Up'}
							</Link> */}
							<Button
								color="secondary"
								variant="contained"
								size="large"
								component="a"
								// href="/explore"
								sx={{ minWidth: 100 }}
								onClick={ExplorePressed}
							>
								EXPLORE
							</Button>
							<Link
								color="inherit"
								variant="h4"
								underline="none"
								href="/ChangeEmail"
								sx={navLink}
							>
								{<SettingsIcon />}
							</Link>
							<Auth0LogOut />
						</Box>
					</Box>
				</Toolbar>
			</MuiAppBar>

		</div>
	);
}

export default AppBar;