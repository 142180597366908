import { Box, Button } from "@mui/material";
import React, { useRef } from "react";
import GalleryTileInfo from "../Core/Types/GalleryTypes"

export const ImagesStrip = (prop: {
	CurrentlyDisplayedTiles: { name: string; type: string; url: string; tags: string[]; }[],
	setCurrentDisplayImage: React.Dispatch<React.SetStateAction<[number, typeof GalleryTileInfo, typeof GalleryTileInfo[]]>>,
	CurrentImageAndImageSet: [number, typeof GalleryTileInfo, typeof GalleryTileInfo[]],
	ImagesStripDiv: React.RefObject<HTMLDivElement>,
	InitialDragPos: React.MutableRefObject<number>,
	InitialScrollPos: React.MutableRefObject<number>,
	isDragging: React.MutableRefObject<boolean>
}) =>
{
	const ImageThumbnailStyle = {
		padding: 0,
		height: "100%",
		width: "6%",
		flexShrink: 0
	};
	const SelectedImageThumbnailStyle = {
		padding: 0,
		height: "100%",
		width: "6%",
		flexShrink: 0,
		border: "3px solid white"
	};

	const DragAcceptance = 8;

	return (
		<Box ref={prop.ImagesStripDiv} display="flex" justifyContent="stretch" flexDirection="row" width="100%" height="15%" sx={{
			overflowX: "scroll", cursor: "pointer", position: "relative",
			"::-webkit-scrollbar": {
				width: "0px",
				height: "0px"
			}
		}}
		>
			<Box display="flex" width="100%" flexDirection="row" gap="10px" height="100%" sx={{

			}} padding={2}

				draggable={false}

				onMouseDown={(event: React.MouseEvent<HTMLElement>) =>
				{
					prop.isDragging.current = false;
					SwipeStart(event.clientX);
				}}
				onMouseMove={(event: React.MouseEvent<HTMLElement>) =>
				{
					if (event.buttons)
					{
						if (Math.abs(prop.InitialDragPos.current - event.clientX) > DragAcceptance)
						{
							prop.isDragging.current = true;
						}
						SwipeMove(event.clientX);
						event.preventDefault();
					}
				}}
				onMouseUp={(event: React.MouseEvent<HTMLElement>) =>
				{
					SwipeEnd(event.clientX);
					event.preventDefault();
				}}

			>
				{prop.CurrentlyDisplayedTiles.map((item, index) => (
					<Button
						key={"Image-Strip-Item-" + index}
						sx={index == prop.CurrentImageAndImageSet[0] ? SelectedImageThumbnailStyle : ImageThumbnailStyle}
						onClick={() =>
						{
							if (!prop.isDragging.current && prop.CurrentlyDisplayedTiles.length > index)
								prop.setCurrentDisplayImage([index, prop.CurrentlyDisplayedTiles[index], prop.CurrentlyDisplayedTiles]);
						}}

					>
						{item.type == "image" ?
							<img
								src={item.url}
								srcSet={item.url}
								alt={item.name}
								loading="lazy"
								key={item.name}
								draggable={false}
								style={{
									objectFit: "cover",
									height: "100%",
									width: "100%",
									flexShrink: 0,
								}}
							/>
							:
							item.type == "video" ?
								<Box
									sx={{
										objectFit: "cover",
										height: "100%",
										width: "100%",
										flexShrink: 0,
										position: "relative",
									}}>
									<video muted loop preload="auto"
										key={item.name}
										draggable={false}
										style={{
											position: "relative",
											objectFit: "cover",
											height: "100%",
											width: "100%",
											flexShrink: 0,
										}}>
										<source src={`${item.url}&w=1920&h=1080&fit=fit&auto=format#t=10`} type="video/mp4" />
										Your browser does not support the video tag.
									</video>
									<Box
										sx={{
											position: "absolute",
											inset: "0 0 0 0",
											color: "white",
											padding: "20%"
										}}>
										<img
											src="https://icon-library.com/images/play-icon-png/play-icon-png-6.jpg"
											alt="wonder"
											width="100%"
											height="100%"
											style={{
												objectFit: "fill",
											}}
										/>
									</Box>
								</Box>
								:
								item.type == "iframe" ?
									<></>
									:
									<></>}

					</Button>
				))}
			</Box>
		</ Box>);



	function SwipeStart(ClientX: number)
	{
		if (prop.ImagesStripDiv !== null && prop.ImagesStripDiv.current !== null)
		{
			prop.InitialDragPos.current = ClientX;
			prop.InitialScrollPos.current = prop.ImagesStripDiv.current.scrollLeft;
			// ImagesStripDiv.current.scrollLeft = event.clientX;
		}
	}
	function SwipeMove(ClientX: number)
	{
		if (prop.ImagesStripDiv !== null && prop.ImagesStripDiv.current !== null)
		{
			prop.ImagesStripDiv.current.scrollLeft = prop.InitialScrollPos.current + prop.InitialDragPos.current - ClientX;
		}
	}
	function SwipeEnd(ClientX: number)
	{
		if (prop.ImagesStripDiv !== null && prop.ImagesStripDiv.current !== null)
		{
			// const Dir = Math.sign(InitialDragPos.current - ClientX);
			// ImagesStripDiv.current.scrollLeft = InitialScrollPos.current + InitialDragPos.current - ClientX + Dir * 100;
			// ImagesStripDiv.current.style.transitionProperty = "all";
			// ImagesStripDiv.current.style.transitionDuration = "3.3s";

		}
	}
}
