import { Box, Button } from "@mui/material";
import React, { useRef } from "react";
import { useEffect } from "react";
import { ImagesStrip } from "./ImagesStrip";
// import { ExampleComp } from "./Test";
import GalleryTileInfo from "../Core/Types/GalleryTypes"

export const ImageViewer = (prop: {
	CurrentImageAndImageSet: [number, typeof GalleryTileInfo, typeof GalleryTileInfo[]],
	setCurrentImageInSet: React.Dispatch<React.SetStateAction<[number, typeof GalleryTileInfo, typeof GalleryTileInfo[]]>>
}) =>
{
	// IMAGES STRIP REFS
	const ImagesStripDiv: React.RefObject<HTMLDivElement> = useRef(null);
	const ImageViewerDiv: React.RefObject<HTMLDivElement> = useRef(null);
	const InitialDragPos = useRef(0);
	const InitialScrollPos = useRef(0);
	const isDragging = useRef(false);


	let CurrentlyDisplayedTiles = prop.CurrentImageAndImageSet[2];
	// const [data, loading] = ExampleComp("string");

	let ContentHeight = "85%";
	if (CurrentlyDisplayedTiles.length < 2)
	{
		ContentHeight = "100%";
	}

	useEffect(() =>
	{
		if (isDragging)
			SwipeEnd(0);
	}, [isDragging]);

	function SetCurrentImage(index: number)
	{
		if (CurrentlyDisplayedTiles.length > index)
		{
			prop.setCurrentImageInSet([index, CurrentlyDisplayedTiles[index], CurrentlyDisplayedTiles]);
		}
		// TODO: On switching with buttons, scroll into view on image strip
		// ImagesStripDiv.current.scrollIntoView()
	}


	return (
		<React.Fragment>
			{
				prop.CurrentImageAndImageSet[0] >= 0 &&
				<Box sx={{ zIndex: 2000, position: "fixed", top: 0, right: 0, left: 0, bottom: 0, backgroundColor: "rgb(0,0,0, .9)" }}>
					{/* Vertical Box */}
					<Box display="flex" flexDirection="column" width="100%" height="100%">
						{/* Viewer */}
						<Box display="flex" flexDirection="row" justifyContent="space-between" sx={{ boxSizing: "border-box", height: ContentHeight }}>
							{CurrentlyDisplayedTiles.length > 1 ?
								<Button sx={{ fontSize: 30, color: "rgb(255,255,255,.6)", width: "2%" }} onClick={() =>
								{
									let NewValue = prop.CurrentImageAndImageSet[0] - 1;
									if (NewValue < 0)
										NewValue = CurrentlyDisplayedTiles.length - 1;

									TransitionViewer(-1000);

									SetCurrentImage(NewValue);

								}}>
									{"<"}
								</Button>
								:
								<></>
							}
							<Box ref={ImageViewerDiv} flexGrow={1} display="flex" alignItems="center" justifyContent="center"
								draggable={false}

								onTouchStart={((event: React.TouchEvent<HTMLElement>) =>
								{
									SwipeStart(event.touches[0].clientX);
								})}
								onTouchMove={((event: React.TouchEvent<HTMLElement>) =>
								{
									SwipeMove(event.touches[0].clientX);
								})}
								onTouchEnd={((event: React.TouchEvent<HTMLElement>) =>
								{
									SwipeEnd(event.changedTouches[0].clientX);
								})}

								onMouseDown={(event: React.MouseEvent<HTMLElement>) =>
								{
									SwipeStart(event.clientX);
								}}
								onMouseMove={(event: React.MouseEvent<HTMLElement>) =>
								{
									if (event.buttons)
										SwipeMove(event.clientX);
								}}
								onMouseUp={(event: React.MouseEvent<HTMLElement>) =>
								{
									SwipeEnd(event.clientX);
								}}
								onMouseLeave={(event: React.MouseEvent<HTMLElement>) =>
								{
									ReturnImageToCenter();
								}}
							>
								{prop.CurrentImageAndImageSet[1].type == "image" ?
									<img
										src={prop.CurrentImageAndImageSet[1].url}
										srcSet={prop.CurrentImageAndImageSet[1].url}
										alt={prop.CurrentImageAndImageSet[1].name}
										loading="lazy"
										draggable={false}
										style={{
											objectFit: "contain",
											height: "100%",
											width: "100%",
										}} />
									:
									prop.CurrentImageAndImageSet[1].type == "video" ?
										<video autoPlay muted loop controls
											src={CurrentlyDisplayedTiles[prop.CurrentImageAndImageSet[0]].url}
											draggable={false}
											style={{
												objectFit: "contain",
												height: "100%",
												width: "100%",
											}} />
										:
										prop.CurrentImageAndImageSet[1].type == "iframe" ?
											<></>
											:
											<></>}

							</Box>
							{CurrentlyDisplayedTiles.length > 1 ?
								<Button sx={{ fontSize: 30, color: "rgb(255,255,255,.6)", width: "2%" }} onClick={() =>
								{
									let NewValue = prop.CurrentImageAndImageSet[0] + 1;
									if (NewValue >= CurrentlyDisplayedTiles.length)
										NewValue = 0;
									TransitionViewer(1000);
									SetCurrentImage(NewValue);
								}}>
									{">"}
								</Button>
								:
								<></>
							}
						</Box>
						{/* Images Strip */}
						{CurrentlyDisplayedTiles.length > 1 ?
							<ImagesStrip CurrentlyDisplayedTiles={CurrentlyDisplayedTiles} setCurrentDisplayImage={prop.setCurrentImageInSet} CurrentImageAndImageSet={prop.CurrentImageAndImageSet}
								ImagesStripDiv={ImagesStripDiv} InitialDragPos={InitialDragPos} InitialScrollPos={InitialScrollPos} isDragging={isDragging}></ImagesStrip>
							:
							<></>}
						{/* {ImagesStrip(CurrentlyDisplayedTiles, prop.setCurrentImageInSet, prop.CurrentImageAndImageSet, ImagesStripDiv, InitialDragPos, InitialScrollPos, isDragging)} */}
						<Button sx={{ position: "absolute", top: ".2%", right: ".2%", fontSize: 30, color: "rgb(255,255,255,.6)" }} onClick={() =>
						{
							prop.setCurrentImageInSet([-1, GalleryTileInfo, []]);
						}}>
							{"x"}
						</Button>
					</Box>
				</Box>
			}
		</React.Fragment >);


	function SwipeStart(ClientX: number)
	{
		if (ImageViewerDiv !== null && ImageViewerDiv.current !== null && prop.CurrentImageAndImageSet[2].length > 1)
		{
			InitialDragPos.current = ClientX;
			InitialScrollPos.current = Number(ImageViewerDiv.current.style.marginLeft);
			ImageViewerDiv.current.style.transition = "none";
		}
	}
	function SwipeMove(ClientX: number)
	{
		if (ImageViewerDiv !== null && ImageViewerDiv.current !== null && prop.CurrentImageAndImageSet[2].length > 1)
		{
			const Change = (ClientX - InitialDragPos.current) * 2;
			const VerticalPadding = Math.abs(Change) / 14;
			const Opacity = Math.max(1 - Math.abs(Change) / 1500, 0.6);
			ImageViewerDiv.current.style.marginLeft = (Change).toString() + 'px';
			ImageViewerDiv.current.style.marginTop = (VerticalPadding).toString() + 'px';
			ImageViewerDiv.current.style.marginBottom = (VerticalPadding).toString() + 'px';
			ImageViewerDiv.current.style.opacity = (Opacity).toString();
		}
	}
	function SwipeEnd(ClientX: number)
	{
		if (ImageViewerDiv !== null && ImageViewerDiv.current !== null && prop.CurrentImageAndImageSet[2].length > 1)
		{
			const CurrentOffset = ClientX - InitialDragPos.current;
			if (Math.abs(CurrentOffset) > 300)
			{
				let NewValue = prop.CurrentImageAndImageSet[0] + 1;
				if (CurrentOffset > 0)
				{
					NewValue = prop.CurrentImageAndImageSet[0] - 1;
					if (NewValue < 0)
						NewValue = CurrentlyDisplayedTiles.length - 1;

					TransitionViewer(-1000);
				}

				else
				{
					if (NewValue >= CurrentlyDisplayedTiles.length)
						NewValue = 0;

					TransitionViewer(1000);
				}
				if (CurrentlyDisplayedTiles.length > NewValue)
				{
					prop.setCurrentImageInSet([NewValue, CurrentlyDisplayedTiles[NewValue], CurrentlyDisplayedTiles]);
				}

			}
			else
			{
				ReturnImageToCenter();
			}
			InitialScrollPos.current = Number(ImageViewerDiv.current.style.marginLeft);
		}
	}

	function ReturnImageToCenter()
	{
		ImageViewerDiv.current.style.margin = '0px';
		ImageViewerDiv.current.style.transitionProperty = "all";
		ImageViewerDiv.current.style.transitionDuration = "0.3s";
		ImageViewerDiv.current.style.opacity = "1";
	}

	function TransitionViewer(StartingPos: number)
	{
		if (ImageViewerDiv.current)
		{
			ImageViewerDiv.current.style.marginLeft = StartingPos.toString() + 'px';
			ImageViewerDiv.current.style.opacity = "0";
			ImageViewerDiv.current.style.transitionDuration = "0s";

			setTimeout((DivElement: HTMLDivElement) =>
			{
				ReturnImageToCenter();
			}, 1, ImageViewerDiv.current);
		}
	}
}
