import React, { useState, createContext, useEffect } from "react";

const ExternalSettingsJsonFileName: string = "ExternalSettings.json";

type GlobalContextType = {
	ExternalSettings: object;
	LoadedExternalSettings: boolean;
	HasFinishedAsyncLoad: boolean;
	SetHasFinishedAsyncLoad: React.Dispatch<React.SetStateAction<boolean>>;
};
export const GlobalContext = createContext<GlobalContextType>(undefined!);
export const GlobalContextProvider = ({ children }: React.PropsWithChildren<{}>) =>
{
	const [ExternalSettings, setExternalSettings] = useState({});

	const [LoadedExternalSettings, setLoadedExternalSettings] = useState(false);
	const [HasFinishedAsyncLoad, SetHasFinishedAsyncLoad] = useState<boolean>(false);

	useEffect(() =>
	{
		function LoadJsonData()
		{
			console.log("Trying to load Json data for " + ExternalSettingsJsonFileName + "...");
			try
			{
				// Need check for file existing
				fetch(ExternalSettingsJsonFileName, { cache: "no-cache" })
					.then(function (response)
					{
						return response.text();
					})
					.then(function (txt)
					{
						try
						{
							let jsonSettings = JSON.parse(txt);
							setExternalSettings(jsonSettings);
							setLoadedExternalSettings(true);
							console.log("Loaded " + ExternalSettingsJsonFileName);
						} catch (err)
						{
							console.log("Failed to Load " + ExternalSettingsJsonFileName + " : " + err);
							return;
						}
					});
			}
			catch (e)
			{
			}

		}
		LoadJsonData();

		return () =>
		{
			// on remove/exit
		};
	}, []);

	return (
		<GlobalContext.Provider
			value={{
				ExternalSettings,
				LoadedExternalSettings,
				HasFinishedAsyncLoad,
				SetHasFinishedAsyncLoad,
			}}>
			{children}
		</GlobalContext.Provider>
	);
};

// Can't have useContext in here
// export function GetExternalSetting(key: String)
// {
// 	const { ExternalSettings } = useContext(GlobalContext);
// 	return ExternalSettings[key as keyof typeof ExternalSettings];
// }
export function GetExternalSetting(settings: object, key: string)
{
	return settings[key as keyof typeof settings];
}
