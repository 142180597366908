import { ILoadingScreen } from "@babylonjs/core";

export class CustomLoadingScreen implements ILoadingScreen
{
	constructor() { }
	displayLoadingUI(): void
	{
		let ReactUI = document.getElementById("ReactUI");
		if (ReactUI)
		{
			ReactUI.style.display = "none";
		}
		let BabylonExterior = document.getElementById("ExteriorBabylonWrapper");
		if (BabylonExterior)
		{
			for (let i = 0; i < BabylonExterior.children.length; i++)
			{
				let HTMLItem = BabylonExterior.children[i] as HTMLElement;
				if (HTMLItem)
				{
					if (HTMLItem.className != "CenteredLoadingLogo")
					{
						HTMLItem.style.display = "none";
					} else
					{
						HTMLItem.style.display = "block";
					}
				}
			}
		}
	}
	hideLoadingUI(): void
	{
		let ReactUI = document.getElementById("ReactUI");
		if (ReactUI)
		{
			ReactUI.style.display = "block";
		}
		let BabylonExterior = document.getElementById("ExteriorBabylonWrapper");
		if (BabylonExterior)
		{
			for (let i = 0; i < BabylonExterior.children.length; i++)
			{
				let HTMLItem = BabylonExterior.children[i] as HTMLElement;
				if (HTMLItem)
				{
					if (HTMLItem.className != "CenteredLoadingLogo")
					{
						HTMLItem.style.display = "block";
					} else
					{
						HTMLItem.style.display = "none";
					}
				}
			}
		}
	}

	loadingUIBackgroundColor: string = "RED";
	loadingUIText: string = "CUSTOM LOADING";
}
