import * as React from 'react';
import { Theme, styled } from '@mui/material/styles';
import { SxProps } from '@mui/system';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

const HomePageLayoutRoot = styled('section')(({ theme }) => ({
	color: theme.palette.common.white,
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	// left: 0,
	// right: 0,
	// top: 0,
	// bottom: 0,
	[theme.breakpoints.up('sm')]: {
		// height: 'calc(100vh - 124px / 2)',
		height: '100%',
		// minHeight: 500,
		// maxHeight: 1300,
		top: 0
	},
}));

const Background = styled(Box)({
	position: 'absolute',
	left: 0,
	right: 0,
	top: 0,
	bottom: 0,
	backgroundSize: 'cover',
	backgroundRepeat: 'no-repeat',
	zIndex: -2,
});

interface HomePageLayoutProps
{
	sxBackground: SxProps<Theme>;
}

export default function HomePageLayout(
	props: React.HTMLAttributes<HTMLDivElement> & HomePageLayoutProps,
)
{
	const { sxBackground, children } = props;

	return (
		<HomePageLayoutRoot>
			<Container
				sx={{
					mt: 3,
					mb: 14,
					display: 'flex',
					flexDirection: 'column',
					alignItems: 'center',
				}}
			>
				<Box sx={{
					position: 'absolute',
					left: "9vh",
					bottom: "9vh",
				}}
				>
					<img
						src="https://realspace-core-iris.pages.dev/images/62c4feb13c2c9f2d9e4d4780_V-A-Monogram.svg"
						alt="Project-Logo"
						width="248"
						height="110"
					/>
				</Box>

				{children}
				<Box
					sx={{
						position: 'absolute',
						left: 0,
						right: 0,
						top: 0,
						bottom: 0,
						backgroundColor: 'common.black',
						opacity: 0.1,
						zIndex: -1,
					}}
				/>
				<Background sx={sxBackground} />
			</Container>
		</HomePageLayoutRoot>
	);
}