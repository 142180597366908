import React from 'react';
import Button from '@mui/material/Button';
import firebase from 'firebase/compat/app';
import { useSetRecoilState } from 'recoil';
import { firebaseSignedIn } from "recoil/atoms";

export function LogOutButton()
{
    const setIsSignedIn = useSetRecoilState(firebaseSignedIn); // Local signed-in state.
    return <Button
        size={'large'}
        variant={'outlined'}
        sx={{ marginTop: { xs: 2, md: 0 } }}
        onClick={() =>
        {
            firebase.auth().signOut().then(
                () => { setIsSignedIn(false); }
            );
        }}
    >
        Log out
    </Button>;
}
