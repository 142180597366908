import { AbstractMesh } from "@babylonjs/core";
import React from "react";
import { ApartmentInfo, tUnitInfoType, tUnitInfoTypes } from "../../components/Types";

export function CreateApartmentLabel(mesh: AbstractMesh, ItemInfoArray: typeof tUnitInfoTypes)
{
	//const setrApartmentLabel = useSetRecoilState(rApartmentInfo);
	const meshdata = findMeshData(mesh, ItemInfoArray);
	if (meshdata.UnitInfo.name === "")
	{
		return undefined;
	}
	const parsedMeshData: string[][] = parseMeshData(meshdata);
	const parsedApartmentInfo = parseApartmentInfo(parsedMeshData);
	console.table(parsedApartmentInfo);
	// TODO: USE DYNAMIC UNIT PAGE
	const info: ApartmentInfo = {
		type: meshdata.UnitInfo.type,
		unit: meshdata.UnitInfo.name,
		data: parsedApartmentInfo,
	};
	return info;
	//const ApartmentLabel = <rApartmentLabel />;
}
function findMeshData(mesh: AbstractMesh, ItemInfoArray: typeof tUnitInfoTypes)
{
	let data: typeof tUnitInfoType;
	for (let i = 0; i < ItemInfoArray.length; i++)
	{
		data = ItemInfoArray[i];
		if (data)
		{
			if (mesh)
			{
				if (mesh.name == data.UnitInfo.mesh)
				{
					return data;
				}
			}
		}
	}
	return tUnitInfoType;
	//  {
	// 	Name: "",
	// 	Link: "",
	// 	Price: -1,
	// 	Availability: "",
	// 	Level: -1,
	// 	Building: "",
	// 	Type: "",
	// 	Mesh: ""
	// };
}

function parseApartmentInfo(info: string[][])
{
	var data: string[][] = [];
	for (let i = 0; i < info.length; i++)
	{
		const element = info[i];
		if (element[1] != "" &&
			element[0] != "link" &&
			element[0] != "mesh" &&
			element[0] != "name" &&
			element[0] != "price" &&
			element[0] != "building" &&
			element[0] != "type" &&
			element[0] != "level" &&
			element[0] != "availability" &&
			element[0] != "walkthrough" &&
			element[0] != "unrealLevel" &&
			element[0] != "description" &&
			element[0] != "_createdBy" &&
			element[0] != "_updatedBy")
		{
			data.push([element[0], element[1]]);
		}
	}

	return data;
}

const parseMeshData = (info: typeof tUnitInfoType) =>
{
	let data: [string, string][] = [];
	for (const n in info.UnitInfo)
	{
		let val = (info.UnitInfo)[n];
		if (typeof val !== "number" && typeof val !== "string")
			continue;
		data.push([n, val as any]);
	}
	if (info.TypeInfo !== undefined)
	{
		for (const n in info.TypeInfo)
		{
			let val = (info.TypeInfo)[n];
			if (typeof val !== "number" && typeof val !== "string")
				continue;
			data.push([n, val as any]);
		}
	}
	return data;
};
