
import { useEffect, useContext } from "react";

import "@babylonjs/loaders";
import React from "react";
import { ExteriorContext } from "Exterior/ExteriorContext";
import { BabylonContext } from "Exterior/Babylon/ExteriorBabylonScene";
const MeshManager = () =>
{
	const { scene } = useContext(BabylonContext);
	const { engine } = useContext(BabylonContext);

	const { ItemPoint } = React.useContext(ExteriorContext);
	const { setItemPoint } = React.useContext(ExteriorContext);

	useEffect(() =>
	{
		if (engine)
		{
			engine.runRenderLoop(OnRender);
		}
	}, [scene]);

	let OnRender = () =>
	{
		// console.count("MESH RENDER");
	};

	return <></>;
};
export default MeshManager;
