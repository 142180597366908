
export const ActiveFiltersType = {
	Bedrooms: 1,
	Carparks: 1,
	AreaMin: 0,
	AreaMax: 100,
	PriceMin: 500,
	PriceMax: 1000,
	FloorsMin: 0,
	FloorsMax: 50,
};

export const ParsedItemData: Map<string, string | boolean | number> = new Map<string, string | boolean | number>();

export const FilteredSetType = new Map<string, [string | number | boolean, string | number | boolean]>();

export const ParseStructureOverride = {
	IsNumberOverride: "number",
	IsStringOverride: "string",
};


export const tUnitType = {
	unitName: "",
	unrealLevel: "",
	walkthrough: "",
}
export const tUnitInfo = {
	name: "",
	mesh: "",
	type: "",
	level: -1,
}
export const tLevel = {
	level: -1,
	description: "",
	building: "",
	name: ""
}
export const tPointOfInterest: {
	name: string,
	positionX: number,
	positionZ: number,
	positionY: number,
	link?: string,
	image?: string,
}
	= {
	name: "",
	positionX: 0,
	positionZ: 0,
	positionY: 0,
}


export const tUnitInfoType: { UnitInfo: typeof tUnitInfo, TypeInfo?: typeof tUnitType } = {
	UnitInfo: {
		name: "",
		mesh: "",
		type: "",
		level: -1,
	}
};

export const tUnitInfoTypes = [tUnitInfoType];


export const tCMSData = {
	Units: [tUnitInfo],
	Types: [tUnitType],
	Levels: [tLevel],
	PointOfInterests: [tPointOfInterest],
}

export type ApartmentInfo = {
	type: string;
	unit: string;
	data: string[][];
};


export const UnitTypeKeys = {
	size: "size",
	balcony: "balcony",
	carparks: "carparks",
	bathrooms: "bathrooms",
	bedrooms: "bedrooms"
}
