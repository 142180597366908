import { Color4, IAnimationKey, Layer, Texture, UniversalCamera } from "@babylonjs/core";
import { useContext, useEffect, useRef, useState } from "react";
import { GetExternalSetting, GlobalContext } from "../../context/GlobalContext";
import { BabylonContext } from "./ExteriorBabylonScene";
import * as BABYLON from "@babylonjs/core";
import React from "react";
import { atom, useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { rCurrentFrame, rUseDisplayOverride } from "recoil/atoms";
// Defined shared type and defaults
const SharedObjectTemplate: {
	CurrentlyPickedMesh: BABYLON.AbstractMesh | undefined;
	IsPointerDown: boolean;
	HasFinishedAsyncLoad: boolean;
	maxamount: number;
	CameraName: string;
	FramePixelRatio: number;
	TrueOrbitDeceleration: boolean;
	UnheldFrictionAmount: number;
	MinOrbitFPS: number;
	ImageRef: React.RefObject<HTMLImageElement>;
	CanvasRef: React.RefObject<HTMLCanvasElement>;
	WrapperDevRef: React.RefObject<HTMLDivElement>;
	UsingBabylonLayer: boolean;
	LoadedExternalSettings: boolean;
	Squencelayer: Layer | undefined;
	ImageLoadingRequired: boolean;
} = {
	CurrentlyPickedMesh: undefined,
	IsPointerDown: false,
	HasFinishedAsyncLoad: false,
	maxamount: 299,
	CameraName: "Camera001",
	FramePixelRatio: 25,
	TrueOrbitDeceleration: false,
	UnheldFrictionAmount: 0.2,
	MinOrbitFPS: 1,
	ImageRef: React.createRef<HTMLImageElement>(),
	CanvasRef: React.createRef<HTMLCanvasElement>(),
	WrapperDevRef: React.createRef<HTMLDivElement>(),
	UsingBabylonLayer: false,
	LoadedExternalSettings: false,
	Squencelayer: undefined,
	ImageLoadingRequired: false,
};

// Main react wrapper for sequence manager
const SequenceManager = (prop: {}) =>
{
	const { LoadedExternalSettings } = useContext(GlobalContext);
	const { ExternalSettings } = useContext(GlobalContext);

	const { CurrentlyPickedMesh } = useContext(BabylonContext);
	const { HasFinishedAsyncLoad } = useContext(GlobalContext);
	const { IsPointerDown } = useContext(BabylonContext);
	// const ImageRef = React.createRef<HTMLImageElement>();
	// Setup shared object
	const [SequenceSharedObject] = useState(SharedObjectTemplate);
	const [CurrentIndexForceUpdate, setCurrentIndexForceUpdate] = useState(0);
	let initialTick = 0;
	const Ticks = useRef(initialTick);
	// Set shared varaibles to react variables
	SequenceSharedObject.CurrentlyPickedMesh = CurrentlyPickedMesh;
	SequenceSharedObject.IsPointerDown = IsPointerDown;
	SequenceSharedObject.HasFinishedAsyncLoad = HasFinishedAsyncLoad;
	useEffect(() =>
	{
		Ticks.current = Ticks.current + 1;
	}, [CurrentIndexForceUpdate]);
	useEffect(() =>
	{
		const interval = setInterval(() =>
		{
			//console.log("ReactRenderTicks: " + Ticks.current);
			Ticks.current = initialTick;
		}, 1000);
		return () => clearInterval(interval);
	}, []);
	useEffect(() =>
	{
		if (LoadedExternalSettings)
		{
			SequenceSharedObject.LoadedExternalSettings = true;
			console.log(ExternalSettings);
			SequenceSharedObject.maxamount = GetExternalSetting(ExternalSettings, "NumberOfFrames");
			SequenceSharedObject.CameraName = GetExternalSetting(ExternalSettings, "CameraName");
			SequenceSharedObject.FramePixelRatio = GetExternalSetting(ExternalSettings, "FramePixelRatio");
			SequenceSharedObject.TrueOrbitDeceleration = GetExternalSetting(ExternalSettings, "TrueOrbitDeceleration");
			SequenceSharedObject.UnheldFrictionAmount = GetExternalSetting(ExternalSettings, "UnheldFrictionMultiplier");
			SequenceSharedObject.MinOrbitFPS = GetExternalSetting(ExternalSettings, "MinOrbitFPS");
			SequenceSharedObject.UsingBabylonLayer = GetExternalSetting(ExternalSettings, "UsingBabylonLayer");
		}
		//else console.log("Waiting for settings to load...");
	}, [LoadedExternalSettings]);

	return <SequenceManagerBabylon SharedObject={SequenceSharedObject} setCurrentIndexForceUpdate={setCurrentIndexForceUpdate} />;
};

// Babylon side sequence manager
const SequenceManagerBabylon = (prop: { SharedObject: typeof SharedObjectTemplate; setCurrentIndexForceUpdate: React.Dispatch<React.SetStateAction<number>>; }) =>
{
	// Only scene, engine and setters (cannot get from react variables at this point)
	const { scene } = useContext(BabylonContext);
	const { engine } = useContext(BabylonContext);
	const { SetHasFinishedAsyncLoad } = useContext(GlobalContext);
	const { SetIsPointerDown } = useContext(BabylonContext);
	const setRecoilCurrentFrame = useSetRecoilState(rCurrentFrame);
	const SharedObject = prop.SharedObject;
	const UseDisplayOverride = useRecoilValue(rUseDisplayOverride);
	const OverrideForceBabylon = UseDisplayOverride;

	let CurrentMovedPixels = 0;
	let CurrentProcessingMovement = 0;
	let CurrentFrame = 0;
	let PreviousFPS = 0;
	let PreviousFrame = 0;
	let BabylonImages: Texture[] = [];

	let PositionData: IAnimationKey[] | undefined;
	let RotationData: IAnimationKey[] | undefined;
	var layer: Layer | undefined;
	let DelayLoad = 0;
	var DJICamera: UniversalCamera | undefined;

	var [debugstring, Setdebugstring] = useState<string>("debug");
	var pointerprevious = 0;
	var pointercurrent = 0;
	let CurrentLoadedImages = 0;
	let devloadimages = true;
	function LSetHasFinishedAsyncLoad(Finished: boolean)
	{
		SetHasFinishedAsyncLoad(Finished);
		SharedObject.HasFinishedAsyncLoad = Finished;
	}
	function LSetIsPointerDown(PointerDown: boolean)
	{
		SetIsPointerDown(PointerDown);
		SharedObject.IsPointerDown = PointerDown;
	}
	let ReactImages: HTMLImageElement[] = [];
	useEffect(() =>
	{
		SharedObject.ImageLoadingRequired = OverrideForceBabylon;
		SharedObject.UsingBabylonLayer = OverrideForceBabylon;
	}, [OverrideForceBabylon]);
	useEffect(() =>
	{
		if (scene && engine)
		{
			if (scene.isReady())
			{
				onSceneReady();
			} else
			{
				scene.onReadyObservable.addOnce((scene) => onSceneReady());
			}
			engine.runRenderLoop(onRender);

			// Cannot use addOnce, as Main exterior sequence file loaded on a second append
			scene.onDataLoadedObservable.add(() =>
			{
				OnSceneDataLoaded();
			});
			if (!SharedObject.UsingBabylonLayer && !OverrideForceBabylon)
			{
			}
		}
	}, [scene]);
	//----------------------------Only Once Scene Is Ready----------------------------//
	async function asyncLoadImage(i: number, ImageAmount: number)
	{
		let temp = new Texture("./images/" + i + ".jpg", null, false, true, undefined, function ()
		{
			BabylonImages[i] = temp;
			if (i >= ImageAmount)
			{
				LSetHasFinishedAsyncLoad(true);
			}
		});
		//images[i].updateURL('./images/' + i + '.jpg');
	}
	async function CheckImageLoading()
	{
		if (!SharedObject.HasFinishedAsyncLoad)
		{
			if (DelayLoad > 120)
			{
				for (let i = 0; i <= SharedObject.maxamount; i++)
				{
					asyncLoadImage(i, SharedObject.maxamount);
				}
			} else
			{
				DelayLoad = DelayLoad + 1;
			}
		}
	}
	const onSceneReady = () =>
	{
		if (!scene) return;

		//----------------------------Load Thumbnail Images----------------------------//
		LoadImages();

		//----------------------------Layer----------------------------//
		// Background 2D sprite where we load our 2d image textures for the turntable


		//----------------------------Register Mouse / Touch Input----------------------------//
		scene.onPointerObservable.add((pointerInfo) =>
		{
			switch (pointerInfo.type)
			{
				case BABYLON.PointerEventTypes.POINTERDOWN:
					// Setdebugstring("PointerEventTypes.POINTERDOWN");
					pointerprevious = pointerInfo.event.x;
					pointercurrent = pointerInfo.event.x;
					if (SharedObject.HasFinishedAsyncLoad)
					{
						if (pointerInfo.event.button === 0)
						{
							if (SharedObject.CurrentlyPickedMesh === undefined)
							{
								LSetIsPointerDown(true);
								CurrentMovedPixels = 0;
								//console.log("true");
							}
						}
					}
					break;
				case BABYLON.PointerEventTypes.POINTERUP:
					// Setdebugstring("PointerEventTypes.POINTERUP");
					if (SharedObject.HasFinishedAsyncLoad)
					{
						if (pointerInfo.event.button === 0)
						{
							//if (SharedObject.CurrentlyPickedMesh == undefined)
							//{
							LSetIsPointerDown(false);
							//console.log("false");
							//}
						}
					}
					break;
				case BABYLON.PointerEventTypes.POINTERMOVE:
					// Setdebugstring("PointerEventTypes.POINTERMOVE");
					if (SharedObject.HasFinishedAsyncLoad)
					{
						// Setdebugstring("PointerEventTypes.POINTERMOVE.HasFinishedAsyncLoad");
						if (SharedObject.IsPointerDown && SharedObject.CurrentlyPickedMesh === undefined)
						{
							pointerprevious = pointercurrent;
							pointercurrent = pointerInfo.event.x;
							var pointerdelta = (pointerprevious - pointercurrent) * -1;
							CurrentMovedPixels += pointerdelta;
							// Setdebugstring("pointerInfo.event.offsetX: " + pointerdelta);
							//console.log('Pointer Move');
						}
					}
					break;
				case BABYLON.PointerEventTypes.POINTERWHEEL:
					// Setdebugstring("PointerEventTypes.POINTERWHEEL");
					//console.log("POINTER WHEEL");
					break;
				case BABYLON.PointerEventTypes.POINTERPICK:
					// Setdebugstring("PointerEventTypes.POINTERPICK");
					//console.log("POINTER PICK");
					break;
				case BABYLON.PointerEventTypes.POINTERTAP:
					// Setdebugstring("PointerEventTypes.POINTERTAP");
					//console.log("POINTER TAP");
					break;
				case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
					// Setdebugstring("PointerEventTypes.POINTERDOUBLETAP");

					break;
			}
		});

	};
	function LoadImages()
	{

		if ((!SharedObject.LoadedExternalSettings && !SharedObject.ImageLoadingRequired) || !scene)
			return;
		var assetsManager = new BABYLON.AssetsManager(scene);
		BabylonImages = [];
		ReactImages = [];
		if (SharedObject.ImageLoadingRequired)
		{
			SharedObject.ImageLoadingRequired = false;
		}
		if (SharedObject.UsingBabylonLayer || OverrideForceBabylon)
		{
			BabylonImages[0] = new Texture("./imagesw/0.webp");
			CurrentLoadedImages = 1;
			console.log("START LOADING IMAGES");
			if (devloadimages)
			{
				for (let i = 1; i <= SharedObject.maxamount; i++)
				{
					var TextureTask = assetsManager.addTextureTask("BackgroundImageTask", "./imagesw/" + i + ".webp");
					TextureTask.onSuccess = function (task)
					{
						BabylonImages[i] = task.texture;
						CurrentLoadedImages = CurrentLoadedImages + 1;
						if (i === SharedObject.maxamount)
						{
							LSetHasFinishedAsyncLoad(true);
						}
					};
					TextureTask.onError = function (task)
					{
						console.log("Image: " + i + " Failed Load");
					};
				}
				layer = new Layer("", BabylonImages[0].url, scene, true);
				SharedObject.Squencelayer = layer;
				//HasFinishedAsyncLoad = true;
			}
			else
			{
				LSetHasFinishedAsyncLoad(true);
			}
		}
		else
		{
			CurrentLoadedImages = 1;
			scene.clearColor = new Color4(0, 0, 0, 0);
			// let tempImages :HTMLImageElement[]= [];
			for (let i = 1; i <= SharedObject.maxamount; i++)
			{
				var tempImage = new Image();
				if (tempImage)
				{
					tempImage.src = "imagesw/" + i + ".webp";
				}
				ReactImages.push(tempImage);
				// tempImages.push(tempImage);
				if (i === SharedObject.maxamount)
				{
					// SetReactImages(tempImages);
					LSetHasFinishedAsyncLoad(true);
				}
			}
		}

		assetsManager.load();
	}
	function SetupCamera()
	{
		if (!scene) return;
		scene.setActiveCameraByName(SharedObject.CameraName);
		if (scene.getCameraByName(SharedObject.CameraName) !== null)
		{
			DJICamera = scene.getCameraByName(SharedObject.CameraName) as UniversalCamera;
			PositionData = scene.getCameraByName(SharedObject.CameraName)?.animations[0].getKeys();
			RotationData = scene.getCameraByName(SharedObject.CameraName)?.animations[1].getKeys();
			//console.table(DJICamera.inputs.attached)
			//var cam = new BABYLON.FreeCameraInputsManager(DJICamera)
			//DJICamera.inputs.clear()
			//DJICamera.inputs.addMouse
			//console.table(DJICamera.inputs.attached)
		}
	}

	// On Sceneloader append, load or mesh
	function OnSceneDataLoaded()
	{
		// Can use camera check to see when exterior file loaded (based on correct camera name)
		//if (scene.getCameraByName(CameraName) !== null)

		SetupCamera();
	}

	const onRender = () =>
	{
		if (CurrentLoadedImages <= 0 && SharedObject.LoadedExternalSettings || SharedObject.ImageLoadingRequired)
			LoadImages();
		//CheckImageLoading();
		if (SharedObject.CurrentlyPickedMesh === undefined)
		{
			HandleTurntableRotation();
		}
	};
	//TO BE CALLED ON RENDER FRAME TO HANDLE ROTATION OF TURNTABLE
	function HandleTurntableRotation()
	{
		if (!SharedObject.HasFinishedAsyncLoad || !scene) return;
		PreviousFrame = CurrentFrame;
		var deltaTimeInMillis = scene.getEngine().getDeltaTime();
		let FPS = (1 / deltaTimeInMillis) * 1000;
		//console.log("FPS : " + Math.trunc(FPS));
		FPS = Math.trunc(FPS);
		if (Math.abs(PreviousFPS - FPS) > 5)
		{
			Setdebugstring(FPS.toString());
			PreviousFPS = FPS;
		}
		CurrentMovedPixels = Math.trunc(CurrentMovedPixels);
		if (Math.abs(CurrentMovedPixels) > SharedObject.FramePixelRatio)
		{
			let LerpTime = 80;
			let LerpPercent = deltaTimeInMillis / LerpTime;
			if (LerpPercent > 1)
			{
				LerpPercent = 1;
			}
			if (Math.abs(CurrentMovedPixels * LerpPercent) < SharedObject.FramePixelRatio * (SharedObject.MinOrbitFPS * (deltaTimeInMillis / 1000)))
			{
				CurrentProcessingMovement = CurrentMovedPixels;
			} else
			{
				if (SharedObject.TrueOrbitDeceleration)
				{
					CurrentProcessingMovement += Math.trunc(CurrentMovedPixels * LerpPercent);
				} else
				{
					CurrentProcessingMovement = Math.trunc(CurrentMovedPixels * LerpPercent);
				}
			}
			if (Math.abs(CurrentProcessingMovement) > SharedObject.FramePixelRatio)
			{
				let FramesMoved = Math.trunc(CurrentProcessingMovement / SharedObject.FramePixelRatio);
				if (Math.abs(FramesMoved) > 0)
				{
					FramesMoved *= -1;
					if (CurrentFrame + FramesMoved > SharedObject.maxamount)
					{
						CurrentFrame = 0 + (CurrentFrame + FramesMoved - SharedObject.maxamount);
					} else if (CurrentFrame + FramesMoved < 0)
					{
						CurrentFrame = SharedObject.maxamount + (CurrentFrame + FramesMoved);
					} else
					{
						CurrentFrame += FramesMoved;
					}
					if (SharedObject.IsPointerDown)
					{
						CurrentMovedPixels -= CurrentProcessingMovement;
					} else
					{
						CurrentMovedPixels -= CurrentProcessingMovement * SharedObject.UnheldFrictionAmount;
					}
					FramesMoved *= -1;
					if (SharedObject.TrueOrbitDeceleration)
					{
						CurrentProcessingMovement = 0;
					}
				}
			} else
			{
				if (!SharedObject.TrueOrbitDeceleration)
				{
					let FramesMoved = Math.sign(CurrentProcessingMovement) * -1;
					if (CurrentFrame + FramesMoved > SharedObject.maxamount)
					{
						CurrentFrame = 0 + (CurrentFrame + FramesMoved - SharedObject.maxamount);
					} else if (CurrentFrame + FramesMoved < 0)
					{
						CurrentFrame = SharedObject.maxamount + (CurrentFrame + FramesMoved);
					} else
					{
						CurrentFrame += FramesMoved;
					}
					if (SharedObject.IsPointerDown)
					{
						CurrentMovedPixels -= CurrentProcessingMovement;
					} else
					{
						CurrentMovedPixels -= CurrentProcessingMovement * SharedObject.UnheldFrictionAmount;
					}
				}
			}
		}
		if (PreviousFrame === CurrentFrame)
		{
			// CurrentFrame += 1;
			// if (CurrentFrame > SharedObject.maxamount)
			// {
			// 	CurrentFrame = 0;
			// }
		}
		if (PreviousFrame !== CurrentFrame)
		{
			setRecoilCurrentFrame(CurrentFrame);
			//Setting Camera Position / Rotation
			if (DJICamera !== undefined)
			{
				if (PositionData !== undefined && RotationData !== undefined)
				{
					DJICamera.position = PositionData[CurrentFrame]?.value;
					DJICamera.rotationQuaternion = RotationData[CurrentFrame]?.value;
				}
			}
			//Setting Layer Texture
			if (SharedObject.Squencelayer !== undefined)
			{
				if (BabylonImages.length >= CurrentFrame)
				{
					SharedObject.Squencelayer.texture = BabylonImages[CurrentFrame];
					//console.log("coordinates");
				}
			}

			if (!SharedObject.UsingBabylonLayer && !OverrideForceBabylon)
			{
				if (ReactImages.length > CurrentFrame)
				{
					//SetExteriorOrbitImage(ReactImages[CurrentFrame]);
					if (SharedObject.ImageRef)
					{
						if (SharedObject.ImageRef.current)
						{
							SharedObject.ImageRef.current.src = ReactImages[CurrentFrame].src;
							prop.setCurrentIndexForceUpdate(CurrentFrame);
						}
					}
					if (SharedObject.CanvasRef)
					{
						if (SharedObject.CanvasRef.current)
						{
							// var hRatio = SharedObject.CanvasRef.current.width / ReactImages[CurrentFrame].width;
							// var vRatio = SharedObject.CanvasRef.current.height / ReactImages[CurrentFrame].height;
							// var ratio = Math.min(hRatio, vRatio);

							var context = SharedObject.CanvasRef.current.getContext("2d");
							if (context)
							{
								//context.scale(ratio, ratio);

								context.imageSmoothingEnabled = false;
								context.imageSmoothingQuality = "high";
								if (SharedObject.WrapperDevRef && SharedObject.WrapperDevRef.current)
								{
									SharedObject.CanvasRef.current.height = SharedObject.WrapperDevRef.current.clientHeight * window.devicePixelRatio;
									SharedObject.CanvasRef.current.width = SharedObject.WrapperDevRef.current.clientWidth * window.devicePixelRatio;
								}
								context.clearRect(0, 0, SharedObject.CanvasRef.current.width, SharedObject.CanvasRef.current.height);
								context.drawImage(ReactImages[CurrentFrame], 0, 0, ReactImages[CurrentFrame].width, ReactImages[CurrentFrame].height, 0, 0, SharedObject.CanvasRef.current.width, SharedObject.CanvasRef.current.height);
							}
						}
					}
					// if(ReactImages[CurrentFrame].src)
					// {
					// 	document.getElementsByClassName("ExteriorOrbitImage").item(0)?.setAttribute("src", ReactImages[CurrentFrame].src);
					// }
				}
			}
		}

	}

	if (SharedObject.UsingBabylonLayer || OverrideForceBabylon)
	{
		return <div>
			{/* <div style={{ position: "absolute", top: "25%", left: "25%" }}>
				{debugstring}
			</div> */}
		</div>;
	} else
	{
		return (
			<div ref={SharedObject.WrapperDevRef} id="ImageTurntableWrapper">
				{/* {ExteriorOrbitImage} */}
				<img className="ExteriorOrbitImage" ref={SharedObject.ImageRef} src="./imagesw/0.webp" alt=""></img>
				{/* <div style={{ position: "absolute", top: "25%", left: "25%" }}>
					{debugstring}
				</div> */}
				{/* <canvas style={{ top: "0%", left: "0%" }} ref={SharedObject.CanvasRef} /> */}

			</div>
		);
	}
};


export default SequenceManager;
