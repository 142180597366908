import { IdToken, useAuth0 } from "@auth0/auth0-react";
import { CollectionReference, DocumentData } from "firebase/firestore/lite";

export async function useFirebaseCollection(collection: string)
{
	const { getIdTokenClaims, isAuthenticated } = useAuth0();
	if (!isAuthenticated) return;
	const token = await getIdTokenClaims();
	const response = await fetch('https://us-central1-iris-90830.cloudfunctions.net/getFirebaseCollection', {
		headers: {
			'Authorization': `Bearer ${token.__raw}`,
			'collection': `${collection}`,
		},
	});
	const data: CollectionReference<DocumentData> = await response.json();
	// console.log("Received Firebase Collection '" + collection + "': ", data);
	return data;
}
