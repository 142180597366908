import Image from "components/Image";
import { Box, Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Typography } from "@mui/material";
import React from "react";
import { jsx } from "@emotion/react";


const UnitLabel = (prop: {
	UnitName: string, UnitTypeAndIconURL: [Type: string, URL: string], UnitLevel: string, HasExploreButton: boolean,
	OnClickDispatch: React.Dispatch<
		{
			type: 'Clicked',
			payload:
			{
				UnitName: string,
				UnitType: string,
			}
		}>,
	UnitInfoAndIcons?: [InfoType: string, IconURL: string, Value: string][], UnitInfoTable?: [InfoType: string, Value: string, FontSize: number, Bold: boolean, Italic: boolean][],
}) =>
{
	let UnitTypeInfoIconURLArray: [InfoType: string, IconURL: string, Value: string][] = [];
	if (prop.UnitInfoAndIcons && prop.UnitInfoAndIcons.length)
	{
		UnitTypeInfoIconURLArray = prop.UnitInfoAndIcons;
	}
	let UnitInfoTableArray: [InfoType: string, Value: string, FontSize: number, Bold: boolean, Italic: boolean][] = [];
	if (prop.UnitInfoTable && prop.UnitInfoTable.length)
	{
		UnitInfoTableArray = prop.UnitInfoTable;
	}


	let InfoMap = () =>
	{
		let GridItemArray: JSX.Element[] = [];
		for (let i = 0; i < UnitTypeInfoIconURLArray.length; i++)
		{
			GridItemArray.push(<Grid item xs={7} sm={5} md={3.5} lg={3}>
				<Box sx={{ display: 'flex', flexDirection: "row" }}>
					<Typography variant={"body2"} alignSelf={"center"}>
						{UnitTypeInfoIconURLArray[i][2]}
					</Typography>
					<Image style={{ minHeight: "50px", paddingLeft: "1rem", aspectRatio: "1/1" }} src={UnitTypeInfoIconURLArray[i][1]} />
				</Box>
			</Grid>)
		}
		return GridItemArray;
	};


	let InfoTable = () =>
	{
		let TableRows: JSX.Element[] = [];
		for (let i = 0; i < UnitInfoTableArray.length; i++)
		{
			let IsBold = UnitInfoTableArray[i][3] ? "bold" : "normal";
			TableRows.push(
				<TableRow >
					<TableCell sx={{ fontWeight: IsBold }}>{UnitInfoTableArray[i][0]}</TableCell>
					<TableCell sx={{ fontWeight: IsBold }} align="right">{UnitInfoTableArray[i][1]}</TableCell>
				</TableRow>
			)
		}
		return TableRows;
	}

	const HorizontalBorder = <Box sx={{ borderTop: "1px solid #d1cdca", marginLeft: "1.5rem", marginRight: "1.5rem" }} />;
	let UnitLevelString = "Level" + prop.UnitLevel;
	return (

		< Box sx={{ display: 'flex', flexDirection: 'column', backgroundColor: "#ffffff" }
		}>
			<Box sx={{ paddingLeft: "1.5rem", paddingRight: '1.5rem', paddingTop: "0.5rem", paddingBottom: '0.5rem', width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
				<Typography variant={"h4"} alignSelf={"center"}>
					{prop.UnitName}
				</Typography>
				<Image style={{ aspectRatio: "1/1" }} src={"https://realspace-core-iris.pages.dev/images/62cd561d4760d4b750dd508f_Unit-Bed.svg"} />
			</Box>
			<Box sx={{ paddingLeft: "1.5rem", paddingRight: '1.5rem', paddingTop: "0.5rem", paddingBottom: '0.5rem', width: "100%", display: 'flex', flexDirection: 'row', justifyContent: "space-between" }}>
				<Box sx={{ height: "100%", width: "100%", display: 'flex', flexDirection: 'row' }}>
					<Typography variant={"h5"} alignSelf={"center"}>
						Type
					</Typography>
					<Image style={{ height: "2rem", aspectRatio: "1/1" }} src={prop.UnitTypeAndIconURL[1]} />
				</Box>
				<Typography variant={"h5"} alignSelf={"center"}>
					{UnitLevelString}
				</Typography>
			</Box>
			{HorizontalBorder}

			<Box sx={{ paddingLeft: "1.5rem", paddingRight: '1.5rem', paddingTop: "0.5rem", paddingBottom: '0.5rem', width: "100%" }}>
				<Grid container spacing={2} columns={15} sx={{ width: "100%", height: "100%", marginTop: 0 }}>
					{InfoMap()}
				</Grid>
			</Box>
			<Box sx={{ paddingLeft: "1.5rem", paddingRight: '1.5rem', paddingTop: "0.5rem", paddingBottom: '0.5rem', width: "100%" }}>
				<TableContainer>
					<Table sx={{ minWidth: "100%" }}>
						<TableBody>
							{InfoTable()}
						</TableBody>
					</Table>
				</TableContainer>
			</Box>

			<Box sx={{ paddingLeft: "1.5rem", paddingRight: '1.5rem', paddingTop: "0.5rem", paddingBottom: '0.5rem', width: "100%" }}>
				<Button
					color="secondary"
					variant="contained"
					size="large"
					sx={{ width: "100%" }}>
					EXPLORE
				</Button>
			</Box>

		</Box >
	);
}
export default UnitLabel;

