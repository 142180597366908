import { StringDictionary } from "@babylonjs/core";
import { Blob } from "buffer";
import React, { useState, createContext, useEffect, useContext, ReactNode } from "react";
import { StringLiteralType } from "typescript";
import { ActiveFiltersType, ParsedItemData, ParseStructureOverride, FilteredSetType, tCMSData, tUnitType, tUnitInfoTypes, tUnitInfoType, tUnitInfo, tLevel, tPointOfInterest } from "../components/Types";
import { useRecoilValue, useSetRecoilState } from "recoil";

// Import the functions you need from the SDKs you need
import { initializeApp, getApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore, collection, getDocs, Firestore, doc, getDoc } from 'firebase/firestore/lite';
import { rBuildingData, rCMSDataLoaded, rCurrentUnit, rLevelData, rMediaData, rPoIData, rTypeData, rUnitData } from "recoil/atoms";

type ExteriorContextType = {
	FiltersEnabled: boolean;
	SetFiltersEnabled: React.Dispatch<React.SetStateAction<boolean>>;
	CurrentFilters: typeof FilteredSetType;
	SetCurrentFilters: React.Dispatch<React.SetStateAction<typeof FilteredSetType>>;
	ItemInfo: [string, string][];
	setItemInfo: React.Dispatch<React.SetStateAction<[string, string][]>>;
	ItemPoint: [number, number];
	setItemPoint: React.Dispatch<React.SetStateAction<[number, number]>>;
	ItemInfoArray: typeof tUnitInfoTypes | undefined;
	ParsedItemInfoArray: typeof ParsedItemData[] | undefined;
	UnitTypes: Map<string, typeof tUnitType>;
	isApartmentSelected: boolean;
	setIsApartmentSelected: React.Dispatch<React.SetStateAction<boolean>>;
	apartmentLink: string;
	setApartmentLink: React.Dispatch<React.SetStateAction<string>>;
	isDrawerLinkOpen: boolean;
	setIsDrawerLinkOpen: React.Dispatch<React.SetStateAction<boolean>>;
	CMSData: typeof tCMSData | undefined;
	setCMSData: React.Dispatch<React.SetStateAction<typeof tCMSData | undefined>>;
	POIArray: ReactNode[];
	setPOIArray: React.Dispatch<React.SetStateAction<ReactNode[]>>;
};

let OverridenTypeNames: Map<string, string> = new Map<string, string>();
OverridenTypeNames.set("mesh", ParseStructureOverride.IsStringOverride);
OverridenTypeNames.set("price", ParseStructureOverride.IsNumberOverride);
OverridenTypeNames.set("bedrooms", ParseStructureOverride.IsNumberOverride);

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBkz6XIoRNsXWtTPfc7m2nsRyX_2uuRmDs",
	authDomain: "iris-90830.firebaseapp.com",
	projectId: "iris-90830",
	storageBucket: "iris-90830.appspot.com",
	messagingSenderId: "497274020230",
	appId: "1:497274020230:web:a94d44733028b220a10c07",
	measurementId: "G-EDENW8P2FR"
};

const ExternalApartmentJsonFileName: string = "ApartmentData.json";

export const ExteriorContext = createContext<ExteriorContextType>(undefined!);
export const ExteriorContextProvider = ({ children }: React.PropsWithChildren<{}>) =>
{
	const [FiltersEnabled, SetFiltersEnabled] = useState(false);
	const [CurrentFilters, SetCurrentFilters] = useState(FilteredSetType);
	const [ItemInfo, setItemInfo] = useState<[string, string][]>([]);
	const [ItemPoint, setItemPoint] = useState<[number, number]>(initializeItemPoint);
	const [ItemInfoArray, setItemInfoArray] = useState<typeof tUnitInfoTypes | undefined>(undefined);
	const [ParsedItemInfoArray, setParsedItemInfoArray] = useState<typeof ParsedItemData[] | undefined>(undefined);
	const [UnitTypes, setUnitTypes] = useState<Map<string, typeof tUnitType>>(new Map());
	const [isApartmentSelected, setIsApartmentSelected] = useState<boolean>(false);
	const [apartmentLink, setApartmentLink] = useState<string>("");
	const [isDrawerLinkOpen, setIsDrawerLinkOpen] = React.useState<boolean>(false);
	const [CMSData, setCMSData] = React.useState<typeof tCMSData | undefined>(undefined);
	const [POIArray, setPOIArray] = useState<ReactNode[]>([]);


	const UnitData = useRecoilValue(rUnitData);
	const TypeData = useRecoilValue(rTypeData);
	const BuildingData = useRecoilValue(rBuildingData);
	const LevelData = useRecoilValue(rLevelData);
	const PoIData = useRecoilValue(rPoIData);

	const SetCMSDataLoaded = useSetRecoilState(rCMSDataLoaded);

	function initializeItemPoint()
	{
		let tuple: [number, number] = [0, 0];
		return tuple;
	}
	function AssignValue(Object: [string, string | number], tempInfoMap: Map<string, string | boolean | number>)
	{
		if (typeof Object[1] === "number")
		{
			let key = Object[0];
			let value: number = Object[1];
			tempInfoMap.set(key, value);
			//OutputMapArray.push(tempInfoMap);
		} else if (typeof Object[1] === "boolean")
		{
			let key = Object[0];
			let value: boolean = Object[1];
			tempInfoMap.set(key, value);
			//OutputMapArray.push(tempInfoMap);
		} else if (typeof Object[1] === "string")
		{
			let key = Object[0];
			let value: string = Object[1];
			let IsNumber = false;
			let IsBoolean = false;

			let finalNumbers: number = 0;
			var boolean: boolean = false;
			if (OverridenTypeNames.get(key) !== undefined)
			{
				if (OverridenTypeNames.get(key) === "number")
				{
					var onlyNumbers = value;
					onlyNumbers = value.replace(/[^\d.]/g, "");
					if (onlyNumbers !== "number")
					{
						onlyNumbers = value.replace(/\D/g, "");
					}
					finalNumbers = +onlyNumbers;
					if (typeof finalNumbers === "number")
					{
						IsNumber = true;
					}
				}
				if (OverridenTypeNames.get(key) === "boolean")
				{
					IsBoolean = value === "true" || value === "false";
					if (IsBoolean)
						boolean = value === "true";
				}
			}
			if (IsNumber)
			{
				tempInfoMap.set(key, finalNumbers);
			} else if (IsBoolean)
			{
				tempInfoMap.set(key, boolean);
				//OutputMapArray.push(tempInfoMap);
			}
			else
			{
				tempInfoMap.set(key, value);
				//OutputMapArray.push(tempInfoMap);
			}
			return tempInfoMap;
		}
	}
	function ParseItemArrayInfo(Data: typeof tUnitInfoTypes)
	{
		var OutputMapArray: Map<string, string | boolean | number>[] = [new Map<string, string | boolean | number>()];
		for (let i = 0; i < Data.length; i++)
		{
			var tempInfoMap: Map<string, string | boolean | number> = new Map<string, string | boolean | number>();
			Object.entries(Data[i].UnitInfo).map((Object) =>
			{
				// TODO: Try pass in directly since it may be object type, so not need reference system
				const CheckMap: Map<string, string | boolean | number> | undefined = AssignValue(Object, tempInfoMap);
				if (CheckMap !== undefined)
					tempInfoMap = CheckMap;
			});
			const ValidTypeInfo = Data[i].TypeInfo;
			if (ValidTypeInfo !== undefined)
			{
				Object.entries(ValidTypeInfo).map((Object) =>
				{
					const CheckMap: Map<string, string | boolean | number> | undefined = AssignValue(Object, tempInfoMap);
					if (CheckMap !== undefined)
						tempInfoMap = CheckMap;
				});
			}
			OutputMapArray.push(tempInfoMap);
		}


		return OutputMapArray;
	}
	function StoreUnitTypes(Data: typeof tCMSData)
	{
		let NewUnitTypesMap: Map<string, typeof tUnitType> = new Map();
		for (let i = 0; i < Data.Types.length; i++)
		{
			NewUnitTypesMap.set(Data.Types[i].unitName, Data.Types[i]);
		}
		setUnitTypes(NewUnitTypesMap);
		return NewUnitTypesMap;
	}


	useEffect(() =>
	{
		let CMSData: typeof tCMSData = { Units: [], Levels: [], PointOfInterests: [], Types: [] };
		if (BuildingData)
		{
			CMSData['Buildings'] = BuildingData;
		}
		if (UnitData)
		{
			CMSData.Units = UnitData as typeof tUnitInfo[];
		}
		if (LevelData)
		{
			CMSData.Levels = LevelData as typeof tLevel[];
		}
		if (PoIData)
		{
			CMSData.PointOfInterests = PoIData as typeof tPointOfInterest[];
		}
		if (TypeData)
		{
			CMSData.Types = TypeData as typeof tUnitType[];
		}
		setCMSData(CMSData);

		const UnitTypes = StoreUnitTypes(CMSData);
		let NewItemInfoArray: typeof tUnitInfoTypes = [];
		for (let i = 0; i < CMSData.Units.length; i++)
		{
			let NewInfo: typeof tUnitInfoType = { UnitInfo: CMSData.Units[i] };
			NewInfo.UnitInfo = CMSData.Units[i];
			if (NewInfo.UnitInfo.type !== undefined)
			{
				const FoundUnit = UnitTypes?.get(NewInfo.UnitInfo.type);
				if (FoundUnit !== undefined)
				{
					NewInfo.TypeInfo = FoundUnit;
				}
			}
			NewItemInfoArray.push(NewInfo);
		}

		if (NewItemInfoArray && NewItemInfoArray.length > 0)
		{
			setItemInfoArray(NewItemInfoArray);
			setParsedItemInfoArray(ParseItemArrayInfo(NewItemInfoArray));
			SetCMSDataLoaded(true);

		}
	}, [UnitData, LevelData, PoIData, BuildingData, TypeData])

	useEffect(() =>
	{
		// console.log(ParsedItemInfoArray);
	}, [ParsedItemInfoArray]);

	return (
		<ExteriorContext.Provider
			value={{
				FiltersEnabled,
				SetFiltersEnabled,
				CurrentFilters,
				SetCurrentFilters,
				ItemInfo,
				setItemInfo,
				ItemPoint,
				setItemPoint,
				ItemInfoArray,
				ParsedItemInfoArray,
				UnitTypes,
				isApartmentSelected,
				setIsApartmentSelected,
				apartmentLink,
				setApartmentLink,
				isDrawerLinkOpen,
				setIsDrawerLinkOpen,
				CMSData,
				setCMSData,
				POIArray,
				setPOIArray,
			}}>
			{children}
		</ExteriorContext.Provider>
	);
};

// Can't have useContext in here
// export function GetExternalSetting(key: String)
// {
// 	const { ExternalSettings } = useContext(GlobalContext);
// 	return ExternalSettings[key as keyof typeof ExternalSettings];
// }
export function GetExternalSetting(settings: object, key: string)
{
	return settings[key as keyof typeof settings];
}
