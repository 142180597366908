import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import AddIcon from "@mui/icons-material/Add";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import IconButton from "@mui/material/IconButton";
import { useRecoilValue } from "recoil";
import { useEffect, useState } from "react";
import { rPOIPositions } from "recoil/atoms";
export type POIInfo = {
	x: number;
	y: number;
	name: string;
};

const POI = (prop: POIInfo) =>
{
	let RecoilPOIPositions = useRecoilValue(rPOIPositions);
	let LabelText = prop.name;
	const [PositionX, setPositionX] = useState(prop.x);
	const [PositionY, setPositionY] = useState(prop.y);
	var isMovie: boolean = false;
	var LabelIcon = isMovie ? <PlayArrowIcon /> : <AddIcon />;

	useEffect(() =>
	{
		for (let i = 0; i < RecoilPOIPositions.length; i++)
		{
			if (RecoilPOIPositions[i][0] === LabelText)
			{
				setPositionX(RecoilPOIPositions[i][1].x);
				setPositionY(RecoilPOIPositions[i][1].y);
			}
		}
	}, [RecoilPOIPositions])
	return (
		<Stack
			direction="row"
			spacing={0.1}
			style={{
				position: "absolute",
				marginLeft: PositionX,
				marginTop: PositionY,
			}}>
			<IconButton
				// onClick={onClick}
				size="large"
				style={{
					color: "#FFFFFFFF",
					borderRadius: 0,
					borderColor: "#FFFFFFFF",
					backgroundColor: "#00BFBF",
					padding: 0,
					marginTop: 0,
					marginRight: 0,
					textTransform: "none",
					left: "0%",
					top: "0%",
					width: "40px",
					height: "40px",
					zIndex: 1,
				}}>
				{LabelIcon}
			</IconButton>
			<Button
				// onClick={onClick}
				variant="contained"
				size="large"
				style={{
					fontSize: 20,
					color: "#FFFFFFFF",
					borderRadius: 0,
					borderColor: "#FFFFFFFF",
					backgroundColor: "#00BFBF",
					boxShadow: "none",
					padding: 10,
					marginTop: 0,
					marginRight: 0,
					textTransform: "none",
					left: "0%",
					top: "0%",
					minWidth: "10px",
					height: "40px",
					zIndex: 1,
				}}>
				{LabelText}
			</Button>
		</Stack>
	);
};
export default POI;
