import * as React from "react";
import { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { ExteriorContext } from "../ExteriorContext";
import { FieldInfoType } from "../Panel/FilterBar";

export function SelectorButtonGroup(prop: { FontSize: number; DisplayName: string; InfoName: string; value?: number; min: number; max: number; step?: number; FieldInfos: Map<string, typeof FieldInfoType>; })
{
	const { CurrentFilters } = React.useContext(ExteriorContext);
	const { SetCurrentFilters } = React.useContext(ExteriorContext);

	const [value, setValue] = useState<number | any>(prop.value);

	useEffect(() =>
	{
		let Range = prop.FieldInfos.get(prop.InfoName);
		if (Range)
		{
			setValue(Range?.min);
			UpdateFilters(Range?.min);
			// console.log("FieldInfos changed for " + prop.InfoName + " to " + Range?.min + " - " + Range?.max);
		}
	}, [prop.FieldInfos]);

	const addValue = () =>
	{
		let NewValue = Math.min(value + 1, prop.max as number);
		setValue(NewValue);
		UpdateFilters(NewValue);
	};
	const minusValue = () =>
	{
		let NewValue = Math.max(value - 1, prop.min as number);
		setValue(NewValue);
		UpdateFilters(NewValue);
	};

	function UpdateFilters(min: number)
	{
		let ChangedFilters = new Map(CurrentFilters);
		ChangedFilters.set(prop.InfoName, [min, prop.max] as [number, number]);
		SetCurrentFilters(ChangedFilters);
	}

	return (
		<ListItem disablePadding>
			<List style={{ flexGrow: 1 }}>
				<ListItem disablePadding>
					<ListItemText primary={prop.DisplayName} sx={{ "& .MuiTypography-root": { fontSize: 18, color: "#00263A" } }}></ListItemText>
				</ListItem>
				<ListItem style={{ backgroundColor: "#FFFFFF", borderRadius: 4, paddingLeft: 24, paddingTop: 0, paddingBottom: 0 }}>
					<ListItemText primary={value} sx={{ "& .MuiTypography-root": { fontSize: prop.FontSize, paddingTop: 1, paddingBottom: 1 } }}></ListItemText>
					<Button sx={{ fontSize: 30, color: "#00263A", padding: 0 }} onClick={addValue}>
						+
					</Button>
					<Button sx={{ fontSize: 30, color: "#00263A", padding: 0 }} onClick={minusValue}>
						─
					</Button>
				</ListItem>
			</List>
		</ListItem>
	);
}
