import * as React from 'react';
import HomePageLayout from '../Core/HomePageLayout';

const backgroundImage =
	'https://realspace-core-iris.pages.dev/images/62c428b543620ea167977813_BM_Aerial.jpg';

export default function HomePage()
{
	return (
		<HomePageLayout
			sxBackground={{
				backgroundImage: `url(${backgroundImage})`,
				backgroundColor: '#7fc7d9', // Average color of the background image.
				backgroundPosition: 'center',
				zIndex: 0
			}}
		>
			{/* Increase the network loading priority of the background image. */}
			<img
				style={{ display: 'none' }}
				src={backgroundImage}
				alt="increase priority"
			/>

		</HomePageLayout>
	);
}