import * as React from 'react';
import { Theme, useTheme } from '@mui/material/styles';
import OutlinedInput from '@mui/material/OutlinedInput';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { ExteriorContext } from '../ExteriorContext';
import { useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import { Chip, Typography } from '@mui/material';
import Box from '@mui/material/Box';

const styles = {
	select: {
		'.MuiOutlinedInput-notchedOutline': {
			borderColor: '#000000',
			borderRadius: 0,
		},
		'&:hover .MuiOutlinedInput-notchedOutline': {
			borderColor: '#color',
			//borderWidth: '0.15rem',
		},
		'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
			// borderColor: 'red',
		},
	},
};


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
		},
	},
};

function getStyles(name: string, personName: readonly string[], theme: Theme)
{
	return {
		fontWeight:
			personName.indexOf(name) === -1
				? theme.typography.fontWeightRegular
				: theme.typography.fontWeightMedium,
	};
}

export default function TypeDropdown(prop: { typename: string, reset: boolean; })
{
	const { CurrentFilters, SetCurrentFilters, UnitTypes, ParsedItemInfoArray } = React.useContext(ExteriorContext);
	const allTypes = "All Unit Types";
	const theme = useTheme();
	const [personName, setPersonName] = React.useState<string[]>([]);
	const names: string[] = Array.from(UnitTypes.keys()).sort();

	function updateFilterSelection(array: string[])
	{
		const filterNames = array.length > 0 ? personName : names;
		let ChangedFilters = new Map(CurrentFilters);
		ChangedFilters.set(prop.typename, [filterNames.join("||"), ""] as [string, string]);
		SetCurrentFilters(ChangedFilters);
	}

	useEffect(() =>
	{
		updateFilterSelection(personName);
	}, [personName]);


	useEffect(() =>
	{
		setPersonName([]);
	}, [prop.reset])
	// if (prop.reset)
	// {
	// 	
	// }

	const handleChange = (event: SelectChangeEvent<typeof personName>) =>
	{
		const {
			target: { value },
		} = event;
		setPersonName(typeof value === 'string' ? value.split(',') : value);// On autofill we get a stringified value.
	};
	//const label = <Typography sx={{ color: "#00263A", fontSize: 20 * window.innerHeight / 1440, textAlign: "center" }} id="demo-multiple-chip-label">{personName.length > 0 ? "" : allTypes}</Typography>;
	return (
		<FormControl fullWidth variant="outlined" sx={{ mt: 2, }} >
			<InputLabel shrink={false} sx={{ color: "#00263A", fontSize: 20 * window.innerHeight / 1440, mx: "calc(48% /2)" }} id="demo-multiple-chip-label">{personName.length > 0 ? "" : allTypes}</InputLabel>
			<Select
				id="demo-multiple-chip"
				multiple
				sx={styles.select}
				value={personName}
				onChange={handleChange}
				renderValue={(selected) => (
					<Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, maxHeight: 200 }}>
						{selected.map((value) => (
							<Chip key={value} label={value} />
						))}
					</Box>
				)}
				MenuProps={MenuProps}
			>
				{names.map((name) => (
					<MenuItem key={name} value={name} style={getStyles(name, personName, theme)}>{name}</MenuItem>
				))}
			</Select>
		</FormControl >
	);
}
