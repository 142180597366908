import { Box, Button, Checkbox, Container, FormControlLabel, ImageList, ImageListItem } from "@mui/material";
import React, { ChangeEvent, useEffect, useReducer, useState } from "react";
import theme from "../theme/theme";
import Typography from "../components/UI/Typography";
import { MemoFilterCheckboxComponent } from "../Core/FilterCheckboxComponent";
import { ImageViewer } from "../Core/ImageViewer";
import { getAnalytics } from "firebase/analytics";
import { getApp, initializeApp } from "firebase/app";
import { getFirestore, getDoc, getDocs, collection } from "firebase/firestore/lite";
import TileGallery from "../Core/TileGallery"
import GalleryTileInfo from "../Core/Types/GalleryTypes"
import { number } from "card-validator";
import { boolean } from "yup";
import { State } from "history";
import { atom, useRecoilValue } from "recoil";
import { MemoFilterButton } from "Core/FilterButtonComponent";
import { rMediaData } from "recoil/atoms";
// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
	apiKey: "AIzaSyBkz6XIoRNsXWtTPfc7m2nsRyX_2uuRmDs",
	authDomain: "iris-90830.firebaseapp.com",
	projectId: "iris-90830",
	storageBucket: "iris-90830.appspot.com",
	messagingSenderId: "497274020230",
	appId: "1:497274020230:web:a94d44733028b220a10c07",
	measurementId: "G-EDENW8P2FR"
};

const FilterTypeStyle = {
	fontSize: 18,
	fontWeight: 100,
	textTransform: "none",
	marginLeft: 1,
	marginRight: 1,
};

//Possible actions for the FiltersState reducer 
// type FiltersAction =
// 	| {
// 		type: 'update';
// 		payload: {
// 			FilterName: string;
// 			State: boolean;
// 		};
// 	}
// 	| {
// 		type: 'reset'
// 	} |
// 	{
// 		type: 'updateExclusive';
// 		payload: {
// 			FilterName: string;
// 			State: boolean;
// 			DisableStates: string[];
// 		};
// 	};
//Initialize default Filters States


export default function GalleryPage()
{
	//State of the Current image being displayed and the array of images it is in [Index in set, Current Image information, Array of Image info the current image is a party of]
	const [CurrentImageInSet, setCurrentImageInSet] = useState<[number, typeof GalleryTileInfo, typeof GalleryTileInfo[]]>([-1, GalleryTileInfo, []]);
	//State of all the tiles which could potentially be displayed in the gallery
	const [TileItemData, SetTileItemData] = useState<typeof GalleryTileInfo[]>([]);
	const [CurrentlyDisplayedTiles, setCurrentlyDisplayedTiles] = useState<typeof GalleryTileInfo[]>([]);
	//State of the active filters
	const [ActiveFilters, setActiveFilters] = useState<string[]>([]);
	const [FilterChanged, setFilterChanged] = useState<[string, boolean]>(["", false]);
	const [ResetFilters, setResetFilters] = useState<boolean>(false);

	const RecoilMediaData = useRecoilValue(rMediaData);

	let LocalActiveFilters: string[] = ActiveFilters;


	const InitialFiltersState =
	{
		Interior: false,
		Exterior: false,
	};

	//Reduced for Filter States 
	const FilterReducer = (state: {},
		action: FiltersAction) =>
	{
		switch (action.type)
		{
			case 'boolUpdate':
				let tempState = structuredClone(state);
				for (let i = 0; i < action.payload.DisableStates.length; i++)
				{
					tempState[action.payload.DisableStates[i]] = false;
				}
				tempState[action.payload.FilterName] = action.payload.State;
				return tempState;
			case 'reset':
				setResetFilters(true);
				return InitialFiltersState;
			case 'increment':
				throw new Error('Unimplemented action type: ${action.type}');
				break;
			case 'decrement':
				throw new Error('Unimplemented action type: ${action.type}');
				break;
			case 'numUpdate':
				throw new Error('Unimplemented action type: ${action.type}');
				break;
			case 'initialize':
				throw new Error('Unimplemented action type: ${action.type}');
				break;
			default:
				throw new Error('Unknown action type: ${action.type}');
				break;
		}
	}


	const [FiltersState, FiltersDispatch] = useReducer(FilterReducer, InitialFiltersState);

	useEffect(() =>
	{
		let TempTileArray: typeof GalleryTileInfo[] = [];
		if (RecoilMediaData && RecoilMediaData.length > 0)
		{
			RecoilMediaData.forEach((row, index) =>
			{
				if (row['name'] === undefined || row['imagefile'] === undefined && row['videoFile'] === undefined) { console.log("No image or video found in row: " + index) }
				else
				{
					let NewTile: typeof GalleryTileInfo =
					{
						name: row['name'],
						type: row['videoFile'] === "" || row['videoFile'] === undefined ? "image" : "video",
						url: row['videoFile'] === "" || row['videoFile'] === undefined ? row['imagefile'][0]['downloadURL'] : row['videoFile'][0]['downloadURL'],
						tags: row['filterCategoriesMultiReference'] === undefined ? [""] : row['filterCategoriesMultiReference']
					};
					let Type = structuredClone(NewTile.type);
					let Tags = structuredClone(NewTile.tags);
					Tags.push(Type);
					TempTileArray.push(NewTile);
				}
			})
			if (TempTileArray.length > 0)
			{
				SetTileItemData(TempTileArray);
			}
		}


	}, [RecoilMediaData]);
	useEffect(() =>
	{
		PopulateDisplayedTiles();
	}, [TileItemData]);
	useEffect(() =>
	{
		PopulateDisplayedTiles();

	}, [ActiveFilters]);
	useEffect(() =>
	{
		if (ResetFilters)
		{
			setResetFilters(false);
		}
	}, [ResetFilters]);

	useEffect(() =>
	{
		PopulateDisplayedTiles();
	}, [FiltersState]);
	useEffect(() =>
	{
		let index = LocalActiveFilters.findIndex(
			(value, index, obj) =>
			{
				if (value == FilterChanged[0])
				{
					return true;
				}
			}
		);
		if (index != -1)
		{
			if (FilterChanged[1] == false)
			{

			}
		}
		else
		{
			if (FilterChanged[1] == true)
			{

			}
		}
		setActiveFilters(LocalActiveFilters);
	}, [FilterChanged]);
	function isImage(url: string)
	{
		return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(url);
	}
	function isVideo(url: string)
	{
		return /\.(mp4|webm|wmv|avi|mov|ogg)$/.test(url);
	}

	function PopulateDisplayedTiles()
	{
		let TempDisplayTileArray: typeof GalleryTileInfo[] = [];
		for (let i = 0; i < TileItemData.length; i++)
		{
			let FoundAllFilters = true;
			let FiltersArray = Object.entries(FiltersState);
			for (let j = 0; j < FiltersArray.length; j++)
			{
				{
					if (FiltersArray[j][1] === true)
					{
						if (TileItemData[i].tags.findIndex((value, index, obj) =>
						{
							if (value == FiltersArray[j][0].toLowerCase())
							{
								return true;
							}
						}) == -1)
						{
							FoundAllFilters = false;
							break;
						}
					}
				}
			}
			if (FoundAllFilters)
			{
				TempDisplayTileArray.push(TileItemData[i]);
			}
		}
		if (TempDisplayTileArray != CurrentlyDisplayedTiles)
		{
			setCurrentlyDisplayedTiles(TempDisplayTileArray);
		}
	}

	const InteriorCheckbox = <MemoFilterCheckboxComponent CheckboxName={"Interior"} FilterName={"interior"} reset={ResetFilters} FilterStateDispatch={FiltersDispatch} />;
	const ExteriorCheckbox = <MemoFilterCheckboxComponent CheckboxName={"Exterior"} FilterName={"exterior"} reset={ResetFilters} FilterStateDispatch={FiltersDispatch} />;
	const ShowAllButton = <MemoFilterButton style={{ color: "primary" }} ButtonName={"ShowAll"} FilterName={"ResetAll"} ButtonType={"reset"} FilterStateDispatch={FiltersDispatch} sx={FilterTypeStyle} />;
	const ImagesButton = <MemoFilterButton style={{ color: "primary" }} ButtonName={"Images"} FilterName={"image"} ButtonType={"boolupdate"} FilterStateDispatch={FiltersDispatch} FiltersToDisable={['iframe', 'video']} sx={FilterTypeStyle} />;
	const VideosButton = <MemoFilterButton style={{ color: "primary" }} ButtonName={"Videos"} FilterName={"video"} ButtonType={"boolupdate"} FilterStateDispatch={FiltersDispatch} FiltersToDisable={['iframe', 'image']} sx={FilterTypeStyle} />;
	return (
		<Box
			sx={{
				// mt: 3,
				mb: 14,
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
			}}
		>
			<Container
				sx={{
					mt: 3,
					mb: 3,
					display: 'flex',
					alignItems: 'center',
					height: "100px",
					width: "100%",
					justifyContent: "space-between",
					[theme.breakpoints.up('xs')]: {
						// backgroundColor: 'green',
						width: '95%',
						paddingLeft: 0,
						paddingRight: 0
					},
					[theme.breakpoints.up('xl')]: {
						// backgroundColor: 'red',
						minWidth: '95%'
					},
				}}
			>
				<Box>
					{ShowAllButton}
					{ImagesButton}
					{VideosButton}
				</Box>
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						justifyContent: "right"
					}}>
					<Typography paddingRight={5} variant='h5'>
						Filter:
					</Typography>
					{InteriorCheckbox}
					{ExteriorCheckbox}
				</Box>
			</Container>
			<Box id="GalleryPageGalleryBox"
				sx={{
					width: "100%",

					paddingLeft: "3rem",
					paddingRight: "3rem",
				}}>
				<TileGallery
					ContentTiles={CurrentlyDisplayedTiles}
					SetClickedTile={setCurrentImageInSet}
				/>
			</Box>

			<ImageViewer CurrentImageAndImageSet={CurrentImageInSet} setCurrentImageInSet={setCurrentImageInSet}></ImageViewer>
		</Box >
	);

}
