import { SVGProps } from "react";
import { useTheme } from "@mui/material";

export interface ILogoProps extends SVGProps<SVGSVGElement>
{
    size?: number;
}

export default function Logo({ size = 0.7, ...props }: ILogoProps)
{
    const theme = useTheme();

    return (
        <svg
            style={{ top: 0 }}
            width={Math.round(359.5 * size)}
            height={Math.round(52.7 * size)}
            viewBox="0 0 300.5 52.7"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby="realspace-logo-title"
            role="img"
            {...props}
        >
            <title id="realspace-logo-title">Realspace</title>

            <path
                d="M125.01,46.7v5.39h-21.46V25.08h6.43v21.61h15.03Zm10.31-1.91c1.26,.73,2.68,1.26,4.48,1.75,1.8,.5,3.34,.69,4.91,.69,3.82,0,5.74-.8,5.74-2.37,0-.57-.34-1.03-.99-1.45-1.26-.8-2.68-1.15-5.58-1.8-2.22-.5-4.05-1.07-5.51-1.68-2.87-1.26-4.78-3.52-4.78-7.23,0-2.79,1.11-4.86,3.33-6.2,2.26-1.34,5.13-2.03,8.64-2.03,3.83,0,8.15,1.13,11.33,2.85l-2.65,4.52c-3.02-1.26-6.08-1.85-8.95-1.85-3.17,0-5.24,.8-5.24,2.45,0,1.53,1.95,2.18,5.16,2.91l.99,.23c3.29,.65,5.89,1.65,7.84,2.98,1.95,1.3,2.94,3.17,2.94,5.62,0,2.87-1.15,5.01-3.44,6.43-2.3,1.42-5.24,2.1-8.84,2.1-2.1,0-4.21-.31-6.35-.88-2.14-.61-4.15-1.44-5.68-2.48l2.64-4.56Zm108.89-20.32c4.59,0,8.49,1.43,11.25,4.12l-2.53,4.43c-2.3-1.88-5.39-3.04-8.26-3.04-4.86,0-8.42,3.33-8.42,8.61s3.56,8.64,8.45,8.64c2.79,0,6.14-1.24,8.34-3.28l2.52,4.37c-2.64,2.72-6.73,4.38-11.36,4.38-7.8,.11-14.61-5.43-14.5-14.11-.15-8.84,6.85-14.23,14.5-14.12ZM18.74,41.88c.12-.04,.23-.08,.35-.13,3.45-1.31,5.24-3.98,5.24-7.87,0-2.41-.77-4.67-2.29-6.2-1.88-1.87-4.9-2.6-8.91-2.6H0v27H6.43V30.44h7.04c3.02,0,4.4,1.26,4.4,3.52,0,2.49-1.45,3.52-4.36,3.52h-4.5l8.44,14.61h7.61l-6.32-10.21Zm26.24-.84h11.33v-5.47h-14.49l3.16,5.47Zm-3.55-10.6h16.26v-5.35h-22.68v27h22.87v-5.39h-16.45V30.44Zm233.67,10.6h11.33v-5.47h-14.49l3.16,5.47Zm-3.55-10.6h16.26v-5.35h-22.68v27h22.87v-5.39h-16.45V30.44Zm-91.11-5.35h-13.27v27h6.43V30.44h6.81c3.1,0,4.51,.96,4.51,3.67s-1.3,3.71-4.48,3.71h-6.61l3.1,5.39h3.47c7.08,0,11.05-3.14,11.05-9.1s-3.98-9.03-11.02-9.03Zm32.55,0h-7.23l-11.13,27h6.92l7.69-20.73,3.63,9.83h-7.04l2.96,5.13h5.95l2.14,5.78h7.15l-11.05-27Zm-128.99,0h-7.23l-11.13,27h6.92l7.69-20.73,3.63,9.83h-7.04l2.96,5.13h5.95l2.14,5.78h7.15l-11.05-27Z"
                fill='#ffffff'
            />
            <path
                d="M316.75,0h-16.5l-8.25,14.29,8.25,14.29h16.5l8.25-14.29-8.25-14.29Zm-1.67,25.7h-13.17l-6.59-11.41,6.59-11.41h13.17l6.59,11.41-6.59,11.41Z"
                fill='#00bfbf'
            />
            <path
                d="M312.07,15.88c.06-.02,.11-.04,.17-.06,1.66-.63,2.52-1.92,2.52-3.79,0-1.16-.37-2.25-1.1-2.98-.9-.9-2.36-1.25-4.29-1.25h-6.32v13h3.09V10.37h3.39c1.46,0,2.12,.61,2.12,1.69,0,1.2-.7,1.69-2.1,1.69h-2.17l4.07,7.04h3.67l-3.04-4.92Z"
                fill='#00bfbf'
            />
        </svg>
    );
}
