import { useAuth0 } from "@auth0/auth0-react";
import Button from "@mui/material/Button";
import React from "react";

const LogoutButton = () =>
{
    const { logout } = useAuth0();

    return (
        <Button variant="outlined" onClick={() => logout({ returnTo: window.location.origin })}>
            LogOut
        </Button>
    );
};

export default LogoutButton;