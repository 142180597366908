//import { useState } from 'react';
import { useState, useEffect, useRef, useContext, ReactNode } from "react";
import { RecoilRoot, atom, selector, useRecoilState, useRecoilValue, useSetRecoilState, useResetRecoilState } from "recoil";
import { CreateApartmentLabel } from "../Label/createApartmentLabel";
// import POIArray from "../POI.json";
import { Vector3, Vector2, HemisphericLight, Scene, UniversalCamera, StandardMaterial, AbstractMesh, Color4 } from "@babylonjs/core";
import * as BABYLON from "@babylonjs/core";
import { AdvancedDynamicTexture, Button, TextBlock } from '@babylonjs/gui/2D';
import * as GUI from '@babylonjs/gui';
import "@babylonjs/loaders";
import { GetExternalSetting, GlobalContext } from "../../context/GlobalContext";
import React from "react";
import { ExteriorContext } from "Exterior/ExteriorContext";
import { FilteredSetType, ParsedItemData, tCMSData, tUnitInfoTypes, tUnitType } from "../../components/Types";
import { BabylonContext } from "Exterior/Babylon/ExteriorBabylonScene";
import POI from "../POI/POI";
import ButtonMUI from "@mui/material/Button";
import { ApartmentLabelPosition, rApartmentInfo, rApartmentLabelSlot, rCurrentFrame, rOverlayButtonEnabled, rOverlayInput, rPOIArray, rPOIPositions } from "recoil/atoms";
import { RecoilApartmentLabel } from "Exterior/Label/rApartmentLabel";

// Defined shared type and defaults
const SharedObjectTemplate: {
	FiltersEnabled: boolean,
	CurrentFilters: typeof FilteredSetType,
	FiltersWereUpdated: boolean,
	CurrentlyPickedMesh: BABYLON.AbstractMesh | undefined,
	IsPointerDown: boolean,
	HasFinishedAsyncLoad: boolean,
	CameraName: string,
	CanvasRect: DOMRect | undefined,
	hasParsedItemInfoArray: boolean,
	ParsedItemInfoArray: typeof ParsedItemData[] | undefined,
	ItemInfoArray: typeof tUnitInfoTypes | undefined,
	CMSDataJson: typeof tCMSData | undefined,
	UnitTypes: Map<string, typeof tUnitType> | undefined,
	MainCamera: UniversalCamera | undefined,
	RecoilCurrentFrame: number,
	PreviousFrame: number,
	POIOpened: boolean,
} = {
	FiltersEnabled: false,
	CurrentFilters: FilteredSetType,
	FiltersWereUpdated: true,
	CurrentlyPickedMesh: undefined,
	IsPointerDown: false,
	HasFinishedAsyncLoad: false,
	CameraName: "Camera001",
	CanvasRect: undefined,
	hasParsedItemInfoArray: false,
	ParsedItemInfoArray: undefined,
	ItemInfoArray: undefined,
	CMSDataJson: undefined,
	UnitTypes: undefined,
	MainCamera: undefined,
	RecoilCurrentFrame: 0,
	PreviousFrame: 0,
	POIOpened: false,
};

// Main react wrapper for exterior manager
const ExteriorManager = (prop: {}) =>
{

	const { LoadedExternalSettings } = useContext(GlobalContext);
	const { ExternalSettings } = useContext(GlobalContext);

	const { CurrentFilters } = useContext(ExteriorContext);
	const { FiltersEnabled } = useContext(ExteriorContext);
	const { UnitTypes } = useContext(ExteriorContext);
	const { ParsedItemInfoArray, ItemInfoArray } = useContext(ExteriorContext);
	const { CMSData: CMSDataJson } = useContext(ExteriorContext);

	const { CurrentlyPickedMesh } = useContext(BabylonContext);
	const { IsPointerDown } = useContext(BabylonContext);
	const { HasFinishedAsyncLoad } = useContext(GlobalContext);
	const { CanvasRect } = useContext(BabylonContext);

	const [MainCamera, SetMainCamera] = useState<UniversalCamera | undefined>(undefined);
	const RecoilCurrentFrame = useRecoilValue(rCurrentFrame);
	const [PreviousFrame, setPreviousFrame] = useState(0);


	// Setup shared object
	// const [SharedObject] = useState(SharedObjectTemplate);
	const [SharedObject] = useState({
		FiltersEnabled: FiltersEnabled,
		CurrentFilters: CurrentFilters,
		FiltersWereUpdated: true,
		CurrentlyPickedMesh: CurrentlyPickedMesh,
		IsPointerDown: IsPointerDown,
		HasFinishedAsyncLoad: HasFinishedAsyncLoad,
		CameraName: "Camera001",
		CanvasRect: CanvasRect,
		hasParsedItemInfoArray: false,
		ParsedItemInfoArray: ParsedItemInfoArray,
		ItemInfoArray: ItemInfoArray,
		CMSDataJson: CMSDataJson,
		UnitTypes: UnitTypes,
		MainCamera: MainCamera,
		RecoilCurrentFrame: RecoilCurrentFrame,
		PreviousFrame: PreviousFrame,
		POIOpened: false,
	});
	// Set shared varaibles to react variables
	SharedObject.FiltersEnabled = FiltersEnabled;
	SharedObject.CurrentFilters = CurrentFilters;
	SharedObject.CurrentlyPickedMesh = CurrentlyPickedMesh;
	SharedObject.IsPointerDown = IsPointerDown;
	SharedObject.HasFinishedAsyncLoad = HasFinishedAsyncLoad;
	SharedObject.ParsedItemInfoArray = ParsedItemInfoArray;
	SharedObject.ItemInfoArray = ItemInfoArray;
	SharedObject.CMSDataJson = CMSDataJson;
	SharedObject.UnitTypes = UnitTypes;
	SharedObject.CanvasRect = CanvasRect;
	SharedObject.MainCamera = MainCamera;
	SharedObject.RecoilCurrentFrame = RecoilCurrentFrame;
	SharedObject.PreviousFrame = PreviousFrame;

	useEffect(() =>
	{
		SharedObject.FiltersWereUpdated = true;
	}, [CurrentFilters, FiltersEnabled]);
	useEffect(() =>
	{

		if (ParsedItemInfoArray !== undefined)
		{
			if (ParsedItemInfoArray.length > 0)
			{ SharedObject.hasParsedItemInfoArray = true; }
		}


	}, [ParsedItemInfoArray]);
	useEffect(() =>
	{
		if (LoadedExternalSettings)
		{
			SharedObject.CameraName = GetExternalSetting(ExternalSettings, "CameraName");
		}
		//else console.log("Waiting for settings to load...");
	}, [LoadedExternalSettings]);

	return <ExteriorManagerBabylon SharedObject={SharedObject} SetMainCamera={SetMainCamera} SetPreviousFrame={setPreviousFrame} />;
}

// Babylon side exterior manager
const ExteriorManagerBabylon = (prop: { SharedObject: typeof SharedObjectTemplate; SetMainCamera: React.Dispatch<React.SetStateAction<UniversalCamera | undefined>>; SetPreviousFrame: React.Dispatch<React.SetStateAction<number>> }) =>
{
	const { scene, engine } = useContext(BabylonContext);
	const { SetCurrentlyPickedMesh } = useContext(BabylonContext);

	const { setItemInfo, setItemPoint, setPOIArray } = useContext(ExteriorContext);
	const setPOIPositions = useSetRecoilState(rPOIPositions)
	const setrPOIArray = useSetRecoilState(rPOIArray);
	const setrApartmentInfo = useSetRecoilState(rApartmentInfo);
	const setApartmentLabelPosition = useSetRecoilState(ApartmentLabelPosition);
	const setrApartmentLabel = useSetRecoilState(rApartmentLabelSlot);
	const setOverlayInfo = useSetRecoilState(rOverlayInput);
	const SetRecoilOverlayButtonEnabled = useSetRecoilState(rOverlayButtonEnabled);

	const MousePosition = useRef<[number, number]>([0, 0]);
	let POIButtonArray: Button[] = []
	let POIPointArray: BABYLON.Mesh[] = []
	const SharedObject = prop.SharedObject;
	const [POIActive, setPOIActive] = useState(SharedObject.POIOpened);
	const [SceneLoadComplete, setSceneLoadComplete] = useState(false);
	let DefaultVisibility = 0.0;
	let DefaultFilterVisibility = 0.6;
	let DefaultPickedVisibility = 1.0;
	let MaterialEmmisiveStrength = 0.7;
	let MeshInfoMapMap = new Map<BABYLON.AbstractMesh, Map<string, string | boolean | number>>();
	let ApartmentMeshMap = new Map<string, BABYLON.AbstractMesh>();
	let MaterialColourMap = new Map<string, BABYLON.Color3>([
		["Selected", new BABYLON.Color3(0.760525, 0.545724, 0.004025)],
		["Available", new BABYLON.Color3(0.0, 0.199495, 0.260417)],
		["Sold", new BABYLON.Color3(0.296138, 0.076185, 0.076185)],
		["Held", new BABYLON.Color3(0.445201, 0.165132, 0.004391)],
		["Hidden", new BABYLON.Color3(0.033105, 0.056128, 0.07036)],
		["Unavailable", new BABYLON.Color3(0.033105, 0.056128, 0.07036)],
	]);

	function GetMaterialColour(Availability: string)
	{
		if (!MaterialColourMap.has(Availability))
			return BABYLON.Color3.White();
		return MaterialColourMap.get(Availability) as BABYLON.Color3;
	}
	function GetMeshColour(Mesh: AbstractMesh)
	{
		if (!MeshInfoMapMap.has(Mesh))
			return BABYLON.Color3.White();
		if (!MeshInfoMapMap.get(Mesh)?.has("availability"))
			return BABYLON.Color3.White();
		return GetMaterialColour(MeshInfoMapMap.get(Mesh)?.get("availability") as string);
	}
	function UpdateMeshMaterial(Mesh: AbstractMesh, IsSelected: boolean = false)
	{
		if (!Mesh)
			return;
		let NewColur: BABYLON.Color3 = GetMaterialColour("Selected");
		if (!IsSelected)
			NewColur = GetMeshColour(Mesh);
		const StandardMat = Mesh.material as StandardMaterial;
		if (StandardMat)
		{
			StandardMat.diffuseColor = NewColur;
			let EmmisiveColour = NewColur.clone();
			EmmisiveColour.r *= 1 + MaterialEmmisiveStrength;
			EmmisiveColour.g *= 1 + MaterialEmmisiveStrength;
			EmmisiveColour.b *= 1 + MaterialEmmisiveStrength;
			StandardMat.emissiveColor = EmmisiveColour;
		}
	}
	function projectVector3DToCanvas2D(position: Vector3, camera: UniversalCamera | undefined)
	{
		if (engine === undefined || camera === undefined || scene === undefined) return new Vector2();
		var transformMatrix = scene.getTransformMatrix();
		var viewport = camera.viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight());
		var coordinates = BABYLON.Vector3.Project(position, BABYLON.Matrix.Identity(), transformMatrix, viewport);
		var _2DCoordinates: Vector2 = new Vector2();
		_2DCoordinates.x = coordinates.x;
		_2DCoordinates.y = coordinates.y;
		return _2DCoordinates;
	}
	let FilterActive = false;
	let ModelSetup = false;

	useEffect(() =>
	{
		SetupSceneFunctions();
	}, [scene]);

	function SetupSceneFunctions()
	{
		if (scene && engine)
		{
			if (scene.isReady())
			{
				onSceneReady(scene);
			} else
			{
				scene.onReadyObservable.addOnce((scene) => onSceneReady(scene));
			}

			// // Clear previous render function
			// if (RenderFunction != undefined)
			// 	engine.stopRenderLoop(RenderFunction);

			// // // Set new render function assign
			// SetRenderFunction(RenderLoop);

			engine.runRenderLoop(RenderLoop);

			scene.executeWhenReady(() =>
			{
				onStart();
			}, false);
		}
	}

	const RenderLoop = () =>
	{
		if (scene)
		{
			onRender(scene);
			// scene.render();
		}
		// console.count("exteriorbabylon RENDER");
	};
	const onStart = () =>
	{
		console.log("ExteriorBabylon on start");
	};



	//------------------------------Babylon Scene------------------------------//
	const onSceneReady = (scene: Scene) =>
	{
		if (SharedObject.hasParsedItemInfoArray)
			LoadModel(scene);
		CheckPOIVisibility(scene);
	}
	//----------------------------Only Once Scene Is Ready----------------------------//
	const LoadModel = (scene: Scene) =>
	{
		if (ModelSetup)
			return;
		ModelSetup = true;
		//scene.getEngine().hideLoadingUI();
		//assetsManager.useDefaultLoadingScreen = false;
		//BABYLON.SceneLoader.ShowLoadingScreen = false;
		//----------------------------Load Scene----------------------------//
		BABYLON.SceneLoader.Append("./assets/", "IRIS01_Exterior_Sequence.babylon", scene, function (scene)
		{
			scene.clearColor = new Color4(0, 0, 0, 0);
			if (scene.getCameraByName(SharedObject.CameraName) !== null && scene.getCameraByName(SharedObject.CameraName) !== undefined)
			{
				let MainCamera: UniversalCamera = scene.getCameraByName(SharedObject.CameraName) as UniversalCamera;
				prop.SetMainCamera(MainCamera);
				SharedObject.MainCamera = MainCamera;
			}

			//----------------------------Light----------------------------//
			// This creates a light, aiming 0,1,0 - to the sky (non-mesh)
			// new HemisphericLight("light", new Vector3(0, 1, 0), scene);
			function HoverOverMesh(mesh: BABYLON.AbstractMesh)
			{

				if (!CurrentlyFilteredApartmentsMap.get(mesh.name) && FilterActive)
				{
					return;
				}
				// Increase opacity when hovered
				if (SharedObject.CurrentlyPickedMesh !== undefined)
				{
					if (mesh !== SharedObject.CurrentlyPickedMesh)
					{
						SelectNewHighlightMesh(mesh);
					}
				} else
				{
					if (mesh !== HighlightedMesh && !SharedObject.IsPointerDown)
					{
						mesh.visibility = 0.9;
					}
				}
			}
			function UnhoverMesh(mesh: BABYLON.AbstractMesh | undefined)
			{
				if (mesh && !mesh.name.includes("Collision"))
				{
					if (HighlightedMesh !== mesh)
					{
						// Default colour and opacity
						UpdateMeshMaterial(mesh);
						if (CurrentlyFilteredApartmentsMap !== undefined && CurrentlyFilteredApartmentsMap?.get(mesh.name) !== undefined && FilterActive)
						{
							mesh.visibility = DefaultFilterVisibility;
						} else
						{
							mesh.visibility = DefaultVisibility;
						}
					}
				}
			}

			function predicate(mesh: AbstractMesh)
			{
				if (mesh.isEnabled() && mesh.isPickable)
				{
					return true;
				}
				return false;
			}
			//----------------------------Register Mouse / Touch Input----------------------------//
			scene.onPointerObservable.add((pointerInfo) =>
			{
				switch (pointerInfo.type)
				{
					case BABYLON.PointerEventTypes.POINTERDOWN:
						if (SharedObject.HasFinishedAsyncLoad)
						{
							let x = pointerInfo.event.offsetX;
							let y = pointerInfo.event.offsetY;
							let PickedInfo = scene.pick(x, y, predicate, false, SharedObject.MainCamera)
							if (PickedInfo)
							{
								let PickedMesh = PickedInfo.pickedMesh;
								if (PickedMesh !== null)
								{
									SelectNewHighlightMesh(PickedMesh);
								}
							}
						}
						break;
					case BABYLON.PointerEventTypes.POINTERUP:
						if (SharedObject.HasFinishedAsyncLoad)
						{
							if (pointerInfo.event.button === 0)
							{
								SetCurrentlyPickedMesh(undefined);
								SharedObject.CurrentlyPickedMesh = undefined;
							}
						}
						break;
					case BABYLON.PointerEventTypes.POINTERMOVE:
						if (SharedObject.HasFinishedAsyncLoad)
						{
							let x = pointerInfo.event.offsetX;
							let y = pointerInfo.event.offsetY;
							MousePosition.current = [x, y];

						}

						break;
					case BABYLON.PointerEventTypes.POINTERWHEEL:
						break;
					case BABYLON.PointerEventTypes.POINTERPICK:
						if (!SharedObject.HasFinishedAsyncLoad)
						{
							let x = pointerInfo.event.offsetX;
							let y = pointerInfo.event.offsetY;
							let PickedInfo = scene.pick(x, y, predicate, false, SharedObject.MainCamera)
							if (PickedInfo)
							{
								let PickedMesh = PickedInfo.pickedMesh;
								if (PickedMesh === null)
								{
									SelectNewHighlightMesh(undefined);
									setrApartmentLabel(<></>);
								}
								else if (!ApartmentMeshMap.get(PickedMesh.name))
								{
									SelectNewHighlightMesh(undefined);
									setrApartmentLabel(<></>);
								}
							}
						}
						break;
					case BABYLON.PointerEventTypes.POINTERTAP:
						if (SharedObject.HasFinishedAsyncLoad)
						{
							let x = pointerInfo.event.offsetX;
							let y = pointerInfo.event.offsetY;
							let PickedInfo = scene.pick(x, y, predicate, false, SharedObject.MainCamera)
							if (PickedInfo)
							{
								let PickedMesh = PickedInfo.pickedMesh;
								if (PickedMesh === null)
								{
									SelectNewHighlightMesh(undefined);
									setrApartmentLabel(<></>);
								}
								else if (!ApartmentMeshMap.get(PickedMesh.name))
								{
									SelectNewHighlightMesh(undefined);
									setrApartmentLabel(<></>);
								}
							}
						}
						break;
					case BABYLON.PointerEventTypes.POINTERDOUBLETAP:
						if (SharedObject.HasFinishedAsyncLoad)
						{
							let x = pointerInfo.event.offsetX;
							let y = pointerInfo.event.offsetY;
							let PickedInfo = scene.pick(x, y, predicate, false, SharedObject.MainCamera)
							if (PickedInfo)
							{
								let PickedMesh = PickedInfo.pickedMesh;
								if (PickedMesh === null)
								{
									SelectNewHighlightMesh(undefined);
									setrApartmentLabel(<></>);
								}
								else if (!ApartmentMeshMap.get(PickedMesh.name))
								{
									SelectNewHighlightMesh(undefined);
									setrApartmentLabel(<></>);
								}
							}
						}
						break;
				}
			});
			//----------------------------Mesh Action Manager----------------------------//
			let SceneActionManager = new BABYLON.ActionManager(scene);
			for (let i = scene.meshes.length - 1; i >= 0; i--)
			{
				//WIP: remove collision meshes for now
				if (scene.meshes[i].name.includes("POI"))
				{
					scene.meshes[i].setEnabled(true);
					scene.meshes[i].renderingGroupId = 1;
					continue;
				} else if (scene.meshes[i].name.includes("Collision"))
				{
					scene.meshes[i].setEnabled(true);
					scene.meshes[i].isPickable = true;
					scene.meshes[i].visibility = 1;
					scene.meshes[i].renderingGroupId = 1;
					(scene.meshes[i] as BABYLON.Mesh).onBeforeRenderObservable.add(() => scene.getEngine().setColorWrite(false));
					(scene.meshes[i] as BABYLON.Mesh).onAfterRenderObservable.add(() => scene.getEngine().setColorWrite(true));
					continue;
				} else
				{
					scene.meshes[i].setEnabled(true);
					scene.meshes[i].isPickable = true;
					//scene.meshes[i].renderOutline = true;
					scene.meshes[i].renderingGroupId = 1;
					scene.meshes[i].visibility = DefaultVisibility;
					ApartmentMeshMap.set(scene.meshes[i].name, scene.meshes[i]);
					ApartmentMeshes.push(scene.meshes[i]);
					CurrentlyFilteredApartments.push(scene.meshes[i]);
					if (SetupMeshInfo(scene.meshes[i]))
					{
						let MeshMat = new BABYLON.StandardMaterial("meshmat", scene);
						MeshMat.disableLighting = true;
						scene.meshes[i].material = MeshMat;
						UpdateMeshMaterial(scene.meshes[i]);
					}
					else
					{
						scene.removeMesh(scene.meshes[i], true);
					}
				}
			}
			UpdateAllFiltered(SharedObject.CurrentFilters);
			ToggleFilterSystem(SharedObject.FiltersEnabled);
			if (SharedObject.ParsedItemInfoArray != undefined)
			{
				UpdateAllMeshes();
			}

			SetupActivePOIArray();
			setSceneLoadComplete(true);
			CheckPOIVisibility(scene);
		});


		if (engine !== undefined && scene !== undefined && SharedObject.CMSDataJson !== undefined)
		{
			let advancedTexture = GUI.AdvancedDynamicTexture.CreateFullscreenUI("UI", true, scene, BABYLON.Texture.BILINEAR_SAMPLINGMODE, true);

			for (let i = 0; i < SharedObject.CMSDataJson.PointOfInterests.length; i++)
			{
				if (advancedTexture !== undefined)
				{
					const CurrentPOI = SharedObject.CMSDataJson.PointOfInterests[i];
					let PostionMesh = BABYLON.MeshBuilder.CreateSphere(CurrentPOI.name + "POI", { diameter: 0 }, scene);
					PostionMesh.position.x = CurrentPOI.positionX;
					PostionMesh.position.y = CurrentPOI.positionY;
					PostionMesh.position.z = CurrentPOI.positionZ;
					PostionMesh.visibility = 0;
					PostionMesh.isPickable = false;
					POIPointArray.push(PostionMesh);

					var POIButton = Button.CreateSimpleButton("POI", CurrentPOI.name);
					POIButton.width = 0.09;
					POIButton.height = 0.03;
					POIButton.cornerRadius = 0;
					POIButton.color = "White";
					POIButton.thickness = 0;
					POIButton.background = "#00BFBF";
					advancedTexture.addControl(POIButton);
					POIButtonArray.push(POIButton);
					if (CurrentPOI.image !== "" || CurrentPOI.link !== "")
					{
						POIButton.onPointerClickObservable.add(function ()
						{
							if (CurrentPOI === undefined)
								return;
							SharedObject.POIOpened = true;
							SetRecoilOverlayButtonEnabled(true);
							if (CurrentPOI.image != "")
								setOverlayInfo(["image", CurrentPOI.image])
							else if (CurrentPOI.link != "")
							{
								if (CurrentPOI.link.endsWith(".mp4") || CurrentPOI.link.endsWith(".ogg") || CurrentPOI.link.endsWith(".webm"))
									// NEED CHECK FOR OFFLINE VIDEO: CurrentPOI.OfflineVideoName
									setOverlayInfo(["video", CurrentPOI.link])
								else
								{
									setOverlayInfo(["iframe", CurrentPOI.link])
								}
							}
							setPOIActive(SharedObject.POIOpened);
							console.log(CurrentPOI.name + "Has Been Pressed");
						});
					}
					POIButton.linkWithMesh(PostionMesh);
					POIButton.linkOffsetY = -25;
				}
			}
		}
		CheckPOIVisibility(scene);
	};

	function SetupActivePOIArray()
	{
		if (engine !== undefined && scene !== undefined && SharedObject.CMSDataJson !== undefined)
		{
			var newPOIArray = [];
			for (let i = 0; i < SharedObject.CMSDataJson.PointOfInterests.length; i++)
			{
				if (scene)
				{
					const poi = SharedObject.CMSDataJson.PointOfInterests[i];
					let MainCamera: UniversalCamera | undefined = SharedObject.MainCamera;
					if (MainCamera === undefined)
					{
						MainCamera = scene.getCameraByName(SharedObject.CameraName) as UniversalCamera;
					}
					if (MainCamera)
					{
						if (scene.getCameraByName(SharedObject.CameraName) !== null && scene.getCameraByName(SharedObject.CameraName) !== undefined)
						{
							var coordinates = projectVector3DToCanvas2D(new Vector3(poi.positionX, poi.positionY, poi.positionZ), MainCamera);
							newPOIArray.push(<POI key={i} x={coordinates.x} y={coordinates.y} name={poi.name} />);
						}
					}
					else
					{
						// UH OH NO CAMERA
					}
				}
			}
			setrPOIArray(newPOIArray);
		}
	}
	useEffect(() =>
	{
		if (SceneLoadComplete === true)
		{

		}
	}, [SceneLoadComplete])



	function UpdateAllMeshes()
	{
		if (scene == undefined)
			return;

		for (let i = 0; i < scene.meshes.length; i++)
		{
			if (!scene.meshes[i].name.includes("POI") && !scene.meshes[i].name.includes("Collision"))
			{
				SetupMeshInfo(scene.meshes[i]);
				UpdateMeshMaterial(scene.meshes[i]);
			}
		}
	}

	//----------------------------Selected Mesh----------------------------//
	// var CurrentlyPickedMesh: BABYLON.AbstractMesh | undefined;
	var HighlightedMesh: BABYLON.AbstractMesh | undefined;
	var HoveredMesh: BABYLON.AbstractMesh | undefined;
	var ApartmentMeshes: BABYLON.AbstractMesh[] = [];
	var CurrentlyFilteredApartments: BABYLON.AbstractMesh[] = [];
	var CurrentlyFilteredApartmentsMap: Map<string, BABYLON.AbstractMesh> = new Map<string, BABYLON.AbstractMesh>();
	var SceneActionManager: BABYLON.ActionManager;
	var selectedMeshData;

	function SetupMeshInfo(mesh: BABYLON.AbstractMesh)
	{
		if (!mesh) return false;
		if (SharedObject.ParsedItemInfoArray == undefined || SharedObject.ParsedItemInfoArray.length <= 0)
		{
			console.log("Failed to setup mesh as item info array is not loaded");
			return false;
		}

		for (let i = 0; i < SharedObject.ParsedItemInfoArray.length; i++)
		{
			if (SharedObject.ParsedItemInfoArray[i].get("mesh") == mesh.name)
			{
				var TempMap: Map<string, string | boolean | number> = SharedObject.ParsedItemInfoArray[i];
				MeshInfoMapMap.set(mesh, TempMap);
				return true;
			}
		}
	}
	function ToggleFilterSystem(IsActive: boolean)
	{
		for (let i = 0; i < POIButtonArray.length; i++)
		{
			POIButtonArray[i].isVisible = IsActive;
		}
		FilterActive = IsActive;
		let FilteredVisibiliity = 0.0;
		if (IsActive)
		{
			FilteredVisibiliity = DefaultFilterVisibility;
			SetupActivePOIArray();
		} else
		{
			FilteredVisibiliity = DefaultVisibility;
			setrPOIArray([]);
		}
		for (let i = 0; i < ApartmentMeshes.length; i++)
		{
			if (HighlightedMesh != ApartmentMeshes[i] && ApartmentMeshes[i] != HoveredMesh)
			{
				ApartmentMeshes[i].visibility = DefaultVisibility;
			}
		}
		for (let i = 0; i < CurrentlyFilteredApartments.length; i++)
		{
			if (HighlightedMesh != CurrentlyFilteredApartments[i] && CurrentlyFilteredApartments[i] != HoveredMesh)
			{
				CurrentlyFilteredApartments[i].visibility = FilteredVisibiliity;
			}
		}
	}

	function UpdateAllFiltered(CurrentFiltered: typeof FilteredSetType)
	{
		UpdateFilterSystem("ADDALL", "", "");
		let SharedSpaces = CurrentFiltered.get("SHAREDSPACES");
		if (SharedSpaces && (SharedSpaces[0] as boolean))
		{
			UpdateFilterSystem("SHAREDSPACES", SharedSpaces[0], SharedSpaces[1]);
			return;
		}
		SharedObject.CurrentFilters.forEach((value, key) =>
		{
			if (value) UpdateFilterSystem(key, value[0], value[1]);
		});
	}
	function RemoveFromFilteredArray(mesh: BABYLON.AbstractMesh)
	{
		for (let i = CurrentlyFilteredApartments.length - 1; i >= 0; i--)
		{
			if (CurrentlyFilteredApartments[i] == mesh)
			{
				CurrentlyFilteredApartments.splice(i, 1);
			}
		}
	}

	function UpdateFilterSystem(ParameterName: string, ParameterMin: number | string | boolean, ParameterMax: number | string | boolean)
	{
		if (ParameterName == "ADDALL")
		{
			if (CurrentlyFilteredApartments != undefined) CurrentlyFilteredApartments = [];
			if (CurrentlyFilteredApartmentsMap != undefined) CurrentlyFilteredApartmentsMap.clear();
			for (let i = 0; i < ApartmentMeshes.length; i++)
			{
				CurrentlyFilteredApartments.push(ApartmentMeshes[i]);
				CurrentlyFilteredApartmentsMap.set(ApartmentMeshes[i].name, ApartmentMeshes[i]);
			}
			return;
		}
		if (ParameterName == "SHAREDSPACES")
		{
			for (let i = 0; i < ApartmentMeshes.length; i++)
			{
				let MeshName = MeshInfoMapMap.get(ApartmentMeshes[i])?.get("mesh") as string;
				if (MeshName != undefined)
				{
					if (MeshName.includes("Shared") != ParameterMin)
					{
						RemoveFromFilteredArray(ApartmentMeshes[i]);
						CurrentlyFilteredApartmentsMap.delete(ApartmentMeshes[i].name);
					}
				}
			}
			if (ParameterMin) return;
		}

		if (typeof ParameterMin === "number" && typeof ParameterMax === "number")
		{
			for (let i = 0; i < ApartmentMeshes.length; i++)
			{
				let tempMeshData = MeshInfoMapMap.get(ApartmentMeshes[i])?.get(ParameterName);
				if (tempMeshData != undefined && tempMeshData != "")
				{
					var NumberData = +tempMeshData;
					if (NumberData != NaN)
					{
						if (NumberData > ParameterMax || NumberData < ParameterMin)
						{
							RemoveFromFilteredArray(ApartmentMeshes[i]);
							CurrentlyFilteredApartmentsMap.delete(ApartmentMeshes[i].name);
						} else
						{
							//console.log(ApartmentMeshes[i]);
						}
					}
				}
			}
		} else if (typeof ParameterMin === "boolean" && typeof ParameterMax === "boolean" && ParameterMin == ParameterMax)
		{
			for (let i = 0; i < ApartmentMeshes.length; i++)
			{
				let tempMeshData = MeshInfoMapMap.get(ApartmentMeshes[i])?.get(ParameterName);
				if (tempMeshData != undefined && tempMeshData != "")
				{
					var BoolData = tempMeshData == "true";
					if (BoolData != undefined)
					{
						if (BoolData != ParameterMin)
						{
							RemoveFromFilteredArray(ApartmentMeshes[i]);
							CurrentlyFilteredApartmentsMap.delete(ApartmentMeshes[i].name);
						}
					}
				}
			}
		} else if (typeof ParameterMin === "string")
		{
			// && typeof ParameterMax === "string" && ParameterMin == ParameterMax)
			for (let i = 0; i < ApartmentMeshes.length; i++)
			{
				let tempMeshData = MeshInfoMapMap.get(ApartmentMeshes[i])?.get(ParameterName) as string;
				if (tempMeshData != undefined)
				{
					const SplitParameters = ParameterMin.split("||");
					if (!SplitParameters.includes(tempMeshData))
					{
						RemoveFromFilteredArray(ApartmentMeshes[i]);
						CurrentlyFilteredApartmentsMap.delete(ApartmentMeshes[i].name);
					}
				}
			}
		}
	}
	//----------------------------Mesh Highlight----------------------------//
	////////////////////////////////////////////////////////////////
	////////////////////////////////////////////////////////////////////////////////////////////////

	function SelectNewHighlightMesh(newMesh: BABYLON.AbstractMesh | undefined)
	{
		if (HighlightedMesh != undefined)
		{
			UpdateMeshMaterial(HighlightedMesh);
			if (CurrentlyFilteredApartmentsMap != undefined && CurrentlyFilteredApartmentsMap?.get(HighlightedMesh.name) != undefined && FilterActive)
			{
				HighlightedMesh.visibility = DefaultFilterVisibility;
			} else
			{
				HighlightedMesh.visibility = DefaultVisibility;
			}
		}
		HighlightedMesh = undefined;
		// new selected mesh colour and opacity
		if (newMesh)
		{
			if (!CurrentlyFilteredApartmentsMap.get(newMesh.name) && FilterActive)
			{
				setrApartmentLabel(<></>);
				return;
			}
			if (newMesh.name.includes("Collision"))
				return;
			SetCurrentlyPickedMesh(newMesh);
			SharedObject.CurrentlyPickedMesh = newMesh;
			HighlightedMesh = newMesh;
			if (HighlightedMesh != undefined)
			{
				if (SharedObject.ItemInfoArray != undefined)
				{
					//console.log(scene.meshUnderPointer.name);
					const apartmentInfo = CreateApartmentLabel(HighlightedMesh, SharedObject.ItemInfoArray);
					if (apartmentInfo !== undefined && apartmentInfo.unit !== "")
					{
						setrApartmentInfo(apartmentInfo);
						setrApartmentLabel(<RecoilApartmentLabel />);
						if (HighlightedMesh !== undefined && SharedObject.MainCamera !== undefined) 
						{
							setApartmentLabelPosition(projectVector3DToCanvas2D(HighlightedMesh.position, SharedObject.MainCamera));
						}
					}
					UpdateMeshMaterial(HighlightedMesh, true);
					HighlightedMesh.visibility = DefaultPickedVisibility;
					//onMeshClickedHandler(findMeshData(HighlightedMesh));
				}
			} else
			{
				setrApartmentLabel(<></>);
			}
			if (engine !== undefined && scene != undefined && SharedObject.MainCamera !== undefined && HighlightedMesh !== undefined)
			{
				var transformMatrix = scene.getTransformMatrix();
				var viewport = SharedObject.MainCamera.viewport.toGlobal(engine.getRenderWidth(), engine.getRenderHeight());
				var coordinates = BABYLON.Vector3.Project(HighlightedMesh.position, BABYLON.Matrix.Identity(), transformMatrix, viewport);
				var localpoint: [number, number] = [coordinates.x, coordinates.y];
				// Offset to left as per figma design (not covering building)
				//TODO: MINUS HALF HEIGHT SO CENTER OF LABEL IS INLINE WITH APARTMENT
				//var CanvasRect = document.getElementById("BabylonCanvas")?.getBoundingClientRect();
				if (SharedObject.CanvasRect)
				{
					localpoint[0] += SharedObject.CanvasRect.left;
					localpoint[1] += SharedObject.CanvasRect.top;
				}
				//console.log(localpoint);
				//SharedObject.ItemPoint = localpoint;
				setItemPoint(localpoint);
				//var coordinates = BABYLON.Vector3.Project(HighlightedMesh.position, BABYLON.Matrix.Identity(), scene.getTransformMatrix(), DJICamera.viewport.toGlobal(engine.getRenderWidth(),engine.getRenderHeight()));
				//console.log(coordinates);
			}
		}
	}

	//----------------------------Render----------------------------//
	let LabelLocation = useRecoilValue(ApartmentLabelPosition);

	function CheckPOIVisibility(scene: Scene)
	{
		if (SharedObject.FiltersEnabled && SharedObject.MainCamera && scene)
		{
			for (let i = 0; i < POIPointArray.length; i++)
			{
				var CamToPointDis = POIPointArray[i].position.subtract(SharedObject.MainCamera.position).length();
				var CamToPintDir = POIPointArray[i].position.subtract(SharedObject.MainCamera.position).normalize();
				var CamToPointRay = new BABYLON.Ray(SharedObject.MainCamera.position, CamToPintDir, (CamToPointDis));
				var hit = scene.pickWithRay(CamToPointRay);
				if (hit && hit.pickedMesh)
				{
					if (hit.pickedMesh !== POIPointArray[i])
					{
						if (POIButtonArray.length > i)
						{
							if (POIButtonArray[i].isVisible === true)
							{
								POIButtonArray[i].isVisible = false;
							}
						}
					}
				}
				else
				{
					if (POIButtonArray.length > i)
					{
						if (POIButtonArray[i].isVisible === false)
						{
							POIButtonArray[i].isVisible = true;
						}
					}
				}
			}
		}
	}
	//Will run on every frame render.  We are spinning the box on y-axis//
	const onRender = (scene: Scene) =>
	{
		if (SharedObject.hasParsedItemInfoArray && scene && scene.isReady() && !ModelSetup)
		{
			LoadModel(scene);
			CheckPOIVisibility(scene);
		}
		if (SharedObject.FiltersEnabled != FilterActive || SharedObject.FiltersWereUpdated)
		{
			UpdateAllFiltered(SharedObject.CurrentFilters);
			ToggleFilterSystem(SharedObject.FiltersEnabled);
			CheckPOIVisibility(scene);
			SharedObject.FiltersWereUpdated = false;
		}
		if (SharedObject.PreviousFrame !== SharedObject.RecoilCurrentFrame)
		{
			prop.SetPreviousFrame(SharedObject.RecoilCurrentFrame);
			// if (HighlightedMesh !== undefined && SharedObject.MainCamera !== undefined) 
			// {
			// 	setApartmentLabelPosition(projectVector3DToCanvas2D(HighlightedMesh.position, SharedObject.MainCamera));
			// }

			CheckPOIVisibility(scene);
			//console.log(LabelLocation);
		}
		onPointerMoved(scene);
	};
	function onPointerMoved(scene: Scene)
	{
		function predicate(mesh: AbstractMesh)
		{
			if (mesh.isEnabled() && mesh.isPickable)
			{
				return true;
			}
			return false;
		}

		function UnhoverMesh(mesh: BABYLON.AbstractMesh | undefined)
		{
			if (mesh && !mesh.name.includes("Collision"))
			{
				if (HighlightedMesh !== mesh)
				{
					// Default colour and opacity
					UpdateMeshMaterial(mesh);
					if (CurrentlyFilteredApartmentsMap !== undefined && CurrentlyFilteredApartmentsMap?.get(mesh.name) !== undefined && FilterActive)
					{
						mesh.visibility = DefaultFilterVisibility;
					} else
					{
						mesh.visibility = DefaultVisibility;
					}
				}
			}
		}

		function HoverOverMesh(mesh: BABYLON.AbstractMesh)
		{

			if (!CurrentlyFilteredApartmentsMap.get(mesh.name) && FilterActive)
			{
				return;
			}
			// Increase opacity when hovered
			if (SharedObject.CurrentlyPickedMesh !== undefined)
			{
				if (mesh !== SharedObject.CurrentlyPickedMesh)
				{
					SelectNewHighlightMesh(mesh);
				}
			} else
			{
				if (mesh !== HighlightedMesh && !SharedObject.IsPointerDown)
				{
					mesh.visibility = 0.9;
				}
			}
		}

		let PickedInfo = scene.pick(MousePosition.current[0], MousePosition.current[1], predicate, false, SharedObject.MainCamera)
		if (PickedInfo)
		{
			let PickedMesh = PickedInfo.pickedMesh;
			if (PickedMesh === null)
			{
				UnhoverMesh(HoveredMesh);
				HoveredMesh = undefined;
			}
			else if (PickedMesh !== HoveredMesh && PickedMesh !== null)
			{
				if (ApartmentMeshMap.get(PickedMesh.name))
				{
					HoverOverMesh(PickedMesh);
					UnhoverMesh(HoveredMesh);
					HoveredMesh = PickedMesh;
				}
			}
		}
		else
		{
			UnhoverMesh(HoveredMesh);
			HoveredMesh = undefined;
		}
	}
	function ClosePOI()
	{
		SharedObject.POIOpened = false;
		setPOIActive(SharedObject.POIOpened);
	}

	return <></>;
};


export default React.memo(ExteriorManager);
