import React, { useEffect, useState } from "react";
import { Button, SxProps } from "@mui/material";

const FilterButtonComponent = (prop: {
	ButtonName: string,
	FilterName: string,
	ButtonType: ButtonType;
	FilterStateDispatch: React.Dispatch<FiltersAction>,
	StateOnClick?: boolean | number,
	FiltersToDisable?: string[],
	FiltersToEnable?: string[],
	sx?: SxProps,
	style?: React.CSSProperties,
}) =>
{
	let LocalBoolStateOnClick = true;
	if (prop.StateOnClick != undefined && typeof prop.StateOnClick === 'boolean')
	{
		LocalBoolStateOnClick = prop.StateOnClick;
	}
	let LocalFiltersToDisable = [];
	if (prop.FiltersToDisable != undefined)
	{
		LocalFiltersToDisable = prop.FiltersToDisable;
	}

	let LocalFiltersToEnable = [];
	if (prop.FiltersToEnable != undefined)
	{
		LocalFiltersToEnable = prop.FiltersToEnable;
	}

	function ButtonClicked()
	{
		switch (prop.ButtonType)
		{
			case "boolupdate":
				prop.FilterStateDispatch(
					{
						type: 'boolUpdate',
						payload: {
							FilterName: prop.FilterName,
							State: LocalBoolStateOnClick,
							DisableStates: LocalFiltersToDisable,
							EnableStates: LocalFiltersToEnable,
						}
					});
				break;
			case "reset":
				prop.FilterStateDispatch({ type: 'reset' });
				break;
			case "increment":
				prop.FilterStateDispatch({ type: 'increment', payload: { FilterName: prop.FilterName } });
				break;
			case "decrement":
				prop.FilterStateDispatch({ type: 'decrement', payload: { FilterName: prop.FilterName } });
				break;
			case "numupdate":
				if (prop.StateOnClick != undefined && typeof prop.StateOnClick === 'number')
				{
					prop.FilterStateDispatch({ type: 'numUpdate', payload: { FilterName: prop.FilterName, NumberState: prop.StateOnClick } })
				}
				break;
			default:
				throw new Error('Unknown Button type: ${prop.ButtonType}');
		}
	}

	let FilterButton = <Button style={prop.style} variant="text" size="large" sx={prop.sx} onClick={ButtonClicked}> {prop.ButtonName} </Button>;
	return FilterButton;
};

type PropType =
	{
		ButtonName: string,
		FilterName: string,
		ButtonType: ButtonType;
		FilterStateDispatch: React.Dispatch<FiltersAction>,
		StateOnClick?: boolean,
		FiltersToDisable?: string[],
		FiltersToEnable?: string[],
		sx?: SxProps,
	};

function PropCheck(prevProp: PropType, newProp: PropType)
{
	if (prevProp.ButtonName === newProp.ButtonName
		&& prevProp.ButtonType === newProp.ButtonType
		&& prevProp.FilterName === newProp.FilterName
		&& prevProp.FilterStateDispatch == newProp.FilterStateDispatch
		&& prevProp.StateOnClick === newProp.StateOnClick
		&& prevProp.sx === newProp.sx)
	{
		if (!(prevProp.FiltersToDisable === newProp.FiltersToDisable))
		{
			let IsSame = false;
			if (prevProp.FiltersToDisable != undefined && newProp.FiltersToDisable != undefined && prevProp.FiltersToDisable.length === newProp.FiltersToDisable.length)
			{
				IsSame = true;
				for (let i = 0; i < newProp.FiltersToDisable.length; i++)
				{
					if (!(prevProp.FiltersToDisable[i] === newProp.FiltersToDisable[i]))
					{
						return false;
					}
				}
			}
			return IsSame
		}
		if (!(prevProp.FiltersToEnable === newProp.FiltersToEnable))
		{
			let IsSame = false;
			if (prevProp.FiltersToEnable != undefined && newProp.FiltersToEnable != undefined && prevProp.FiltersToEnable.length === newProp.FiltersToEnable.length)
			{
				IsSame = true;
				for (let i = 0; i < newProp.FiltersToEnable.length; i++)
				{
					if (!(prevProp.FiltersToEnable[i] === newProp.FiltersToEnable[i]))
					{
						return false;
					}
				}
			}
			return IsSame
		}
		return true;
	}
	return false;
}

export const MemoFilterButton = React.memo(FilterButtonComponent, PropCheck);