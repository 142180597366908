import { Button, ImageList, ImageListItem } from "@mui/material";
import React from "react";
import theme from "../theme/theme";
import GalleryTileInfo from "../Core/Types/GalleryTypes"
const TileGallery = (prop: { ContentTiles: typeof GalleryTileInfo[], OverrideMinColumnWidth?: number, SetClickedTile?: React.Dispatch<React.SetStateAction<[number, typeof GalleryTileInfo, typeof GalleryTileInfo[]]>> | undefined, style?: {}, StopForcedAspectRatio?: boolean }) =>
{
	let OverflowYSetting: string = "hidden";
	let AspectRatioHeight: string = "16/9";
	if (prop.StopForcedAspectRatio === true)
	{
		OverflowYSetting = "auto";
		AspectRatioHeight = "auto";
	}
	let MinColumnWidth = 500;
	if (prop.OverrideMinColumnWidth)
	{
		MinColumnWidth = prop.OverrideMinColumnWidth;
	}
	let MinWidthString = MinColumnWidth.toString() + "px";
	{
		return <ImageList variant="woven" style={prop.style} sx={{
			width: "100%", overflowY: OverflowYSetting,
			gridTemplateColumns: "repeat(auto-fit, minmax(" + MinWidthString + ", 1fr)) !important",
			position: "relative",
			// [theme.breakpoints.up('xs')]: {
			// 	gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr)) !important",
			// 	backgroundColor: "white",
			// },
			// [theme.breakpoints.up('sm')]: {
			// 	gridTemplateColumns: "repeat(auto-fit, minmax(200px, 1fr)) !important",
			// 	backgroundColor: "blue"
			// },
			// [theme.breakpoints.up('md')]: {
			// 	gridTemplateColumns: "repeat(auto-fit, minmax(250px, 1fr)) !important",
			// 	backgroundColor: "green"
			// },
			// [theme.breakpoints.up('lg')]: {
			// 	gridTemplateColumns: "repeat(auto-fit, minmax(300px, 1fr)) !important",
			// 	backgroundColor: "orange"
			// },
			// [theme.breakpoints.up('xl')]: {
			// 	gridTemplateColumns: "repeat(auto-fill, minmax(550px, 1fr)) !important",
			// 	backgroundColor: "red"
			// },
		}} cols={3}>
			{/* rowHeight={200} */}
			{/* <ImageListItem sx={{
		position: "relative",
		width: "100%",
		paddingTop: "56.25%",
		overflow: "hidden",
	}}>
		<video autoPlay muted loop controls src="https://iris01-image-storage.s3.ap-southeast-2.amazonaws.com/Video/IRIS01_Albert+Type+D.mp4" style={{
			position: "absolute",
			top: 0,
			bottom: 0,
			right: 0,
			left: 0,
			width: "100%",
			height: "100%",
		}}>
			Your browser does not support the video tag.
		</video>
	</ImageListItem> */}
			{prop.ContentTiles.map((item, index) => (
				<Button key={item.url} sx={{ padding: 0 }} onClick={() =>
				{
					prop.SetClickedTile([index, item, prop.ContentTiles]);
				}}>
					{/* // For some reason by default, the even list items have 70% height */}
					<ImageListItem key={item.url} sx={{
						position: "relative",
						width: "100%",
						aspectRatio: AspectRatioHeight,
					}}>
						{item.type == "image" ?
							<img
								src={item.url}
								srcSet={item.url}
								alt={item.name}
								loading="lazy"
								style={{
									position: "relative",
								}} />
							:
							item.type == "video" ?
								<video autoPlay muted loop
									style={{
										position: "absolute",
										top: 0,
										bottom: 0,
										right: 0,
										left: 0,
										width: "100%",
										height: "100%",
									}}>
									<source src={item.url} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
								:
								item.type == "iframe" ?
									<></>
									:
									<></>}
						{/* height: "100% !important" */}


					</ImageListItem>
				</Button>
			))}
		</ImageList>;
	}
}

export default TileGallery;


/*
						{item.type == "image" ?
							<img
								src={`${item.url}?w=1920&h=1080&fit=fit&auto=format`}
								srcSet={`${item.url}?w=1920&h=1080&fit=fit&auto=format&dpr=2 2x`}
								alt={item.name}
								loading="lazy"
								style={{
									position: "relative",
								}} />
							:
							item.type == "video" ?
								<video autoPlay muted loop
									// src={`${item.url}?w=1920&h=1080&fit=fit&auto=format`}
									style={{
										position: "absolute",
										top: 0,
										bottom: 0,
										right: 0,
										left: 0,
										width: "100%",
										height: "100%",
									}}>
									<source src={`${item.url}?w=1920&h=1080&fit=fit&auto=format#t=10`} type="video/mp4" />
									Your browser does not support the video tag.
								</video>
								:
								item.type == "iframe" ?
									<></>
									:
									<></>}*/