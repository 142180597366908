// Import FirebaseAuth and firebase.
import React, { useEffect, useState } from 'react';
//import StyledFirebaseAuth from 'react-firebaseui';
import StyledFirebaseAuth from 'react-firebaseui/StyledFirebaseAuth';
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { useSetRecoilState } from 'recoil';
import { firebaseSignedIn } from 'recoil/atoms';
import AuthLayout from './AuthLayout';
import { useSearchParams } from "react-router-dom";
import { FirebaseAuth } from 'react-firebaseui';
import './firebaseui-styling.global.css'; // Import globally. Not with CSS modules.


// Configure FirebaseUI.
const uiConfig = {
	// Popup signin flow rather than redirect flow.
	signInFlow: 'popup',
	// We will display Google and Facebook as auth providers.
	signInOptions: [
		{
			provider: firebase.auth.EmailAuthProvider.PROVIDER_ID,
			requireDisplayName: false,
			disableSignUp: {
				// Disable user from signing up with email providers.
				status: false,
			}
		},
	],
	callbacks: {
		// Avoid redirects after sign-in.
		signInSuccessWithAuthResult: () => false,
	},
};


export function AuthPage()
{
	const setIsSignedIn = useSetRecoilState(firebaseSignedIn); // Local signed-in state.
	const [LoginStateLoaded, setLoginStateLoaded] = useState(!!firebase.auth().currentUser); // Local signed-in state.

	// Listen to the Firebase Auth state and set the local state.
	useEffect(() =>
	{
		const unregisterAuthObserver = firebase.auth().onAuthStateChanged(user =>
		{
			setIsSignedIn(!!user);
			setLoginStateLoaded(true);
		});
		return () => unregisterAuthObserver(); // Make sure we un-register Firebase observers when the component unmounts.
	}, []);

	return (
		<div>
			{
				LoginStateLoaded ?
					<AuthLayout>
						< FirebaseAuth uiConfig={uiConfig} firebaseAuth={firebase.auth()} />
					</AuthLayout >
					:
					<></>
			}
		</div>

	);

}

//export default AuthPage;
// return (
//     <div>
//         <h1>My App</h1>
//         <p>Welcome {firebase.auth().currentUser.displayName}! You are now signed-in!</p>
//         <a onClick={() => firebase.auth().signOut()}>Sign-out</a>
//     </div>
// );