import { Button, Drawer, MenuItem, Select, SelectChangeEvent, styled, SwipeableDrawer, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import React, { ReactNode, useEffect, useContext } from "react";
import BackIcon from "Assets/icon-arrow-left.svg";
import { ExteriorContext } from "../ExteriorContext";
import { tLevel, tUnitType, UnitTypeKeys } from "components/Types";

const drawerWidth = "25%";

const DrawerHeader = styled("div")(({ theme }) => ({
	display: "flex",
	alignItems: "flex-start",
	padding: theme.spacing(8, 8),
	//necessary for content to be below app bar
	...theme.mixins.toolbar,
	justifyContent: "flex-start",
	// height: '100%',
}));

const tBuilding =
{
	name: "",
	description: ""
}

//const BuildingType = BuildingsJsonTemplate.Buildings[0];
// const LevelType = BuildingsJsonTemplate.Levels[0];
// const UnitType = BuildingsJsonTemplate.Types[0];

const BasicNavPanel = (prop: {}) =>
{
	const { CMSData: CMSDataJson, setCMSData: setBuildingsJson } = useContext(ExteriorContext);
	const { ItemInfoArray } = useContext(ExteriorContext);


	const [open, setOpen] = React.useState(true);
	const [isDrawerLinkOpen, setIsDrawerLinkOpen] = React.useState<boolean>(false);
	const [UnitLink, setUnitLink] = React.useState<string>("");
	const [CurrentLevel, setLevel] = React.useState("");
	const [CurrentBuilding, setBuilding] = React.useState("");
	const [BuildingsMap, setBuildingsMap] = React.useState<Map<string, typeof tBuilding>>(new Map<string, typeof tBuilding>());
	const [TypesMap, setTypesMap] = React.useState<Map<string, typeof tUnitType>>(new Map<string, typeof tUnitType>());

	// FIXME: DOES NOT WORK USING 'CURRENT LEVEL'
	const CorrectLevelString = parseInt(CurrentLevel).toString();

	// let BuildingsMap = new Map<string, typeof BuildingType>();
	// let CurrentLevelsMap = new Map<string, typeof LevelType >();
	const [CurrentLevelsMap, setCurrentLevelsMap] = React.useState(new Map<string, typeof tLevel>());

	const [BuildingElements] = React.useState<ReactNode[]>([]);
	const [UnitElements] = React.useState<ReactNode[]>([]);

	// let BuildingElements: ReactNode[] = [];
	// let UnitElements: ReactNode[] = [];

	const toggleDrawer = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) =>
	{
		if (event && event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift"))
		{
			console.log(event);
			return;
		}
		setIsDrawerLinkOpen(open);
	};


	useEffect(() =>
	{
		if (CMSDataJson === undefined)
			return;
		BuildingElements.splice(0, BuildingElements.length);
		BuildingsMap.clear();
		if (CMSDataJson["Buildings"])
		{
			CMSDataJson["Buildings"].forEach((Building) =>
			{
				BuildingsMap.set(Building.name, Building);
				// if (CurrentLevel == "")
				PopulateBuildings(Building);
			});

		}

		TypesMap.clear();
		CMSDataJson.Types.forEach((UnitType) =>
		{
			TypesMap.set(UnitType.unitName, UnitType);
		});

	}, [CMSDataJson]);



	const handleLevelChange = (event: SelectChangeEvent) =>
	{
		setBuilding(event.target.name);
		const Building = BuildingsMap.get(event.target.name);
		//console.log("Adding levels map");

		CurrentLevelsMap.clear();
		if (CMSDataJson === undefined)
			return;
		for (let i = 0; i < CMSDataJson.Levels.length; i++)
		{
			if (CMSDataJson.Levels[i].building == Building.name)
				CurrentLevelsMap.set(CMSDataJson.Levels[i].level.toString(), CMSDataJson.Levels[i]);
		}

		// Building?.Levels.forEach((Level) =>
		// {
		// 	CurrentLevelsMap.set(Level.Level.toString(), Level);
		// 	//console.log("Adding " + Level.Level.toString());
		// 	//console.log(Level);
		// });
		setLevel(event.target.value as string);
	};
	const BackClicked = () =>
	{
		if (CurrentBuilding != "")
		{
			setBuilding("");
			setLevel("");
		}
	};


	function PopulateBuildings(BuildingJSON: typeof tBuilding)
	{
		let LevelMenuItemElements: ReactNode[] = [];
		if (CMSDataJson === undefined)
			return;
		for (let i = 0; i < CMSDataJson.Levels.length; i++)
		{
			if (CMSDataJson.Levels[i].building == BuildingJSON.name)
			{
				let NewMenuItem = <MenuItem key={CMSDataJson.Levels[i].level} value={CMSDataJson.Levels[i].level}>{CMSDataJson.Levels[i].level}</MenuItem>;
				LevelMenuItemElements.push(NewMenuItem);
			}
		}
		// BuildingJSON.Levels.forEach((Level) =>
		// {
		// 	let NewMenuItem = <MenuItem key={Level.Level} value={Level.Level}>{Level.Level}</MenuItem>;
		// 	LevelMenuItemElements.push(NewMenuItem);
		// });
		BuildingElements.push(
			<Typography key={BuildingJSON.name} variant="h2" fontSize={40} fontWeight={600} color={"#00263A"} paddingTop={8}>
				{BuildingJSON.name}
			</Typography>
		);
		BuildingElements.push(
			<Typography key={BuildingJSON.name + "-description"} variant="body1" paddingTop={2} fontSize={20} fontWeight={500} color={"#00263A"}>
				{BuildingJSON.description}
			</Typography>
		);
		BuildingElements.push(
			<Box key={BuildingJSON.name + "-Select"} paddingTop={2}>
				<Select
					id="building-level-select"
					value={CurrentLevel}
					name={BuildingJSON.name}
					defaultValue="Select Floor"
					// label="Level"
					fullWidth
					onChange={handleLevelChange}
					sx={{
						backgroundColor: "#FFFFFF",
						borderRadius: 1,
						"& .MuiSelect-icon": {
							color: "#00263A",
							width: 32,
							height: 32,
						},
					}}>
					{LevelMenuItemElements}
				</Select>
			</Box>
		);
	}

	const UnitSelected = (UnitObject: typeof tUnitType) => (event: React.KeyboardEvent | React.MouseEvent) =>
	{
		if (event && event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift"))
		{
			console.log(event);
			return;
		}
		// TODO: UPDATE WITH UNIT PAGE
		// setUnitLink(UnitObject.Link);
		setIsDrawerLinkOpen(open);
	};

	function PopulateUnits()
	{
		const UnitButtonStyle = {
			//position: 'absolute'
			fontSize: 20,
			color: "#00263A",
		};
		UnitElements.splice(0, UnitElements.length);
		if (CMSDataJson === undefined)
			return;
		// FIXME: INNEFFECIENT, should store levels for unit types
		let AddedUnits: string[] = [];
		for (let i = 0; i < CMSDataJson?.Units.length; i++)
		{
			if (CurrentLevelsMap.has(CMSDataJson.Units[i].level.toString()))
			{
				const UnitObject = TypesMap.get(CMSDataJson.Units[i].type);
				if (UnitObject == undefined || AddedUnits.includes(UnitObject.unitName))
					continue;
				AddedUnits.push(UnitObject.unitName);
				UnitElements.push(
					<Button key={UnitObject.unitName} variant="outlined" style={{ fontSize: 20, color: "#00263A", borderRadius: 0, borderColor: "#000000", padding: 18, marginTop: 25, marginRight: 20 }} onClick={UnitSelected(UnitObject)}>
						{/* href={UnitObject.Link}> */}
						{UnitObject.unitName}
					</Button>
				);
			}

		}
		// CurrentLevelsMap.get(CorrectLevelString)?.Units.forEach((Unit) =>
		// {
		// 	const UnitObject: typeof UnitType = GetUnitObject(Unit);
		// 	UnitElements.push(
		// 		<Button key={UnitObject.UnitName} variant="outlined" style={{ fontSize: 20, color: "#00263A", borderRadius: 0, borderColor: "#000000", padding: 18, marginTop: 25, marginRight: 20 }} onClick={UnitSelected(UnitObject)}>
		// 			{/* href={UnitObject.Link}> */}
		// 			{UnitObject.UnitName}
		// 		</Button>
		// 	);
		// });
	}

	// function GetUnitObject(UnitName: string)
	// {
	// 	for (let i = 0; i < BuildingsJson.Units.length; i++)
	// 	{
	// 		const UnitObject = BuildingsJson.Units[i];
	// 		///console.log("Checking " + UnitObject.UnitName + " vs " + UnitName )
	// 		if (UnitObject.UnitName == UnitName)
	// 		{
	// 			//console.log("Found " + UnitName);

	// 			return UnitObject;
	// 		}
	// 	}
	// 	console.log("None found for " + UnitName);
	// 	//FIXME: Will use default/first element, no check is done outside this function
	// 	return UnitType;
	// }

	// DOESN"T UPDATE RENDER???
	// useEffect(() =>
	// {
	// 	if (CurrentLevel)
	// 	{
	// 		PopulateUnits();
	// 	}
	// }, [CurrentLevel]);

	if (CurrentLevel)
	{
		PopulateUnits();
	}

	///console.log("Current Level: ");
	//console.log(CurrentLevel);
	//console.log("Levels Map:");
	//console.log(CurrentLevelsMap);
	//console.log(CurrentLevelsMap.get(CorrectLevelString));

	const StyledBackButton = styled(Button)({
		fontSize: 20,
		color: "#000000",
		textTransform: "none",
		marginBottom: 40,
	});

	return (
		<Box component="div" sx={{ display: "flex" }}>
			{/* <CssBaseline /> */}
			<Drawer
				sx={{
					width: drawerWidth,
					flexShrink: 0,
					"& .MuiDrawer-paper": {
						width: drawerWidth,
						boxSizing: "border-box",
						background: "rgba(202, 214, 220, 0.6)",
						backdropFilter: "blur(23px)",
						borderWidth: 0,
					},
				}}
				variant="persistent"
				anchor="right"
				open={open}>
				<DrawerHeader>
					{CurrentLevel == "" ? (
						<div>
							{/* Building Selection */}
							<Typography variant="h1" fontSize={"3.8em"} fontWeight={700} color={"#00263A"}>
								EXPLORE
							</Typography>
							<Typography variant="body1" fontSize={20} fontWeight={500} paddingTop={3} fontStyle={"normal"} color={"#00263A"}>
								Situated at BroadBeach on the Gold Coast, Victoria & Albert comprising two spectacular residential towers, Victoria Residences and Albert Residences, a world-class luxury recreational podium, sky residences, private owners-only amenity, two levels of premium office space and commercial facilities and a vibrant new retail arcade.
							</Typography>

							<Box paddingTop={0}>{BuildingElements}</Box>
						</div>
					) : (
						<div>
							<StyledBackButton startIcon={<img style={{ width: 32 }} src={BackIcon} alt="Back Icon" />} variant="text" onClick={BackClicked}>
								Back
							</StyledBackButton>
							<Typography variant="h2" fontSize={40} fontWeight={700} color={"#00bfbf"}>
								{CurrentBuilding}
							</Typography>
							<Typography variant="h3" fontSize={50} fontWeight={700} color={"#00263A"}>
								FLOOR {CurrentLevel}
							</Typography>
							<Typography variant="body1" paddingTop={3} fontSize={20} fontWeight={500} color={"#00263A"}>
								{CurrentLevelsMap.get(CorrectLevelString)?.description}
							</Typography>
							<Typography variant="h4" paddingTop={4} fontSize={28} fontWeight={700} fontStyle={"normal"} color={"#00263A"}>
								Select a unit to show details.
							</Typography>
							{UnitElements}
						</div>
					)}
					{/* <IconButton onClick={handleDrawerClose}>{theme.direction === 'ltr' ? <ChevronLeftIcon /> : <ChevronRightIcon />}</IconButton> */}
				</DrawerHeader>
			</Drawer>
			<SwipeableDrawer anchor={"bottom"} open={isDrawerLinkOpen} onClose={toggleDrawer(false)} onOpen={toggleDrawer(true)}>
				<Box component="div" sx={{ width: "auto", height: "1440px" }} style={{ overflow: "hidden" }} onClick={toggleDrawer(false)} onKeyDown={toggleDrawer(false)}>
					<iframe title={"ExploreBox"} height={"100%"} width={"100%"} src={UnitLink}></iframe>
				</Box>
			</SwipeableDrawer>
		</Box>
	);
};
export default BasicNavPanel;
