import BrandedBackground, { Wrapper } from "BrandedBackground";
import React, { useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Page from './Page';
import { LogOutButton } from "./LogOutButton";
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import { firebaseSignedIn } from "recoil/atoms";
import { useSetRecoilState } from "recoil";


const validationSchema = yup.object({
  newEmail: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
});

const ChangeEmailPage = (): JSX.Element =>
{
  const [hasSentUpdateEmailRequest, setHasSentUpdateEmailRequest] = useState<boolean>(false);
  const [hasError, setHasError] = useState<boolean>(false);
  const [prompt, setPrompt] = useState<string>("Enter a new email address");
  const initialValues = { newEmail: '' };
  const setIsSignedIn = useSetRecoilState(firebaseSignedIn); // Local signed-in state.
  const onSubmit = (values) =>
  {
    firebase.auth().currentUser.verifyBeforeUpdateEmail(values.newEmail)
      .then(function ()
      {
        // Verification email sent.
        setPrompt("Verification email sent. When the user clicks the email link, this account will be updated to " + values.newEmail);
        setHasSentUpdateEmailRequest(true);
      })
      .catch(function (error)
      {
        setHasError(true);
        // Error occurred. Inspect error.code.
        switch (error.code)
        {
          case "auth/email-already-in-use":
            setPrompt("This email address is already in use");
            break;
          case "auth/invalid-email":
            setPrompt("This email address is not valid");
            break;
          case "auth/requires-recent-login":
            setPrompt("Login token has expired and requires re-authentication");
            firebase.auth().signOut().then(
              () => { setIsSignedIn(false); }
            );
            break;
          default:
            console.log(error.code);
            setPrompt("Error sending request, please try again");
            break;
        }
      });
    return values;
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });
  function UpdateEmailForm(formik)
  {
    return hasSentUpdateEmailRequest
      ?
      <Typography
        variant={'subtitle2'}
        sx={{ marginBottom: 2 }}
        fontWeight={700}
      >
        {prompt}
      </Typography>
      :
      <form onSubmit={formik.handleSubmit}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
              color={hasError ? "red" : "black"}
            >
              {prompt}
            </Typography>
            <TextField
              variant="outlined"
              name={'newEmail'}
              fullWidth
              value={formik.values.newEmail}
              onChange={formik.handleChange}
              error={formik.touched.newEmail &&
                Boolean(formik.errors.newEmail)}
              // @ts-ignore
              helperText={formik.touched.newEmail &&
                formik.errors.newEmail} />
          </Grid>
          <Grid item xs={12}>
            <Divider />
          </Grid>
          <Grid item container xs={12}>
            <Box
              display="flex"
              flexDirection={{ xs: 'column', sm: 'row' }}
              alignItems={{ xs: 'stretched', sm: 'center' }}
              justifyContent={'space-between'}
              width={1}
              margin={'0 auto'}
            >
              <Box marginBottom={{ xs: 1, sm: 0 }}>

              </Box>
              <Button size={'large'} variant={'contained'} type={'submit'} color={hasError ? "error" : "primary"}>
                Update Email
              </Button>
            </Box>
          </Grid>
        </Grid>
      </form>;
  }
  return (
    <Page>
      <Box>
        <Box
          display={'flex'}
          flexDirection={{ xs: 'column', md: 'row' }}
          justifyContent={'space-between'}
          alignItems={{ xs: 'flex-start', md: 'center' }}
        >
          <Typography variant="h6" fontWeight={700} >
            {"Change your email: "}
          </Typography>
          <Typography variant="subtitle2" fontWeight={700} color="grey">
            {firebase.auth().currentUser.email}
          </Typography>
          {<LogOutButton />}
        </Box>
        <Box paddingY={4}>
          <Divider />
        </Box>
        {UpdateEmailForm(formik)}
      </Box>
    </Page>

  );
};

export default ChangeEmailPage;
