import { Container } from "@mui/system";
import Image from "components/Image";
import { ExteriorContextProvider } from "Exterior/ExteriorContext";
import { FilterBarMemo } from "Exterior/Panel/FilterBar";
import { useRecoilValue } from "recoil";
import { rMiscData, rUnitData } from "recoil/atoms";
import { useEffect, useReducer, useRef, useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import UnitLabel from "components/UI/UnitLabel";

interface UnitTileData
{
	Name: string,
	Level: string,
	HasExplore: boolean,
	ClickCallback: React.Dispatch<{
		type: 'Clicked';
		payload:
		{
			UnitName: string;
			UnitType: string;
		};
	}>,
	TypeAndIcons: [string, string],
	InfoAndIcons: [string, string, string][],
	InfoTable: [string, string, number, boolean, boolean][],
}
export default function UnitsTilesPage()
{
	const UnitsData = useRecoilValue(rUnitData);
	const MiscData = useRecoilValue(rMiscData);


	const [AllUnitData, setAllUnitData] = useState<UnitTileData[]>([])


	function ParseUnitData(Data: Object[])
	{

	}
	const ButtonReducer = (state: {},
		action: {
			type: 'Clicked',
			payload:
			{
				UnitName: string,
				UnitType: string,
			}
		}) =>
	{
		switch (action.type)
		{
			case 'Clicked':
				return { ["LastClickedButtonUnit"]: action.payload.UnitName, ["LastClickedButtonType"]: action.payload.UnitType };
			default:
				break;
		}

	}

	const InitialState = {
		LastClickedButtonUnit: "",
		LastClickedButtonType: ""
	}



	const BackGroundImageURL: string = GetBackgroundImageURL();
	let FilterBarRef = useRef();

	const [ButtonCallbacks, DispatchButtonCallback] = useReducer(ButtonReducer, InitialState);

	useEffect(() =>
	{

	}, [ButtonCallbacks])
	useEffect(() =>
	{
		console.log(UnitsData);
	}, [UnitsData])



	function GetBackgroundImageURL()
	{
		let BackgroundURL = "";
		if (MiscData)
		{
			for (let i = 0; i < MiscData.length; i++)
			{
				if (MiscData[i].hasOwnProperty("page"))
				{
					if (MiscData[i]["page"] === "UnitTiles")
					{
						if (MiscData[i].hasOwnProperty("backgroundImage"))
						{
							if ((MiscData[i]["backgroundImage"] as Array<any>).length > 0)
							{
								BackgroundURL = MiscData[i]["backgroundImage"][0]["downloadURL"];
							}
						}
					}
				}
			}
		}
		return BackgroundURL;
	}


	let TestUnitIconURLArray: [InfoType: string, IconURL: string, Value: string][] =
		[
			["Bedroom", "https://realspace-core-iris.pages.dev/images/62cd561d4760d4b750dd508f_Unit-Bed.svg", "3"],
			["Bathroom", "https://realspace-core-iris.pages.dev/images/62cd561d5537f7324523b0fb_Unit-Bath.svg", "2"],
			["Bathroom", "https://realspace-core-iris.pages.dev/images/62cd561d5537f7324523b0fb_Unit-Bath.svg", "2"],
			["Bathroom", "https://realspace-core-iris.pages.dev/images/62cd561d5537f7324523b0fb_Unit-Bath.svg", "2"],
			["Bathroom", "https://realspace-core-iris.pages.dev/images/62cd561d5537f7324523b0fb_Unit-Bath.svg", "2"],
			["Bathroom", "https://realspace-core-iris.pages.dev/images/62cd561d5537f7324523b0fb_Unit-Bath.svg", "2"],
			["Bathroom", "https://realspace-core-iris.pages.dev/images/62cd561d5537f7324523b0fb_Unit-Bath.svg", "2"],
			["Bathroom", "https://realspace-core-iris.pages.dev/images/62cd561d5537f7324523b0fb_Unit-Bath.svg", "2"],
			["Bathroom", "https://realspace-core-iris.pages.dev/images/62cd561d5537f7324523b0fb_Unit-Bath.svg", "2"],
			// ["Carpark", "https://upload.wikimedia.org/wikipedia/commons/5/5a/Car_icon_alone.png", "2"],
		];
	let TestUnitInfoTableArray: [InfoType: string, Value: string, FontSize: number, Bold: boolean, Italic: boolean][] =
		[
			["Internal", "153 m²", 1, false, false],
			["External", "19 m²", 1, false, false],
			["Total Area", "172 m²", 1, true, false],
		];
	const GridItemZIndex = 100;
	return (
		<Box>
			<ExteriorContextProvider>
				<Grid container spacing={7} columns={20} sx={{ zIndex: GridItemZIndex, position: "absolute", padding: "4rem", width: "85vw" }}>
					<Grid item xs={20} sm={10} md={6} lg={5} sx={{}}>
						<UnitLabel key={1} UnitName="Albert Unit" UnitLevel="99" HasExploreButton={true} OnClickDispatch={DispatchButtonCallback}
							UnitTypeAndIconURL={["C", "https://realspace-core-iris.pages.dev/images/62cd561d4760d4b750dd508f_Unit-Bed.svg"]} UnitInfoAndIcons={TestUnitIconURLArray} UnitInfoTable={TestUnitInfoTableArray} />
					</Grid>
					<Grid item xs={20} sm={10} md={6} lg={5} sx={{}}>
						<UnitLabel key={2} UnitName="Albert Unit" UnitLevel="99" HasExploreButton={true} OnClickDispatch={DispatchButtonCallback}
							UnitTypeAndIconURL={["C", "https://realspace-core-iris.pages.dev/images/62cd561d4760d4b750dd508f_Unit-Bed.svg"]} UnitInfoAndIcons={TestUnitIconURLArray} UnitInfoTable={TestUnitInfoTableArray} />
					</Grid>
					<Grid item xs={20} sm={10} md={6} lg={5} sx={{}}>
						<UnitLabel key={3} UnitName="Albert Unit" UnitLevel="99" HasExploreButton={true} OnClickDispatch={DispatchButtonCallback}
							UnitTypeAndIconURL={["C", "https://realspace-core-iris.pages.dev/images/62cd561d4760d4b750dd508f_Unit-Bed.svg"]} UnitInfoAndIcons={TestUnitIconURLArray} UnitInfoTable={TestUnitInfoTableArray} />
					</Grid>
					<Grid item xs={20} sm={10} md={6} lg={5} sx={{}}>
						<UnitLabel key={4} UnitName="Albert Unit" UnitLevel="99" HasExploreButton={true} OnClickDispatch={DispatchButtonCallback}
							UnitTypeAndIconURL={["C", "https://realspace-core-iris.pages.dev/images/62cd561d4760d4b750dd508f_Unit-Bed.svg"]} UnitInfoAndIcons={TestUnitIconURLArray} UnitInfoTable={TestUnitInfoTableArray} />
					</Grid>
					<Grid item xs={20} sm={10} md={6} lg={5} sx={{}}>
						<UnitLabel key={5} UnitName="Albert Unit" UnitLevel="99" HasExploreButton={true} OnClickDispatch={DispatchButtonCallback}
							UnitTypeAndIconURL={["C", "https://realspace-core-iris.pages.dev/images/62cd561d4760d4b750dd508f_Unit-Bed.svg"]} UnitInfoAndIcons={TestUnitIconURLArray} UnitInfoTable={TestUnitInfoTableArray} />
					</Grid>
					<Grid item xs={20} sm={10} md={6} lg={5} sx={{}}>
						<UnitLabel key={6} UnitName="Albert Unit" UnitLevel="99" HasExploreButton={true} OnClickDispatch={DispatchButtonCallback}
							UnitTypeAndIconURL={["C", "https://realspace-core-iris.pages.dev/images/62cd561d4760d4b750dd508f_Unit-Bed.svg"]} UnitInfoAndIcons={TestUnitIconURLArray} UnitInfoTable={TestUnitInfoTableArray} />
					</Grid>

				</Grid>
				<FilterBarMemo ></FilterBarMemo>
				<Image style={{ position: "fixed", top: 0, left: 0, height: "100vh", width: "100vw" }} src={BackGroundImageURL}></Image>
			</ExteriorContextProvider>
		</Box >
	);
}